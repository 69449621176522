<template>
  <TemplateAuth>
    <FormLogin />
  </TemplateAuth>
</template>
<script lang="ts">
import Vue from 'vue'

import FormLogin from "@/components/organisms/FormLogin.vue";
import TemplateAuth from "@/templates/TemplateAuth.vue";

export default Vue.extend({
  components: {
    TemplateAuth,
    FormLogin
  }
})
</script>
