<template>
  <div
    v-if="errors && errors.length > 0"
    class="form-errors"
  >
    <div class="form-error red center">
      <span
        v-for="message in errors"
        :key="message"
      >
        {{ message }}<br>
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name:'FormErrors',
  props: {
    errors: {
      type: Array,
      default: () => []
    },
  },
  methods: {

  }
})
</script>

<style lang="less" scoped>
@import '../../less/form-layout';
</style>
