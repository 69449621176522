import axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    plans: {}
  },
  mutations: {
    SET (state, plans) {
      plans.forEach((plan) => {
        Vue.set(state.plans, plan._id, plan)
      })
    }
  },
  actions: {
    load (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/plans')
          .then(response => {
            if (response.data.length) {
              context.commit('SET', response.data)
            }
            resolve(response.data)
          })
          .catch(() => {
            reject()
          })
      })
    }
  },
  getters: {
    all: state => Object.values(state.plans),
    byId: (state, getters) => id => getters.all.find(item => item._id === id),
    forSelectedAccount: (state, getters, rootstate, rootGetters) => {
      const selectedAccount = rootGetters['account/selected']
      if (selectedAccount && selectedAccount.plan) {
        return getters.all.find(plan => selectedAccount.plan === plan._id)
      } else {
        return null
      }
    }
  }
}
