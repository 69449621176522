<template>
  <div class="notifications-popup">
    <Notification
      v-for="(notification, index) in notifications"
      :key="index"
      :notification-id="notification._id"
      :title="notification.title"
      :message="notification.message"
      :image="notification.image"
      :read="notification.read"
      :icon="notification.icon"
      :time="notification.createdAt"
      :link="notification.link"
      @mouseover.native="$store.dispatch('notification/mouseenter', notification._id)"
      @mouseleave.native="$store.dispatch('notification/mouseleave', notification._id)"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import Notification from '@/components/molecules/Notification.vue'

export default Vue.extend({
  components: {
    Notification
  },
  computed: {
    notifications () {
      const ids = Object.keys(this.$store.state.notification.visible)
      const notifications = []
      ids.forEach(id => {
        notifications.push(this.$store.state.notification.documents[id])
      })
      return notifications
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';
  @import '../../less/mixins.less';

  .notifications-popup{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 30;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
    gap:0.5em;
    box-sizing: border-box;
    max-width: 350px;
  }

  .notification {
    background: @white;
    box-sizing: border-box;
    width: 100%;
    box-shadow:0 5px 10px fade(@black, 10%);
    margin: 0;
    animation: fadein 0.5s;
  }

  @keyframes fadein {
    0%{
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }
</style>
