// The profiling function
import profile from './profile';

const addProfiling = (fn, nameResolver, type, key) => {
    if (typeof fn !== 'function') {
        return fn;  // Return the original value if it's not a function
    }
    return function(...args) {
        const resolvedName = nameResolver.call(this);
        const profileKey = `${resolvedName}:${type}:${key}`;
        return profile(profileKey).call(this, fn.apply(this, args));
    };
};

function nameResolver() {
    return this.$options.name || this.$options._componentTag || 'PremountComponent';
}

function profileComponent(component) {

    if(component.profile === 'false'){
        console.log('Not profiling', nameResolver.call(component));
        return component;
    }
    // Profile methods
    if (component.methods) {
        for (const methodName in component.methods) {
            component.methods[methodName] = addProfiling(component.methods[methodName], nameResolver, 'method', methodName);
        }
    }

    // Profile computed properties
    if (component.computed) {
        for (const computedName in component.computed) {
            const originalComputed = component.computed[computedName];
            component.computed[computedName] = typeof originalComputed === 'function'
                ? addProfiling(originalComputed, nameResolver, 'computed', computedName)
                : {
                    get: originalComputed.get ? addProfiling(originalComputed.get, nameResolver, 'computed', computedName + ':get') : undefined,
                    set: originalComputed.set ? addProfiling(originalComputed.set, nameResolver, 'computed', computedName + ':set') : undefined,
                };
        }
    }

    // Profile watchers
    if (component.watch) {
        for (const watchKey in component.watch) {
            const originalWatcher = component.watch[watchKey];
            if (Array.isArray(originalWatcher)) {
                originalWatcher.forEach((watcher, index) => {
                    originalWatcher[index] = addProfiling(watcher, nameResolver, 'watch', watchKey);
                });
            } else {
                component.watch[watchKey] = addProfiling(originalWatcher, nameResolver, 'watch', watchKey);
            }
        }
    }

    const lifecycleHooks = [
        'beforeCreate',
        'created',
        'beforeMount',
        'mounted',
        'beforeUpdate',
        'updated',
        'activated',
        'deactivated',
        'beforeDestroy',
        'destroyed',
        'errorCaptured',
    ];

    // Profile lifecycle hooks
    lifecycleHooks.forEach(hookName => {
        if (component[hookName]) {
            component[hookName] = addProfiling(component[hookName], nameResolver, 'lifecycle', hookName);
        }
    });

    return component;
}

const VueComponentProfile = {
    install(Vue) {
        // Save a reference to the original Vue.extend function.
        const originalExtend = Vue.extend;

        // Override Vue.extend
        Vue.extend = function (options = {}) {
            const profiledOptions = profileComponent(options);
            return originalExtend.call(this, profiledOptions);
        };
    }
}

export default VueComponentProfile;
