import axios from 'axios';
export default {
    namespaced: true,
    state: {
        documents: {}
    },
    actions: {
        track(context, data){
            return axios.post('/api/system-events/track', data)
        }
    },
    mutations: {

    },
    getters: {

    }
}