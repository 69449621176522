<template>
  <BaseModal
    :title="`${title || confirmButtonLabel + '?'}`"
    @close="$emit('close');"
  >
    <br>
    <div class="message">
      <slot name="message" />
      <slot />
      {{ message }}
    </div>
    <div v-if="confirmString">
      <p>
        To {{ confirmButtonLabel.toLowerCase() }}, enter
        <span class="confirm-string">{{ confirmString }}</span>
        below and click "{{ confirmButtonLabel }}".
      </p>
      <InputText
        ref="input"
        v-model="input"
        :placeholder="confirmString"
        @keyup.native="onKeyUp"
        @enter="confirm"
      />
      <Button
        :class="{disabled: !enableButton, 'warning': true}"
        :label="confirmButtonLabel"
        @click="confirm"
      />
    </div>
    <div
      v-else
      class="button-container"
    >
      <Button
        class="secondary"
        :label="cancelButtonLabel"
        @click="$emit('cancel'); $emit('close');"
      />
      <Button
        class="primary"
        :label="confirmButtonLabel"
        :icon="confirmButtonIcon"
        @click="$emit('confirm')"
      />
    </div>
  </BaseModal>
</template>
<script lang="ts">
import Vue from 'vue'

import Button from '@/components/atoms/Button.vue'
import InputText from '@/components/atoms/InputText.vue'
import BaseModal from '@/components/molecules/BaseModal.vue'


export default Vue.extend({
  components: {
    InputText,
    Button,
    BaseModal
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    confirmString: {
      type: String,
      default: ''
    },
    confirmButtonLabel: {
      type: String,
      default: 'Delete'
    },
    confirmButtonIcon: {
      type: String,
      default: ''
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel'
    }
  },
  data () {
    return {
      input: ''
    }
  },
  computed: {
    stringsMatch () {
      return this.input.toLowerCase() == this.confirmString.toLowerCase()
    },
    enableButton () {
      return this.stringsMatch || this.confirmString === ''
    }
  },
  mounted () {
    Vue.nextTick(() => {
      if (this.$refs.input) {
        this.$refs.input.$el.focus()
      }
    })
  },
  methods: {
    onKeyUp (event) {
      this.input = event.target.value
    },
    confirm () {
      if (this.stringsMatch) {
        this.$emit('confirm')
        this.$emit('close')
      }
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  input {
    border:1px solid @grayLightMedium;
  }

  .confirm-string{
    text-transform: uppercase;
    font-weight: 500;
  }

  .message {
    color: @grayMedium;
    margin-bottom: 1rem;
  }

  .message /deep/ strong{
    font-weight: 500;
    color: @grayDark;
  }

  .button-container{
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
</style>
