<template>
  <div :class="{ 'v-step--sticky': isSticky, hidden, 'v-step': true}" :id="'v-step-' + hash" :ref="'v-step-' + hash">
    <slot name="header">
      <div v-if="step.header" class="v-step__header">
        <div v-if="step.header.title" v-html="step.header.title"></div>
      </div>
    </slot>

    <slot name="content">
      <div class="v-step__content">
        <div v-if="step.content" v-html="step.content"></div>
        <div v-else>This is a demo step! The id of this step is {{ hash }} and it targets {{ step.target }}.</div>
      </div>
    </slot>

    <slot name="actions">
      <div class="content">
        <div class="v-step__buttons">
          <Button @click="previousStep" v-if="!isFirst && isButtonEnabled('buttonPrevious')" class="small rounded v-step__button-previous">
            {{ labels.buttonPrevious }}
          </Button>
          <Button @click="nextStep" v-if="!isLast && isButtonEnabled('buttonNext')" class="rounded small v-step__button-next">
            {{ labels.buttonNext }}
            <IconSvg name="chevron" />
          </Button>
          <Button @click="finish" v-if="isLast && isButtonEnabled('buttonStop')" class="small rounded v-step__button-stop">
            {{ labels.buttonStop }}
            <IconSvg name="check" />
          </Button>
        </div>
        <a @click="skip" v-if="!isLast && isButtonEnabled('buttonSkip')" class="small skip-link">{{ labels.buttonSkip }}</a>
      </div>
    </slot>

    <div class="v-step__arrow" data-popper-arrow></div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import jump from 'jump.js'
import sum from 'hash-sum'
import Button from '@/components/atoms/Button.vue'
import { DEFAULT_STEP_OPTIONS, HIGHLIGHT } from '@/store/tour-constants'
import IconSvg from "@/components/atoms/IconSvg.vue";

export default {
  name: 'TourStep',
  components: {
    IconSvg,
    Button
  },
  props: {
    step: {
      type: Object
    },
    previousStep: {
      type: Function
    },
    nextStep: {
      type: Function
    },
    stop: {
      type: Function
    },
    skip: {
      type: Function,
      default: function () {
        this.stop()
      }
    },
    finish: {
      type: Function,
      default: function () {
        this.stop()
      }
    },
    isFirst: {
      type: Boolean
    },
    isLast: {
      type: Boolean
    },
    labels: {
      type: Object
    },
    enabledButtons: {
      type: Object
    },
    highlight: {
      type: Boolean
    },
    stopOnFail: {
      type: Boolean
    },
    debug: {
      type: Boolean
    }
  },
  data () {
    return {
      hash: sum(this.step.target),
      targetElement: document.querySelector(this.step.target),
      hidden: true
    }
  },
  computed: {
    params () {
      return {
        ...DEFAULT_STEP_OPTIONS,
        ...{ highlight: this.highlight }, // Use global tour highlight setting first
        ...{ enabledButtons: Object.assign({}, this.enabledButtons) },
        ...this.step.params // Then use local step parameters if defined
      }
    },
    /**
     * A step is considered sticky if it has no target.
     */
    isSticky () {
      return !this.step.target
    }
  },
  mounted () {
    this.createStep()
  },
  destroyed () {
    this.removeHighlight()
  },
  methods: {
    createStep () {
      if (this.debug) {
        console.log('[Vue Tour] The target element ' + this.step.target + ' of .v-step[id="' + this.hash + '"] is:', this.targetElement)
      }

      if (this.isSticky) {
        document.body.appendChild(this.$refs['v-step-' + this.hash])
      } else {
        if (this.targetElement) {
          this.enableScrolling()
          this.createHighlight()

          createPopper(
              this.targetElement,
              this.$refs['v-step-' + this.hash],
              this.params
          )
        } else {
          if (this.debug) {
            console.error('[Vue Tour] The target element ' + this.step.target + ' of .v-step[id="' + this.hash + '"] does not exist!')
          }
          this.$emit('targetNotFound', this.step)
          if (this.stopOnFail) {
            this.stop()
          }
        }
      }
    },
    enableScrolling () {
      if (this.params.enableScrolling) {
        if (this.step.duration || this.step.offset) {
          const jumpOptions = {
            duration: this.step.duration || 1000,
            offset: this.step.offset || 0,
            callback: undefined,
            a11y: false
          }
          console.log('jumping');
          jump(this.targetElement, jumpOptions)
        } else {
          // Use the native scroll by default if no scroll options has been defined
          console.log('smooth scrolling');
          this.targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    },
    isHighlightEnabled () {
      if (this.debug) {
        console.log(`[Vue Tour] Highlight is ${this.params.highlight ? 'enabled' : 'disabled'} for .v-step[id="${this.hash}"]`)
      }
      return this.params.highlight
    },
    createHighlight () {
      if (this.isHighlightEnabled()) {
        document.body.classList.add(HIGHLIGHT.classes.active)
        const transitionValue = window.getComputedStyle(this.targetElement).getPropertyValue('transition')

        // Make sure our background doesn't flick on transitions
        if (transitionValue !== 'all 0s ease 0s') {
          this.targetElement.style.transition = `${transitionValue}, ${HIGHLIGHT.transition}`
        }

        this.targetElement.classList.add(HIGHLIGHT.classes.targetHighlighted)
        // The element must have a position, if it doesn't have one, add a relative position class
        if (!this.targetElement.style.position) {
          this.targetElement.classList.add(HIGHLIGHT.classes.targetRelative)
        }
      } else {
        document.body.classList.remove(HIGHLIGHT.classes.active)
      }
    },
    removeHighlight () {
      if (this.isHighlightEnabled()) {
        const target = this.targetElement
        const currentTransition = this.targetElement.style.transition
        this.targetElement.classList.remove(HIGHLIGHT.classes.targetHighlighted)
        this.targetElement.classList.remove(HIGHLIGHT.classes.targetRelative)
        // Remove our transition when step is finished.
        if (currentTransition.includes(HIGHLIGHT.transition)) {
          setTimeout(() => {
            target.style.transition = currentTransition.replace(`, ${HIGHLIGHT.transition}`, '')
          }, 0)
        }
      }
    },
    isButtonEnabled (name) {
      return this.params.enabledButtons[name] || true
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../../less/variables.less';

  .v-step {
    background: white;
    max-width: 330px;
    box-shadow: 0px 5px 10px fade(@black, 10%);
    pointer-events: none;
    z-index: 10000;
    border:2px solid @green;
    border-radius: 1rem;
    text-align: left;
    color: @white;
    font-size: 0.85rem;
  }

  .v-step--sticky {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }

  .v-step--sticky .v-step__arrow {
    display: none
  }

  .v-step__arrow, .v-step__arrow:before {
    position: absolute;
    z-index: -1;
    background: inherit;
  }

  .v-step__arrow {
    visibility: hidden
  }

  .v-step__arrow--dark:before {

  }

  .v-step__arrow:before {
    background: @white;
    z-index: -1;
    visibility: visible;
    content: "";
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border:2px solid @green;
    width: 20px;
    height: 20px;
    box-shadow: 0px 5px 10px fade(@black, 10%);
  }

  .v-step[data-popper-placement^=top] > .v-step__arrow {
    bottom: 12px;
    margin-left: -10px;
  }

  .v-step[data-popper-placement^=bottom] > .v-step__arrow {
    top: -12px;
    margin-left: -10px;
  }

  .v-step[data-popper-placement^=right] > .v-step__arrow {
    left: -12px;
    margin-top: -10px;
  }

  .v-step[data-popper-placement^=left] > .v-step__arrow {
    right: -12px;
    margin-top: -10px;
  }

  .v-step__header {
    padding: 1.5rem 1.5rem 0.5em 1.5rem;
    background-color: @white;
    font-weight: 600;
    border-top-left-radius: 0.85rem;
    border-top-right-radius: 0.85rem;
    color: @black;
    font-size: 1.2em;
    *{
      line-height: 1.2em;
    }
  }

  .v-step__content {
    padding: 0rem 1.5rem 0 1.5rem;
    position: relative;
    background: white;
    overflow: auto;
    font-weight: 400;
    color: @grayDark;
  }

  .v-step__buttons{
    position: relative;
    z-index:1;
    padding: 0 1.5em;
    display: flex;
    gap: 1em;
    justify-content: flex-end;
    border-bottom-left-radius: 1.2em;
    border-bottom-right-radius: 1.2em;
    pointer-events: all;
  }
  .v-step__button-previous{
    margin-right: auto;
  }


  .skip-link{
    text-align: center;
    color: gray;
    display: block;
    padding-bottom: 0.5em;
    overflow: hidden;
    pointer-events: all;
  }
  .content{
    background: @white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    font-weight: 600;
  }

  /deep/ small{
    background: @grayDark;
    color: @white;
    font-weight: 600;
    padding: 0em 0em;
    border-radius: 0.25em;
    min-width: 1.5em;
    line-height: 1.5em;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
  }

</style>