import axios from 'axios'

export default {
  namespaced: true,
  state: {
    systemEventTypes: {},
    subscriptions: {}
  },
  mutations: {
    SET_SYSTEM_EVENT_TYPES (state, systemEventTypes) {
      state.systemEventTypes = systemEventTypes
    },
    SET_SUBSCRIPTIONS (state, subscriptions) {
      state.subscriptions = subscriptions
    }
  },
  actions: {
    load (context) {
      return new Promise((resolve, reject) => {
        if (context.getters.all.length > 0) {
          // we already have systemEventTypes
          resolve()
        } else {
          axios.get('/api/system-event-types')
            .then(response => {
              if (response.data.length) {
                context.commit('SET_SYSTEM_EVENT_TYPES', response.data)
              }
              resolve()
            })
            .catch(() => {
              reject()
            })
        }
      })
    },
    loadSubscriptions (context) {
      return axios.get('/api/system-event-types/subscriptions').then(function (response) {
        context.commit('SET_SUBSCRIPTIONS', response.data)
      }).catch(e => {
        console.log(e);
      })
    },
    subscribe (context, data) {
      return axios.put(
        `/api/system-event-types/subscriptions/${data.systemEventType}/${data.notificationType}`,
        { subscribed: true }
      ).then(response => {
        context.commit('SET_SUBSCRIPTIONS', response.data)
      })
    },
    unsubscribe (context, data) {
      return axios.put(
        `/api/system-event-types/subscriptions/${data.systemEventType}/${data.notificationType}`,
        { subscribed: false }
      ).then(response => {
        context.commit('SET_SUBSCRIPTIONS', response.data)
      })
    }
  },
  getters: {
    all: state => Object.values(state.systemEventTypes)
  },
  onDeauthenticate(state){
    state.systemEventTypes = {}
    state.subscriptions = {}
  }
}
