<template>
  <header class="page-header">
	<h1 v-if="printTitle && $root.isPrintMode">
		{{ printTitle }}
	</h1>
    <slot v-else />
  </header>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
	props: {
		printTitle: {
			type: String,
			default: '',
			required: false
		}
	}
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  .page-header{
    height: @mobile-header-height;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: @white;
    box-shadow: 0 5px 15px rgba(49, 10, 59, 15%);
    position: fixed;
    width: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center center;
  }

  h1, h4{
    margin-right: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h1{
    font-size: 20px;
    padding-left: 1.5rem;
    margin: 0;
    font-weight: 600;
    &:last-child{
      margin-bottom: 56px;
    }
    p{
      font-size: 1rem;
      text-overflow: ellipsis;
    }
  }

  .print h1{
	font-size: 1rem;
	line-height: 1.5em;
  }

  a {
    margin-left: 1.20rem;
    margin-bottom: 0.25rem;
    color: @purpleVibrant;
    &:hover, &:active {
      text-decoration: none;
    }
    /deep/ .icon.chevron {
      margin-top: -1px;
    }
  }

  @media (min-width: @firstbreakpoint){
    h1{
      font-size: 1.5rem;
      padding-left: 0;
      margin-top: 0;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }
    a {
      pointer-events: all;
      margin-left: -0.18rem;
      color: @grayMedium;
    }
    .page-header {
      position: relative;
      height: 122px;
      margin-bottom: 1.5em;
      background-color: transparent;
      box-shadow: none;
      pointer-events: none;
      padding: 0 1.5rem;
      > * {
        pointer-events: all;
      }
      > a{
        font-size: 0.8em;
      }
    }
  }
</style>
