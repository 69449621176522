import BaseModule from '@/store/base-module'

export default BaseModule({
    name: 'addresses',
    getters: {
        forAccount: (state, getters) => accountId => {
            return getters.all.filter(object => object.account === accountId || false)
        },
        forCollab: (state, getters) => collabId => {
            return getters.all.filter(object => object.collab === collabId || false)
        },
        forAgreement: (state, getters) => agreementId => {
            console.log(agreementId, getters.all);
            return getters.all.find(object => object.agreement === agreementId || false)
        }
    }
})