import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'social-entities',
  state: {
    available: {}
  },
  onDeauthenticate: state => {
    state.available = {}
  },
  getters: {
    available: state => Object.values(state.available),
    forAccount: (state, getters) => accountId => {
      return getters.all.filter(object => object.account === accountId || false).sort((a, b) => (b.followers || 0) - (a.followers || 0))
    },
    accounts: (state, getters) => {
      const accounts = {};
      getters.all.forEach(socialEntity => {
        if (!Object.prototype.hasOwnProperty.call(accounts, socialEntity.account)) {
          accounts[socialEntity.account] = [socialEntity];
        }else{
          accounts[socialEntity.account].push(socialEntity)
        }
      })
      // loop over the accounts and sort them by followers
      Object.keys(accounts).forEach(account => {
        accounts[account].sort((a, b) => {
          // Treat undefined or null followers as 0
          const followersA = a.followers !== undefined && a.followers !== null ? a.followers : 0;
          const followersB = b.followers !== undefined && b.followers !== null ? b.followers : 0;
          return followersB - followersA;
        });
      });
      return accounts;
    }
  },
  async beforeSave (context, doc) {
    if (doc.followers && String(doc.followers).indexOf(',') !== -1) {
      doc.followers = parseInt(String(doc.followers).replace(/,/g, ''), 10);
    }
  }
})
