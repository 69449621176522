import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'object-event-subscriptions',
  getters: {
    settings: (state, getters, rootState, rootGetters) => details => {
      details.user = rootGetters['user/me']._id;
      const keys = Object.keys(details);
      const existingObjectEventSubscription = getters.all.find(sub => {
        return keys.every(key => sub[key] === details[key])
      });
      if(existingObjectEventSubscription){
        return existingObjectEventSubscription
      }
      return details
    }
  },
  async beforeSave (context, doc) {
    if (!doc.user) {
      doc.user = context.rootGetters['user/me']._id
      if (!doc.user) {
        throw new Error('An objectEventSubscription must have a user')
      }
    }
  }
})
