<template>
  <div class="user-container">
    <div class="avatar-container">
      <img
        v-if="avatar"
        :src="avatar"
      >
      <span v-if="!avatar">
        <IconSvg name="selfie" />
      </span>
    </div>
    <div
      v-if="showName"
      class="user-name"
    >
      {{ fullName }}
    </div>
    <slot />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import store from '@/store'
import IconSvg from "@/components/atoms/IconSvg.vue";


export default Vue.extend({
  components: {IconSvg},
  props: {
    showName: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    avatar () {
      return store.state.user.me.local.avatar
    },
    initials () {
      return store.getters['user/initials']
    },
    fullName () {
      return store.getters['user/fullName']
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  .user-container{
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    color: @grayMedium;
  }

  .avatar-container{
    display: inline-block;
    float: left;
    background: @grayLight;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    font-weight: 600;
    overflow: hidden;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .user-name{
    color: @grayMedium;
    text-align: center;
    margin-left: 0.5em;
  }
</style>
