export default {
  supported: async function () {
    return !!(await this.getRegistration())
  },
  pushSupported: async function () {
    const registration = await this.getRegistration()
    return !!(registration && registration.pushManager)
  },
  getRegistration: async function () {
    const registration = await navigator.serviceWorker.ready
    return registration || null
  },
  getPushManager: async function () {
    const registration = await this.getRegistration()
    return registration.pushManager ? registration.pushManager : null
  },
  getPushPermissionStatus: async function () {
    if (window.Notification && window.Notification.permission) {
      return window.Notification.permission
    }
    return null
  },
  getPushSubscription: async function () {
    const pushManager = await this.getPushManager()
    if (pushManager) {
      return await pushManager.getSubscription()
    }
    return null
  },
  pushSubscribe: async function () {
    let subscription = await this.getPushSubscription()
    if (!subscription && (await this.pushSupported())) {
      const pushManager = await this.getPushManager()
      subscription = await pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: '' // this would have to come from somewhere import.meta.env.VUE_APP_VAPID_PUBLIC_KEY
      }).catch(e => {
        console.log('Subscribing error', e)
      })
    }
    if (subscription) {
      const subscriptionPayload = subscription.toJSON();
      subscriptionPayload.userAgent = navigator.userAgent
      return subscriptionPayload
    }
    return null
  },
  pushUnsubscribe: async function () {
    const subscription = await this.getPushSubscription()
    if (subscription) {
      return await subscription.unsubscribe()
    }
    return true
  }
}
