<template>
  <Button
    :class="classes"
    :disabled="loading"
    @click="onClick"
  >
    <IconSvg
      v-if="!loading"
      :name="platform"
      class="colored"
    />
    <ProgressSpinner v-if="loading" />
    {{ buttonLabel }}
  </Button>
</template>
<script lang="ts">
import Vue from 'vue'

import Button from '@/components/atoms/Button.vue'
import IconSvg from '@/components/atoms/IconSvg.vue'
import ProgressSpinner from '@/components/atoms/ProgressSpinner.vue'

export default Vue.extend({
  components: {
    Button,
    IconSvg,
    ProgressSpinner
  },
  props: {
    platform: {
      type: String,
      required: true,
      validator: (value: string) => ['google', 'facebook', 'tiktok'].includes(value)
    },
    label: {
      type: String,
      required: true
    },
    scopes: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    classes () {
      const classes = {
        'button-social-login': true
      }
      classes[this.platform] = true
      return classes
    },
    isFacebook () { return this.platform === 'facebook' },
    isGoogle () { return this.platform === 'google' },
    platformName () { return this.platform.charAt(0).toUpperCase() + this.platform.slice(1) },
    buttonLabel () {
      if (this.loading) {
        return `Waiting for ${this.platformName}...`
      } else {
        return this.label
      }
    }
  },
  methods: {
    onClick () {
      if (this.platform === 'facebook' && this.scopes.length > 0) {
        console.log('authing with fb with scopes', this.scopes.join(','))
        this.loading = true
        this.$emit('open')
        window.FB.login(response => {
          this.loading = false
          if (response.authResponse) {
            this.$emit('success')
            this.$store.dispatch('user/updateFacebookPermissions', response.authResponse).then(() => {
              Promise.all([
                this.$store.dispatch('socialEntity/loadAvailable'),
                this.$store.dispatch('socialEntity/load')
              ]).then(() => {
                this.$emit('complete')
              })
            })
          } else {
            // User has aborted or denied access
            this.$emit('abort')
          }
        }, {
          scope: this.scopes.join(','),
          // eslint-disable-next-line @typescript-eslint/camelcase
          return_scopes: true
        })
      } else {
        window.location.href = `/api/users/auth/${this.platform}?redirect=${this.$route.path}`
      }
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  .button{
    width: 100%;
    position: relative;
    padding: 1em 2em 1em 5em;
    height: auto;
    margin: 0 0 1em;
    &.facebook{
      background: @facebook;
      .icon {
        color: @white !important;
      }
    }
    &.google{
      background: @white;
      color: @grayDark;
      box-shadow:0 2px 2px fade(@black, 10%);
    }
    &.disabled{
      background: @grayLight;
    }
  }

  .icon-svg{
    font-size: 2em;
    position: absolute;
    top: 50%;
    left: 1em;
    margin: -0.5em 0 0 -0.5em;
    vertical-align: middle;
  }

</style>
