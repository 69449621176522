<template>
  <input
    v-mask="mask"
    v-rules="validate"
    :name="name"
    :value="value"
    :placeholder="placeholder"
    :class="{ invalid, valid }"
    :required="required"
    :type="type"
    @change="onChange"
    @keyup="onKeyUp"
    @keypress.enter="onEnter"
  >
</template>
<script lang="ts">
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'

export default Vue.extend({
  extends: BaseInput,
  props: {
    type: {
      type: String,
      default: 'text'
    }
  }
})

</script>
