<template>
  <div
    class="popover-menu-item"
    @click="$emit('click')"
  >
    <IconSvg
      v-if="icon"
      :name="icon"
    />
    <img
      v-else-if="image"
      :src="image"
    >
    <div v-else />
    <div class="text">
      <div class="label">
        {{ label }}
      </div>
      <div
        v-if="description"
        class="description"
      >
        {{ description }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import IconSvg from '@/components/atoms/IconSvg.vue'

export default Vue.extend({
  components: {
    IconSvg
  },
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    image: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      default: '',
      required: false
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';
  *{
    line-height: 1.5em;
  }

  .popover-menu-item{
    display: grid;
    grid-template-columns: 2em 1fr;
    color: black;
    border-radius: 0.25em;
    cursor: pointer;
    padding: 0.5em 0.5em  0.6em 1em;
    transition: background-color 150ms;
    align-items: center;
    gap: 0.5em;
    &:hover, &:active{
      background: @grayLight;
    }
    &.rotate-icon-180{
      .icon{
        transform: rotate(180deg);
      }
    }
    &.warning .label{
      color: @red;
    }
    &.warning .icon{
      color: @red;
    }
  }

  .icon{
    color: @black;
    width: 1.2em;
    height: 1.2em;
  }
  img{
    width: 100%;
    border-radius: 50%;
    background: rgba(0,0,0,0.2);
  }

  div.label {
    font-weight: 500;
    color: @black;
  }

  div.description {
    font-size: 0.8em;
    color: @grayMedium;
  }
</style>
