<template>
  <div
    style="text-align: left"
    class="input-select-list"
  >
    <div
      v-for="(option, index) in options"
      :key="`${uniqueKey}-${index}`"
      :class="{
        option: true,
        checked: isSelected(option)
      }"
      :style="{ width }"
      @click="toggleSelection(option)"
    >
      {{ option.name || option }}
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'
import IconSvg from "@/components/atoms/IconSvg.vue";
export default Vue.extend({
  components: {
    IconSvg
  },
  extends: BaseInput,
  props: {
    options: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Number,
      default: 1
    },
    uniqueKey: {
      type: String,
      default: null
    },
		multiple: {
			type: Boolean,
			default: false
		}
  },
  computed: {
    width(){
      return `${100 / this.columns}%`
    }
  },
  methods: {
    toggleSelection(option){
      if(this.multiple){
			if(this.value.includes(option)){
				this.setValue([...this.value].filter(v => v != option))
			}else{
				this.setValue([...this.value, option])
			}
		} else{
			this.setValue(this.value == option ? null : option	)
		}
    },
	isSelected(option){
		if(this.multiple){
			return this.value.includes(option)
		}else{
			return this.value == option
		}
	}
  },
})
</script>

<style lang="less" scoped>
@import '../../less/variables';

.option{
  width: 50%;
  display: inline-flex;
  align-items: center;
  padding:0.5em 1em;
  cursor: pointer;
	box-sizing: border-box;
	&:hover{
		background-color: @grayLight;
	}
}
</style>
