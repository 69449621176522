<template>
  <input
    v-money="mask"
    :name="name"
    :value="value"
    :placeholder="placeholder"
    :class="{ invalid, valid }"
    :required="required"
    :type="inputType"
    @change="onChange"
    @keyup="onKeyUp"
    @keypress.enter="onEnter"
  >
</template>
<script lang="ts">
import {VMoney} from 'v-money'
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'

export default Vue.extend({
  directives: {money: VMoney},
  extends: BaseInput,
  props: {
    mask: {
      type: [Object, null],
      default: null
    }
  },
  computed: {
    inputType(){
      if(this.mask){
        return 'tel'
      }
      return 'number'
    }
  },
  methods: {
    parseValue(value){
      if(this.mask){
        value = String(value).replace(/\D/g,'')
      }
      return Number(value)
    }
  }
})

</script>
