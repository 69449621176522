import axios from 'axios'

import BaseModule from '@/store/base-module'

const beforeSave = async function (context, collaboration) {
  if (!collaboration.account) {
    collaboration.account = context.rootGetters['account/selected']._id
    if (!collaboration.account) {
      throw new Error('A collaboration must have an account')
    }
  }
  collaboration.role = 'manager'
};

export default BaseModule({
  name: 'collaborations',
  actions: {
    invite (context, collaboration) {
      (async () => {
        await beforeSave(context, collaboration)
      })();
      return new Promise((resolve, reject) => {
        axios
          .post(
            '/api/collaborations',
            collaboration
          )
          .then(response => {
            context.dispatch('load')
            // context.commit('SET', [response.data]);
            resolve(response.data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    accept (context, collaborationId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/collaborations/${collaborationId}/accept`
        })
          .then(response => {
            if (response.data._id) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    resend(context, collaborationId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/collaborations/${collaborationId}/resend`
        })
        .then(response => {
          if (response.data._id) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch(e => {
            reject(e)
        })
      })
    }
  },
  getters: {
    isProtected: (state, getters, rootState, rootGetters) => collaborationId =>{
      // ensure meaningful collaborations are protected from deletion
      // criteria
      // 1. collaboration has an agreement
      // 2. collaboration has any media
      const agreement = rootGetters['agreement/forCollab'](collaborationId)
      if (agreement.length > 0) {
        // if any of the agreement statuses are not 'draft' then the agreement is protected
        const draft = agreement.filter(a => a.status !== 'draft')
        if (draft.length > 0) {
          return true
        }
      }
      const media = rootGetters['media/forCollab'](collaborationId)
      if (media.length > 0) {
        return true
      }
      return false
    }
  },
  beforeSave
})
