<template>
  <input
    v-rules="validPhone"
    v-mask="'+1 (###) ###-####'"
    :name="name"
    :value="value"
    :placeholder="placeholder"
    :class="{ invalid: invalid, valid: valid }"
    type="tel"
    @change="onChange"
    @keypress.enter="onEnter"
  >
</template>
<script lang="ts">
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'

export default Vue.extend({
  extends: BaseInput,
  methods: {
    validPhone (value) {
      if (value) {
        const re = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
        return this.notEmpty(value) && re.test(String(value).toLowerCase())
      } else {
        return true
      }
    }
  }
})

</script>
