import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'projects',
  state: {
    viewOptions: [ { icon: 'card-with-header', label: 'Full View', value:'full'}, { icon: 'hamburger', label: 'Compact View', value:'compact' } ],
  },
  actions: {
    addCreator(context, data){
      const project = {...context.state.documents[data.projectId]}
      project.creators.push(data.creatorId);
      project.creators = [...new Set(project.creators)] // ensure no duplicates
      return context.dispatch('update', project).then(project => {
        context.dispatch('app/setStatusMessage', `Creator added to ${project.name}`, {root: true})
      })
    },
    removeCreator(context, data){
      const project = {...context.state.documents[data.projectId]}
      const creators = new Set(project.creators);
      creators.delete(data.creatorId);
      project.creators = [...creators]
      return context.dispatch('update', project).then(project => {
        context.dispatch('app/setStatusMessage', `Creator removed from ${project.name}`, {root: true})
      })
    },
    toggleCreator(context, data){
      const project = {...context.state.documents[data.projectId]}
      if(project.creators.includes(data.creatorId)){
        return context.dispatch('removeCreator', data);
      }else{
        return context.dispatch('addCreator', data);
      }
    },
    async duplicate(context, projectId){
      try{
        const originalProject = context.state.documents[projectId];
        console.log(originalProject);

        const project = await context.dispatch('create', {
          name: `Copy of ${originalProject.name}`,
          account: originalProject.account
        })

        for(const collab of (context.rootGetters['collab/byProject'][projectId] || [])){
          console.log('Adding creator to new project', collab.creator, project._id);
          await context.dispatch('collab/addCreator', {
            project: project._id,
            creator: collab.creator
          }, {root: true})
        }
        await context.dispatch('app/setStatusMessage', `Project duplicated as ${project.name}`, {root: true})
        return project;
      } catch(e){
        console.error('Failed to duplicate project', e);
      }
    }
  },
  async beforeSave (context, doc) {
    if (!doc.account) {
      doc.account = context.rootGetters['account/selected']._id
      if (!doc.account) {
        console.error('A project must have an account', doc);
        throw new Error('A project must have an account')
      }
    }
    if(!doc._id){
      await context.dispatch('subscription/authorizeFeature','projects', {root: true});
    }
  }
})
