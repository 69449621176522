<template>
  <div class="upgrade">
    <h1>
      Out of <strong>{{$store.state.app.upgradeModalReason}}</strong>?
    </h1>
    <h1>No problem!</h1>
    <h1>
      Upgrade to <strong> Mach 1</strong>
    </h1>
    <Button
      class="primary"
      size="large"
      icon="check"
      @click="getStripePortalLink"
    >
      Upgrade
      <ProgressSpinner v-if="loading" />
    </Button>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';

import Button from "@/components/atoms/Button.vue";
import ProgressSpinner from "@/components/atoms/ProgressSpinner.vue";

export default Vue.extend({
  components: {ProgressSpinner, Button},
  data (){
    return {
      loading: false
    }
  },
  methods: {
    async getStripePortalLink(){
      try{
        this.loading = true;
        const url = await this.$store.dispatch('subscription/getStripePortalLink')
        // open in a new url
        window.open(url)
      } catch(e){
        console.error(e)
      } finally {
        this.loading = false;
      }
    }
  }
});
</script>
<style lang="less" scoped>
  @import '../../less/mixins';
  @import '../../less/variables';
  @import '../../less/typography';

  .upgrade{
    text-align: center;
  }
  h1{
    font-weight: 500;
    strong{
      font-weight: 700;
    }
  }
</style>
