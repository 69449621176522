
import axios from 'axios'
import Vue from 'vue'

import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'notifications',
  state: {
    visible: {}
  },
  mutations: {
    DISPLAY (state, data) {
      Vue.set(state.visible, data.id, data.timeout)
    },
    HIDE (state, id) {
      Vue.delete(state.visible, id)
    }
  },
  actions: {
    mark (context, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            '/api/notifications/' + data.id,
            { read: data.read }
          )
          .then(response => {
            context.commit('SET', response.data)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    markRead (context, id) {
      return context.dispatch('mark', { id, read: true })
    },
    markUnread (context, id) {
      return context.dispatch('mark', { id, read: false })
    },
    async markAllAsRead (context) {
      // Loop over all notifications and mark them as read
      for(const notification of context.getters.all.filter(n => !n.read)){
        await context.dispatch('markRead', notification._id)
      }
    },
    async clearAll (context) {
      // Loop over all notifications and mark them as read
      for(const notification of context.getters.all){
        await context.dispatch('delete', notification._id)
      }
    },
    display (context, notification) {
      context.commit('SET', notification)
      context.commit('DISPLAY', {
        id: notification._id,
        timeout: setTimeout(() => {
          context.commit('HIDE', notification._id)
        }, 5000)
      })
    },
    mouseenter (context, id) {
      const timeout = context.state.visible[id]
      if (timeout) {
        clearTimeout(timeout)
      }
    },
    mouseleave (context, id) {
      const timeout = setTimeout(() => {
        context.commit('HIDE', id)
      }, 2000)
      context.commit('DISPLAY', { id, timeout })
    }
  },
  getters: {
    unreadCount: (state, getters) => getters.all.filter(n => !n.read).length
  }
})
