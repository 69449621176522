import hrtime from 'browser-hrtime';

const profiles = [];
const logEnabled = false;

function profile(key, timeout = 10000) {
    const profile = {
        key,
        start: hrtime(),
    };

    let timeoutFunc = null;

    const done = (results) => {
        profile.end = hrtime(profile.start);
        profile.ms = Math.round(profile.end[0] * 1000 + profile.end[1] / 1000000);
        delete profile.start;
        delete profile.end;

        if (results instanceof Error) {
            profile.error = results;
        }

        if (logEnabled) {
            console.log(profile);
        }

        clearTimeout(timeoutFunc);
        profiles.push(profile);
        return results;
    };

    timeoutFunc = setTimeout(() => {
        done(new Error(`Timeout: ${timeout}ms`));
    }, timeout);

    return done;
}

export default profile;
export { profiles };
