<template>
  <div
    style="text-align: left"
    class="input-select-checkbox"
  >
    <div
      v-for="option in options"
      :key="`${uniqueKey}-${option}`"
      :class="{
        option: true,
        checked: value.includes(option)
      }"
      :style="{ width }"
      @click="toggleSelection(option)"
    >
      <span :class="{checkbox: true, checked: value.includes(option) }">
        <IconSvg name="check" />
      </span>
      {{ option }}
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'
import IconSvg from "@/components/atoms/IconSvg.vue";
export default Vue.extend({
  components: {
    IconSvg
  },
  extends: BaseInput,
  props: {
    options: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Number,
      default: 2
    },
    uniqueKey: {
      type: String,
      default: null
    }
  },
  computed: {
    width(){
      return `${100 / this.columns}%`
    }
  },
  methods: {
    toggleSelection(option){
      if(this.value.includes(option)){
        this.setValue([...this.value].filter(v => v != option))
      }else{
        this.setValue([...this.value, option])
      }
    }
  },
})
</script>

<style lang="less" scoped>
@import '../../less/variables';

.option{
  width: 50%;
  display: inline-flex;
  align-items: center;
  margin:0.5em 0;
  cursor: pointer;
}

.checkbox{
  float: left;
  height: 1.5em;
  width: 1.5em;
  border-radius: 0.4em;
  border: 2px solid @grayDark;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  margin-left: 1em;
  .icon{
    visibility: hidden;
  }
  &:hover{
    background: @grayExtraLight;
  }
  &.checked{
    background: black;
    border-color: black;
    color: white;
    .icon{
      visibility: visible;
    }
  }
  &.invalid{
    border-color: red;
  }
}
</style>
