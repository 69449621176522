<script lang="ts">
/**
     * @module InputMixin
     *
     * [InputMixin] enables data validation rules on [Inputs]
     *
     * @rules {array} are the specific rules to be run on the [Input]
     * @invalid {boolean} contains whether or not the [Input] is failing or passing validation
     *
     * ==========================================
     */

import Vue from 'vue'
import { mask } from 'vue-the-mask'

interface InputComponent extends Vue{
  rules: {
    [key: string]: [string, Function];
  };
}

export default Vue.extend({
  directives: {
    mask: {
      bind(el, binding, vnode, oldVnode) {
        if (binding.value) {
          // Apply the mask if the binding value is non-empty
          mask(el, { ...binding, value: binding.value }, vnode, oldVnode);
        }else{
          el.oninput = null;
        }
      },
      update(el, binding, vnode, oldVnode) {
        if (binding.value) {
          // Re-apply the mask if the binding value changes and is non-empty
          mask(el, { ...binding, value: binding.value }, vnode, oldVnode);
        }else{
          el.oninput = null;
        }
      }
    },
    rules: function (input, binding, vnode) {
      if (typeof binding.value === 'function') {
        (vnode.context as InputComponent).rules.validator = binding.value;
      } else if (typeof binding.value === 'object') {
        (vnode.context as InputComponent).rules = Object.assign((vnode.context as InputComponent).rules, binding.value);
      }
    }
  },
  data: function () {
    return {
      /*
                 * @rules {object} contains an array of
                 * specific rules to be run on the [Input]
                 */
      rules: {},
      /*
                * @invalid {boolean} contains whether or not
                * the [Input] value is actively failing validation
                */
      invalid: false,
      /*
                 * @valid {boolean} contains whether or not
                 * the [Input]v value is currently passing validation
                 */
      valid: false,
      form: null,

      // Overwrite the value by default
      overwrite: true
    }
  },
  created () {
    if (this.required === true) {
      this.rules.notEmpty = true
    }
  },
  mounted() {
    if(this.required === null){
      console.warn('InputMixin: define :required as true or false.', this.$el)
    }

    let parentComponent = this.$parent;
    while (parentComponent) {
      if (typeof parentComponent.registerInput === 'function') {
        this.form = parentComponent;
        this.form.registerInput(this);
        return;
      }
      parentComponent = parentComponent.$parent;
    }
    if(!this.orphan){
      console.warn('InputMixin: define :orphan as true or use within Form', this.$el);
    }
  },
  beforeDestroy(){
    this.form?.unregisterInput(this);
  },
  methods: {
    onEnter (event) {
      // PREVENT DEFAULT BROWSER FORM SUBMISSION
      if(event.stopPropagation){
        event.stopPropagation();
      }
      if(event.preventDefault){
        event.preventDefault();
      }
      this.onChange(event)
      Vue.nextTick(() => {
        this.$emit('enter', event, this)
      })
    },
    onChange (event) {
      // If target is checkbox, value boolean
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
      this.setValue(value)
    },
    onKeyUp (event) {
      let value
      if (event.target.tagName === 'DIV') {
        // Contenteditable DIV
        value = event.target.textContent
      } else {
        value = event.target.value
      }
      this.$emit('keyup', value, this)
      if(this.changeOnKeyUp){
        this.onChange(event)
      }
    },
    setValue (value) {
      // If rules are defined for this field then run validation
      if(this.parseValue){
        value = this.parseValue(value)
      }
      if (this.rules) {
        this.$nextTick(() => {
          this.checkValidity(value)
        })
      }
      this.$emit('change', value, { overwrite: this.overwrite })
      this.$emit('input', value, { overwrite: this.overwrite })
    },
    checkValidity (value) {
      value = value !== undefined ? value : this.value || ''

      // Loop over all rules for given field
      for (const ruleName in this.rules) {
        // Check to see if the rule exists

        let ruleFunction;
        let ruleOptions;

        if(typeof this.rules[ruleName] === 'function'){
          ruleFunction = this.rules[ruleName];
        }else if (Object.prototype.hasOwnProperty.call(this, ruleName) && typeof this[ruleName] === 'function') {
          ruleOptions = this.rules[ruleName]
          ruleFunction = this[ruleName];
          // Run the rule
        } else {
          // eslint-disable-next-line
          console.error(`ReferenceError: Form validation rule ${ruleName} is referenced but not defined`);
        }
        if(ruleFunction){
          const result = ruleFunction(value, ruleOptions);
          if (result !== true) {
            this.invalidate(ruleName, result);
            break;
          } else {
            this.valid = true
            this.invalid = false;
          }
        }
      }
    },
    invalidate (ruleName, errorMessage) {
      this.invalid = true
      this.valid = false
      this.$emit('error', {
        target: this,
        error: errorMessage || ruleName
      })
    },
    notEmpty (value) {
      return value !== undefined && value !== null && value !== ''
    }
  }
})
</script>
