<template>
  <div
    class="notification-list"
  >
    <Button
      v-if="showHeader"
      icon="x"
      class="transparent circle close-notifications-button"
      @click.native="$emit('close')"
    />
    <h2 v-if="showHeader && notificationsOrdered.length > 0">
      Notifications
    </h2>
    <div class="buttons">
      <Button
        v-if="notificationsOrdered.length > 0"
        class="secondary block"
        icon="eye"
        size="small"
        @click="$store.dispatch('notification/markAllAsRead')"
      >
        Mark all read
      </Button>
      <Button
        v-if="notificationsOrdered.length > 0"
        class="secondary block"
        icon="x"
        size="small"
        @click="$store.dispatch('notification/clearAll')"
      >
        Clear all
      </Button>
      <Button
        v-if="false && showPushSubscribeButton"
        class="primary block"
        icon="bell"
        @click="subscribe"
      >
        Enable Notifications
      </Button>
      <p
        v-if="pushBlocked"
        class="blocked"
      >
        Push notifications are blocked.
      </p>
    </div>
    <Notification
      v-for="(notification, index) in notificationsOrdered"
      :key="index"
      :notification-id="notification._id"
      :title="notification.title"
      :message="notification.message"
      :image="notification.image"
      :read="notification.read"
      :icon="notification.icon"
      :time="notification.createdAt"
      :link="notification.link"
    />
    <NoResults
      v-if="notifications.length === 0"
      icon="bell"
      icon-size="x4"
      title="No Notifications"
      message="They'll be here when you get them."
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import ServiceWorkerUtil from '../../plugins/serviceWorkerUtils'

import Button from '@/components/atoms/Button.vue'
import Notification from '@/components/molecules/Notification.vue'
import NoResults from '@/components/organisms/NoResults.vue'

export default Vue.extend({
  components: {
    Button,
    Notification,
    NoResults
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pushSupported: null,
      pushBlocked: null,
      pushEnabled: null
    }
  },
  computed: {
    notificationsOrdered () {
      return [...this.notifications].reverse()
    },
    showPushSubscribeButton () {
      return this.pushSupported && !this.pushEnabled && !this.pushBlocked
    }
  },
  created () {
    this.updatePushSubscriptionStatus()
  },
  methods: {
    async updatePushSubscriptionStatus() {
      this.pushSupported = await ServiceWorkerUtil.pushSupported()
      const permission = await ServiceWorkerUtil.getPushPermissionStatus()
      this.pushBlocked = permission === 'denied'
      this.pushEnabled = permission === 'granted'
    },
    async subscribe() {
      const subscription = await ServiceWorkerUtil.pushSubscribe()
      if (subscription) {
        // We're subscribed!
        // Time to save the subscription to the server
        this.$store.dispatch('user/savePushSubscription', subscription).then(() => {
          // Subscription fully active
          this.updatePushSubscriptionStatus()
        }).catch(async e => {
          // Subscription failed on server
          // Unsubscribe the client
          console.log(e);
          await ServiceWorkerUtil.pushUnsubscribe()
          await this.updatePushSubscriptionStatus()
        })
      } else {
        await this.updatePushSubscriptionStatus()
      }
    },
    // clearNotifications: async function () {
    //   await this.$store.dispatch('user/clearNotifications')
    // }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/mixins.less';
  @import '../../less/variables';

  .notification-list {
    border-left: 1px solid lighten(@grayLightMedium, 15%);
    background: @white;
    flex-shrink: 0;
    flex-grow: 0;
    overflow-y: auto;
    overflow-x: visible;
    user-select: none;
    cursor: default;
    padding: 1em;
    box-sizing: border-box;
  }

  .blocked {
    text-align: center;
    font-size: 90%;
    color: @grayMedium;
  }

  .buttons {
    display: flex;
    gap: 0.5em;
    button{
      margin-top: 0;
      margin-bottom: 0.5em;
    }
  }

  @keyframes overdue {
    0% {
      background-color: #fff;
      color: @black;
    }
    5% {
      background-color: transparent;
      color: @grayLightMedium;
    }
    10% {
      background-color: #fff;
      color: @black;
    }
    15% {
      background-color: transparent;
      color: @grayLightMedium;
    }
    20% {
      background-color: #fff;
      color: @black;
    }
    100% {
      background-color: #fff;
      color: @black;
    }
  }

  .close-notifications-button{
    float: right;
    vertical-align: middle;
    color: @grayMedium;
    &:hover, &:active{
      background: @grayLightMedium;
      color: @grayDark;
    }
  }

</style>
