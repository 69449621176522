import {firstBy} from "thenby";

import BaseModule from '@/store/base-module'

interface Note {
  adminOnly?: boolean;
  pinned?: boolean;
  createdAt: string;
}

const sortNoteFunction = firstBy((a: Note, b: Note) => Number(!!b.adminOnly) - Number(!!a.adminOnly))
    .thenBy((a: Note, b: Note) => Number(!!b.pinned) - Number(!!a.pinned))
    .thenBy((a: Note, b: Note) => Date.parse(b.createdAt) - Date.parse(a.createdAt))

export default BaseModule({
  name: 'notes',
  getters: {
    all: (state, getters, rootState, rootGetters) => {
      const documents: Array<Note> = Object.values(state.documents);
      return documents
          .filter(note => !note.adminOnly || rootGetters['user/isAdmin'])
          .sort(sortNoteFunction)
    },
    forAccountAndCreator: (state, getters, rootState, rootGetters) => creatorId => {
      return getters.all.filter(note => {
        return note.creator === creatorId && (
					note.account === rootGetters['account/selected']._id ||
					note.adminOnly && rootGetters['user/isAdmin'] // This ensures that admin can see all regardless of account
				)
      })
    },
    forCollab: (state, getters) => collabId => {
      return getters.all.filter(note => note.collab === collabId)
    },
    forSelectedAccount: (state, getters, rootState, rootGetters) => {
      return getters.all.filter(note => note.account === rootGetters['account/selected']._id)
    },
    forProject: (state, getters, rootState, rootGetters) => projectId => {
      const collabIdsForProject = (rootGetters['collab/byProject'][projectId] || []).map(collab => collab._id);
      if(collabIdsForProject){
        return getters.all.filter(note => {
          return collabIdsForProject.includes(note.collab) || (note.projects || []).includes(projectId)
        })
      }
      return []
    }
  },
  async beforeSave (context, doc) {
    if (!doc.account) {
      doc.account = context.rootGetters['account/selected']._id
      if (!doc.account) {
        throw new Error('A note must have an account')
      }
    }
    if (!doc.user) {
      doc.user = context.rootGetters['user/me']._id
      if (!doc.user) {
        throw new Error('A note must have a user')
      }
    }
  }
})
