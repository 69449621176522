<template>
  <div>
    <br>
    
		
    <p>
      Staging has <strong>newer code</strong> which means:
    </p>
    <ol>
      <li>It has new bug fixes and features.</li>
      <li>It will be released to customers soon, so our eyes MUST be on it.</li>
    </ol>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <span>
        Redirecting in {{ countdownValue }} seconds.
      </span>
      <Button
        style="float: right"
        label="Go now"
        :has-chevron="true"
        size="large"
        @click="redirect"
      />
    </div>

    <p
      class="size-s grayMedium"
      style="overflow: auto"
    >
      <strong>Only use production if:</strong> You need access to a feature that has become broken/unavailable on staging AND a bug report has been made.
    </p>

    <p class="size-s">
      <label>
        <input
          v-model="stagingisBroken"
          type="checkbox"
        >
        Staging is broken
      </label><br>
      <label>
        <input
          v-model="bugReported"
          type="checkbox"
        >
        The bug has been reported
      </label>
      <br>
      <a
        v-if="stagingisBroken && bugReported"
        href="#"
        class="danger link"
        @click="cancel"
      >
        Use production
      </a>
    </p>
    <br>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import Button from "@/components/atoms/Button.vue";

export default Vue.extend({
  components: { Button },
  data(){
    return {
      countdownValue: 60,
      countdownInterval: null,
			stagingisBroken: false,
			bugReported: false
    }
  },
  mounted(){
    console.log('Showing server redirect modal');
    this.countdownInterval = setInterval(() => {
      this.countdownValue--;
      console.log('Automatically redirecting to staging in: ', this.countdownValue);
      if(this.countdownValue === 0){
        this.redirect();
      }
    }, 1000);
  },
  beforeDestroy(){
    clearInterval(this.countdownInterval);
  },
  methods: {
    redirect(){
      console.log('Redirecting to staging now.');
      window.location.href = 'https://staging.creatorvelocity.com';
    },
    cancel(){
      clearInterval(this.countdownInterval);
      this.$emit('cancelRedirect');
    }
  }
});
</script>


<style lang="less" scoped>
@import '../../less/variables';

.button-container{
  display: flex;
  justify-content: space-between;
}
div{
	font-weight: 400;
}
strong{
	font-weight: 600;
}

ol {
	padding-left: 1.5rem;
	margin: 0;
	li {
		margin-bottom: 0.5rem;
		list-style: decimal;
	}
}
.danger{
	color: @red !important;
}
</style>