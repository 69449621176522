<template>
  <aside :class="{'sidebar-container': true, expanded}">
    <div class="sidebar-content">
      <router-link
          :to="{name: 'home'}"
          style="text-align: center"
      >
        <TheLogo
            v-show="expanded"
            color="white"
        />
        <img
          v-show="!expanded"
          src="/img/creator-velocity-icon.png"
          width="40%"
          style="margin:0 auto 1em;"
          alt="Creator Velocity"
        >
      </router-link>

      <nav style="justify-self: flex-start; margin-top: 0.5em">
        <PopoverMenu
          placement="right-start"
          class="account-selector"
        >
          <NavigationMenuLink
            v-if="selectedAccount"
            slot="trigger"
            icon="selfie"
            :image="selectedAccount.logo"
            :title="selectedAccount.name"
          />
          <div style="text-align: right" v-if="$store.getters['user/isAdmin']">
            <span class="size-xxs uppercase grayMedium">
            Show test accounts
          </span>
          <InputToggle
					v-if="$store.getters['user/isAdmin']"
            v-model="showTestAccounts"
            @change="showTestAccounts=$event"
            :height="20"
            :width="48"
            :font-size="10"
            color="orange"
			:orphan="true"
            :labels="{ checked: 'Yes', unchecked: 'No' }"
            />
          </div>
          <PopoverMenuItem
            v-for="(brand, index) in $store.getters['account/brandsForUser'].sort((a,b) => a.name.localeCompare(b.name)).filter(a => !showTestAccounts && !a.isTestAccount || showTestAccounts)"
            :key="index"
            v-close-popover
            :image="brand.logo"
            :label="brand.name"
            class="small"
            @click="$store.commit('account/SET_SELECTED_ACCOUNT', brand._id)"
          />
        </PopoverMenu>

        <div>
          <hr style="border-color: #666">
        </div>
        <NavigationMenuLink
          v-if="$store.getters['account/isBrand']"
          :to="{name: 'home'}"
          icon="home"
          title="Dashboard"
        />
        <NavigationMenuLink
          id="tour-nav-search"
          v-if="$store.getters['account/isBrand']"
          :to="{name: hasTopicsSelected ? 'creators' : 'topics'}"
          icon="magnifying-glass"
          title="Find Creators"
        />
        <NavigationMenuLink
          id="tour-nav-projects"
          v-if="$store.getters['account/isBrand']"
          :to="{name: 'projects'}"
          icon="favorites"
          title="Projects"
        />
        <NavigationMenuLink
          :to="{name: 'tax-documents'}"
          icon="dollar-light"
          title="Tax Documents"
        />
        <NavigationMenuLink
          :to="{name: 'account-settings'}"
          icon="storefront-inverted"
          title="Account"
        />
        <div
          v-if="$store.getters['user/isAdmin']"
          class="admin-menu-container"
        >
          <hr style="border-color: #666">
          <NavigationMenuLink
            :to="{name: 'brands'}"
            icon="storefront-inverted"
            title="Brands"
          />
          <NavigationMenuLink
            :to="{name: 'creators-to-review'}"
            icon="selfie"
            title="Creators to review"
          />
          <NavigationMenuLink
            :to="{name: 'media-outliers', params: { factor: 'by-average-views'}}"
            icon="play"
            title="Outliers"
          />
          <NavigationMenuLink
            :to="{name: 'vision-profiles'}"
            icon="eye"
            title="Vision Profiles"
          />
          <NavigationMenuLink
            :to="{name: 'vision-reports'}"
            icon="graph"
            title="Vision Reports"
          />
          <NavigationMenuLink
            :to="{name: 'media'}"
            icon="report"
            title="Media Errors"
          />
          <NavigationMenuLink
            v-if="false && $store.getters['user/isAdmin']"
            :to="{name: 'users'}"
            icon="selfie"
            title="Users"
          />
          <NavigationMenuLink
            :to="{name: 'app-config'}"
            icon="gear"
            title="Global Config"
          />
          <NavigationMenuLink
            :to="null"
            icon="gear"
            title="Profiler"
            @click.native="$store.commit('app/SET_SHOW_PROFILER', !$store.state.app.showProfiler)"
          />
          <NavigationMenuLink
            :to="{name: 'app-queue'}"
            icon="clock"
            title="Queue"
          />
          <NavigationMenuLink
            :to="{name: 'errors'}"
            icon="warn"
            title="Errors"
          />
        </div>
        <NavigationMenuLink
          :to="null"
          icon="chevron"
          :icon-class="{'rotate-180': expanded}"
          :title="expanded ? 'Collapse menu' : 'Expand Menu'"
          @click.native="expanded = !expanded"
        />
      </nav>

      <div
        class="bottom"
      >
        <div>
					<v-popover trigger="hover" placement="right">
						<p class="size-xxs grayMedium" @click="copyServerData">
							v{{ $store.state.app.version}}
						</p>
						<template slot="popover">
							<div @click="copyServerData">
								<strong>Click to Copy</strong><br />
								Environment: {{ $store.state.app.env }}<br />
								Version: {{ $store.state.app.version}}<br />
								Build: {{ $store.state.app.build }}<br />
								Start time: {{ $store.getters['app/getServerStartTime'] }}<br />
								Browser: {{ browser }}
							</div>
						</template>
					</v-popover>
        </div>
      </div>
    </div>
  </aside>
</template>
<script lang="ts">
import '../../assets/woman-on-laptop.png'
import Vue from 'vue'
import { computed } from 'vue';

import Button from '@/components/atoms/Button.vue'
import NavigationMenuLink from '@/components/atoms/NavigationMenuLink.vue'
import TheLogo from "@/components/atoms/TheLogo.vue";
import EntityProfileName from "@/components/molecules/EntityProfileName.vue";
import PopoverMenu from "@/components/molecules/PopoverMenu.vue";
import PopoverMenuItem from "@/components/molecules/PopoverMenuItem.vue";
import InputToggle from '../atoms/InputToggle.vue';

export default Vue.extend({
  components: {
    PopoverMenuItem,
    PopoverMenu,
    EntityProfileName,
    Button,
    NavigationMenuLink,
    TheLogo,
    InputToggle
},
  props: {
    showInstallPrompt: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menus: [
        'account',
        'location'
      ],
      selectedMenu: 'account',
      direction: 'forward',
      expanded: false,
      showTestAccounts: false
    }
  },
  provide() {
    return {
      expanded: computed(() => this.expanded)
    }
  },
  computed: {
    hasTopicsSelected(){
      try {
        return this.$store.state.account.creatorFilterSettings.contentTopics.length > 0
      } catch(e){
        return false;
      }
    },
    selectedAccount(){
      return this.$store.getters['account/selected']
    },
		browser(){
			// get the javasceipt btowser form the user agent
			try{
				const browser = (function(){
				let ua= navigator.userAgent, tem, M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
				if(/trident/i.test(M[1])){
					tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
					return 'IE '+(tem[1] || '');
				}
				if(M[1]=== 'Chrome'){
					tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
					if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
				}
				M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
				if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
				return M.join(' ');
			})();
			return browser;
			} catch(e){
				return 'unknown'
			}
		}
  },
	methods: {

		copyServerData: function(){
			this.$root.copyToClipboard(`
								Environment: ${ this.$store.state.app.env }
								Version: ${ this.$store.state.app.version}
								Build: ${ this.$store.state.app.build }
								Start time: ${ this.$store.getters['app/getServerStartTime'] }
								Browser: ${ this.browser }
							`)
		}
	}
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  nav{
    padding: 1px 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    font-size: 0.8em;
  }

  .sidebar-container{
    flex-shrink: 0;
    height: 100vh;
    position: relative;
    display: flex;
    z-index: 2;
    //transition: width 1s;
    background: @black;
    overflow: scroll;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Webkit browsers */
    }
  }
  .v-tour--active .sidebar-container{
    overflow: visible;
  }

  .sidebar-content{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1.5em;
    user-select: none;
    -webkit-app-region: drag;
    box-sizing: border-box;
    justify-content: flex-start;
  }

  .sidebar-container, .sidebar-content{
    width: 70px;
  }

  /deep/ .v-select{
    margin: 0.75em;
    padding: 0 0.5em;
    border-radius: 27.5px;
    transition: background-color 150ms;
    &:hover, &:active{
      background: fade(@white, 10%);
    }
  }

  /deep/ .vs__selected{
    color: @grayLight
  }

  .account-name /deep/ .entity-details{
    display: none;
  }

  button.circle {
    margin:1em auto;
    padding: 0;
    height: auto;
    width: auto;
    /deep/ .icon{
      color: @black;
      border-radius: 5px;
      background: @grayMedium;
      font-size: 0.8em;
      padding: 0.2em;
      &:hover{
        background: @grayLightMedium
      }
    }
  }

  .account-selector /deep/ .trigger{
    width: 100%;
    text-align: center;
  }

  .admin-menu-container p{
    display: none;
  }

  .expanded button.circle{
    position: absolute;
    top: 5px;
    right: 5px;
    margin-top: 0;
  }

  .bottom{
    text-align: center;
    padding: 0 1rem 1rem;
    margin-top: auto;
    h2 {
      color: @grayMedium;
      font-weight: 300 !important;
    }
    img {
      width: 50%;
    }
  }

  .the-logo{
    margin:0 auto 0.5em;
    width: 50%;
  }

  /deep/ .vs__actions{
    display: none;
  }

  .sidebar-container.expanded {
    width: 220px;
    .sidebar-content{
      width: 220px;
    }
    .account-name{
      justify-content: flex-start !important;
      margin-left: 1.5em;
    }
    .account-name /deep/ .entity-details{
      margin-left: 1em;
      display: block;
    }
    .bottom{
      display: block;
    }
    /deep/ .vs__actions{
      display: flex;
    }
    .admin-menu-container p{
      display: block;
    }
  }
</style>
