import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'agreement-deliverables',
  getters: {
    forAgreement: (state, getters) => agreementId => {
      return getters.all.filter(object => object.agreement === agreementId || false)
    }
  }
})
