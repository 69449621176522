import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'tax-documents',
  getters: {
    forBrand: (state, getters) => brandId => {
      return getters.all.filter(object => object.brand === brandId || false)
    },
    w9sForCreator: (state, getters) => creatorId => {
      return getters.all.filter(object => object.creator === creatorId || false)
    },
    w9sForAgreement: (state, getters) => agreementId => {
      return getters.all.filter(object => object.type === 'W9' && object.agreement === agreementId)
    },
    w9sForCollab: (state, getters) => collabId => {
      return getters.all.filter(object => object.type === 'W9' && object.collab === collabId)
    },
    w9ForAgreement: (state, getters) => agreementId => {
      // get the MOST RECENT one based on createdAt
      return getters.w9sForAgreement(agreementId).sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })[0] || false
    },
    w9ForCollab: (state, getters) => collabId => {
      // get the MOST RECENT one based on createdAt
      return getters.w9sForCollab(collabId).sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })[0] || false
    },
    w9ForCreator: (state, getters) => creatorId => {
      return getters.w9sForCreator(creatorId).sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })[0] || false
    },
    w9ForAccountAndCreator: (state, getters) => creatorId => {
      return getters.forSelectedAccount.reverse().find(doc => doc.type === 'W9' && doc.creator == creatorId)
    },
    forAccountAndCreator: (state, getters) => creatorId => {
      return getters.forSelectedAccount.filter(doc => doc.creator == creatorId)
    },
  }
})
