// store/modules/socket.js

export default {
    namespaced: true,
    state: {
        show: false,
        showTimeout: null,
        reconnectAttemptTimeout: null,
        reconnectSuccessTimeout: null,
        reconnectFailed: false,
        reconnectSucceeded: false,
        isSocketConnected: false, // Assuming initial state
        isUserAuthenticated: false, // Assuming initial state,
        timesConnected: 0
    },

    mutations: {
        SET_SHOW(state, value) {
            state.show = value;
        },
        SET_RECONNECT_FAILED(state, value) {
            state.reconnectFailed = value;
        },
        SET_RECONNECT_SUCCEEDED(state, value) {
            state.reconnectSucceeded = value;
        },
        SET_SOCKET_CONNECTED(state, value) {
            if(value){
                state.timesConnected++;
            }
            state.isSocketConnected = value;
        },
        SET_SHOW_TIMEOUT(state, timeout) {
            state.showTimeout = timeout;
        },
        SET_RECONNECT_ATTEMPT_TIMEOUT(state, timeout) {
            state.reconnectAttemptTimeout = timeout;
        },
        SET_RECONNECT_SUCCESS_TIMEOUT(state, timeout) {
            state.reconnectSuccessTimeout = timeout;
        },
        CLEAR_TIMEOUTS(state) {
            clearTimeout(state.showTimeout);
            clearTimeout(state.reconnectAttemptTimeout);
            clearTimeout(state.reconnectSuccessTimeout);
            state.showTimeout = null;
            state.reconnectAttemptTimeout = null;
            state.reconnectSuccessTimeout = null;
        },
    },

    actions: {
        onProblemDetected({ commit, state }) {
            commit('SET_SHOW_TIMEOUT', setTimeout(() => {
                commit('SET_SHOW', true);
                commit('SET_RECONNECT_ATTEMPT_TIMEOUT', setTimeout(() => {
                    commit('SET_RECONNECT_FAILED', true);
                }, 12000));
            }, 6000));
        },
        onProblemCleared({ commit, state }) {
            commit('CLEAR_TIMEOUTS');
            if (state.show) {
                commit('SET_RECONNECT_SUCCEEDED', true);
                commit('SET_RECONNECT_SUCCESS_TIMEOUT', setTimeout(() => {
                    commit('SET_SHOW', false);
                    commit('SET_RECONNECT_FAILED', false);
                    commit('SET_RECONNECT_SUCCEEDED', false);
                }, 2000));
            }
        },
    },

    getters: {
        socketProblemDetected(state, getters, rootState, rootGetters) {
            return rootGetters['user/isAuthenticated'] && !state.isSocketConnected;
        },
    },
};
