<template>
  <div class="progress-spinner" />
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .progress-spinner{
	position: relative;
    display: inline-block;
    vertical-align: middle;
	height: 20px;
	width: 20px;
	aspect-ratio: 1 / 1;
  }

  .progress-spinner::before {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
    content: '';
	aspect-ratio: 1 / 1;
    box-sizing: border-box;
    display: inline-block;
	height: 100%;
	width: 100%;
    border-radius: 50%;
    border: 1px solid @grayLightMedium;
    border-top-color: @grayLightMedium;
    border-right-color: @grayDark;
    border-bottom-color: @grayDark;
    animation: spinner .6s linear infinite;
    opacity: 0.75;
    float: left;
  }
</style>
