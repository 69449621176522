<template>
  <div
    :class="classes"
    @click="$emit('click', $event)"
  >
    <svg
      v-if="name==='card-with-header'"
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="fill"
        d="M17.999 17c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2h-12c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12zm-12-12h12l.002 10H5.999V5zm-2 14h16v2h-16z"
      />
    </svg>
    <svg
      v-if="name==='chevron'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M36.2,9c-2.6-2.5-6.7-2.4-9.2,0.2s-2.4,6.7,0.2,9.2v0L59,49.2L27,82.3c-2.5,2.6-2.4,6.7,0.2,9.2c2.6,2.5,6.7,2.4,9.2-0.2
				l36.5-37.8c1.2-1.3,1.9-2.9,1.8-4.7c0-1.7-0.7-3.4-2-4.6L36.2,9z"
      />
    </svg>
    <svg
      v-if="name==='youtube'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M49.7,14.4c9.4,0,18.8,0.4,28.2,0.9c3.1,0.2,6.2,0.4,9.3,0.5c4.6,0.3,9.2,3.8,10.5,8.4c0.6,1.9,0.8,4,1,6
        c0.9,7.2,1.3,14.5,1.2,21.8c-0.1,7.1-0.7,14.1-1.7,21.1c-0.7,5.1-4.7,9.6-9.5,10.8c-1.3,0.3-2.6,0.3-3.9,0.4
        c-11.5,0.7-22.9,1.3-34.4,1.3c-8.8,0-17.5-0.4-26.3-0.9c-3.7-0.2-7.4-0.4-11.1-0.7c-4.3-0.3-7.4-2.5-9.6-6c-1.4-2.1-1.8-4.5-2.1-6.9
        c-1.3-10.3-1.8-20.7-1.1-31c0.3-4.7,0.8-9.3,1.4-13.9c0.7-5.6,5.5-10,11.1-10.4C25.1,15,37.4,14.4,49.7,14.4z M38.7,35
        c0,9.7,0,19.3,0,29c9-4.8,17.9-9.6,27-14.5C56.6,44.6,47.7,39.8,38.7,35z"
      />
    </svg>
	<svg v-if="name==='printer'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9"></polyline><path class="fill" d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>
    <svg v-if="name==='file'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file">
		<path class="fill" d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
	</svg>
	<svg v-if="name==='download'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
	<svg
      v-if="name==='tiktok'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2859 3333"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
    >
      <path
        class="fill"
        d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"
      />
    </svg>
    <svg
      v-if="name==='reply'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M41.6,64.2c0,0.8,0,1.4,0,2.1c0,5.1,0,10.3,0,15.4c0,1.9-0.9,4-2.7,4.8c-1.8,0.8-3.9,0.2-5.2-1.1
      c-6.3-6-27.1-27.4-31.7-31.8c-2.5-2.4-2.5-4.4-0.1-6.8c10.9-10.5,21-21.3,31.9-31.8c2.3-2.2,5.6-2,7.1,0.3c0.5,0.8,0.7,1.8,0.7,2.8
      c0.1,4.9,0,9.8,0,14.7c0,0.6,0,1.3,0,2.1c0.8,0,1.4,0,2,0c10.2-0.2,20.3,0.5,30.1,3.5c4.6,1.4,8.9,3.4,12.9,6.2
      c6.5,4.6,10.1,11.3,11.7,18.8c1,4.4,1.3,8.9,1.7,13.4c0.2,2.4-0.8,3.9-2.5,4.5c-1.9,0.7-3.4-0.1-4.8-1.5
      c-10-10.4-22.3-15.5-36.5-16.3c-4.2-0.2-8.4,0.2-12.6,0.3C42.9,63.8,42.3,64,41.6,64.2z"
      />
    </svg>

    <svg
      v-if="name==='refresh'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M21.597,50.732c-0.023-0.485-0.036-0.975-0.036-1.467c0.001-4.08,0.824-7.932,2.309-11.446
				c2.228-5.269,5.965-9.773,10.662-12.944c4.702-3.173,10.324-5.016,16.438-5.019c3.694,0.002,7.203,0.68,10.443,1.913l-4.094,5.745
				c-0.69,0.968-0.729,2.261-0.097,3.268s1.81,1.537,2.982,1.337l19.222-3.259c1.602-0.272,2.676-1.778,2.41-3.381L78.644,6.246
				c-0.196-1.173-1.081-2.115-2.239-2.384c-1.159-0.268-2.367,0.19-3.059,1.159l-3.962,5.562c-5.582-2.664-11.842-4.17-18.414-4.169
				c-5.899,0-11.552,1.199-16.683,3.37c-7.698,3.258-14.227,8.683-18.846,15.518c-4.62,6.83-7.326,15.111-7.323,23.964
				c0,0.711,0.018,1.421,0.052,2.124c0.181,3.708,3.335,6.567,7.042,6.385C18.92,57.594,21.779,54.44,21.597,50.732z"
      />
      <path
        class="fill"
        d="M93.611,45.017c-0.364-3.695-3.654-6.394-7.349-6.029s-6.394,3.654-6.029,7.349
				c0.095,0.961,0.144,1.937,0.144,2.929c-0.002,4.077-0.822,7.928-2.309,11.443c-2.227,5.27-5.966,9.773-10.662,12.944
				c-4.7,3.172-10.323,5.017-16.437,5.019c-5.019-0.001-9.693-1.257-13.813-3.454l4.105-5.764c0.691-0.968,0.729-2.261,0.097-3.268
				c-0.631-1.008-1.81-1.537-2.983-1.339l-19.221,3.26c-1.602,0.271-2.675,1.779-2.41,3.382l3.195,19.232
				c0.195,1.173,1.078,2.115,2.237,2.384c1.16,0.268,2.368-0.19,3.059-1.159l4.076-5.72c0.101,0.066,0.199,0.136,0.305,0.196
				c6.282,3.621,13.602,5.694,21.353,5.692c5.897,0.002,11.551-1.198,16.681-3.369c7.699-3.258,14.229-8.684,18.848-15.518
				c4.619-6.831,7.324-15.112,7.322-23.963C93.82,47.834,93.749,46.416,93.611,45.017z"
      />
    </svg>

    <svg
      v-if="name==='backspace'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M92.273,10.203H47.788c-0.145,0-0.286,0.013-0.429,0.021c-4.484,0-7.205,1.099-10.021,3.915L4.266,48.46
				c-1.152,1.249-1.825,2.787-1.729,4.517c0,1.634,0.673,3.268,1.921,4.42L37.53,89.22c2.313,2.312,5.47,3.719,8.775,3.719
				c0.161,0.011,0.32,0.024,0.482,0.024h45.486c3.715,0,6.727-3.012,6.727-6.727V16.93C99,13.215,95.988,10.203,92.273,10.203z
				M78.912,63.01c1.689,1.688,1.689,4.426,0,6.114c-1.688,1.688-4.426,1.688-6.113,0L61.131,57.457L49.465,69.124
				c-1.688,1.688-4.427,1.688-6.115,0c-1.689-1.688-1.689-4.427,0-6.114l11.666-11.667L43.35,39.675c-1.689-1.688-1.689-4.426,0-6.114
				c1.688-1.689,4.426-1.689,6.115,0l11.666,11.667l11.668-11.667c1.688-1.689,4.426-1.689,6.113,0c1.689,1.688,1.689,4.426,0,6.114
				L67.246,51.343L78.912,63.01z"
      />
    </svg>
    <svg
      v-if="name==='star'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M93.6,35.9l-8.2-0.2l-21.4-0.4l-4.3-12.5L57,15.1l-2.7-7.7c0,0,0,0,0,0c-2.4-6.9-6.3-6.9-8.7,0c0,0,0,0,0,0
	L43,15.1l-2.7,7.7l-4.3,12.5l-21.4,0.4l-8.2,0.2c-7.3,0.1-8.5,3.9-2.7,8.3c0,0,0,0,0,0l6.5,4.9l6.5,4.9l10.6,8L21,82.6l-2.4,7.8
	c-2.1,7,1.1,9.3,7,5.1c0,0,0,0,0,0l6.7-4.7l6.7-4.7L50,78.6l10.9,7.6l6.7,4.7l6.7,4.7c0,0,0,0,0,0c6,4.1,9.1,1.8,7-5.1l-8.6-28.3
	l17.1-12.9l6.5-4.9C102.1,39.8,100.9,36.1,93.6,35.9z"
      />
    </svg>
    <svg
      v-if="name==='heart'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M49.9,84.4c0.1,0,0.2,0,0.3,0c0.2-0.2,0.3-0.4,0.6-0.6c3.7-3.4,7.5-6.9,11.2-10.3c5.4-4.9,10.8-9.9,16.2-14.8
			c2.7-2.5,5.5-4.9,8.1-7.5c3.1-3,4.9-6.8,5.6-11.1c0.1-0.5,0.2-1,0.3-1.5c0-1,0-2.1,0-3.1c-0.3-1.3-0.5-2.6-0.8-3.9
			c-0.7-2.7-2.1-5.2-3.8-7.4c-1.6-2-3.4-3.6-5.6-4.9c-2.5-1.5-5.2-2.4-8.1-2.9c-0.5-0.1-1.1-0.2-1.6-0.3c-1.2,0-2.4,0-3.6,0
			c-1.1,0.2-2.3,0.4-3.4,0.7c-3.2,0.7-6.1,2.1-8.6,4.2c-2,1.7-3.9,3.5-5.9,5.3c-0.2,0.2-0.4,0.3-0.6,0.5C49,25.9,48,25,47,24.1
			c-1.8-1.6-3.6-3.2-5.6-4.6c-2.5-1.8-5.4-2.7-8.5-3.2c-0.5-0.1-1-0.2-1.4-0.3c-1.3,0-2.5,0-3.8,0c-0.9,0.2-1.8,0.3-2.7,0.5
			c-3.2,0.6-6.2,1.9-8.8,3.9c-2.6,1.9-4.6,4.4-6.1,7.2c-1.2,2.2-1.8,4.6-2.2,7.1c0,0.2-0.1,0.4-0.1,0.6c0,1,0,2.1,0,3.1
			c0.2,1.2,0.4,2.4,0.7,3.6c0.7,2.9,2,5.5,4,7.7c1.8,2,3.9,3.9,5.9,5.8c3.6,3.4,7.2,6.7,10.9,10c3.1,2.8,6.1,5.6,9.2,8.4
			c3.7,3.4,7.4,6.8,11.1,10.2C49.8,84.3,49.8,84.4,49.9,84.4z"
      />
    </svg>
    <svg
      v-if="name==='heart-outline'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="stroke"
        d="M49.9,84.4c0.1,0,0.2,0,0.3,0c0.2-0.2,0.3-0.4,0.6-0.6c3.7-3.4,7.5-6.9,11.2-10.3c5.4-4.9,10.8-9.9,16.2-14.8
			c2.7-2.5,5.5-4.9,8.1-7.5c3.1-3,4.9-6.8,5.6-11.1c0.1-0.5,0.2-1,0.3-1.5c0-1,0-2.1,0-3.1c-0.3-1.3-0.5-2.6-0.8-3.9
			c-0.7-2.7-2.1-5.2-3.8-7.4c-1.6-2-3.4-3.6-5.6-4.9c-2.5-1.5-5.2-2.4-8.1-2.9c-0.5-0.1-1.1-0.2-1.6-0.3c-1.2,0-2.4,0-3.6,0
			c-1.1,0.2-2.3,0.4-3.4,0.7c-3.2,0.7-6.1,2.1-8.6,4.2c-2,1.7-3.9,3.5-5.9,5.3c-0.2,0.2-0.4,0.3-0.6,0.5C49,25.9,48,25,47,24.1
			c-1.8-1.6-3.6-3.2-5.6-4.6c-2.5-1.8-5.4-2.7-8.5-3.2c-0.5-0.1-1-0.2-1.4-0.3c-1.3,0-2.5,0-3.8,0c-0.9,0.2-1.8,0.3-2.7,0.5
			c-3.2,0.6-6.2,1.9-8.8,3.9c-2.6,1.9-4.6,4.4-6.1,7.2c-1.2,2.2-1.8,4.6-2.2,7.1c0,0.2-0.1,0.4-0.1,0.6c0,1,0,2.1,0,3.1
			c0.2,1.2,0.4,2.4,0.7,3.6c0.7,2.9,2,5.5,4,7.7c1.8,2,3.9,3.9,5.9,5.8c3.6,3.4,7.2,6.7,10.9,10c3.1,2.8,6.1,5.6,9.2,8.4
			c3.7,3.4,7.4,6.8,11.1,10.2C49.8,84.3,49.8,84.4,49.9,84.4z"
      />
    </svg>
    <svg
      v-if="name==='phone'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M88.6,73.8c-1-1.2-2.2-2.3-3.4-3.3c-1.7-1.5-3.4-2.9-5.2-4.3c-1.5-1.2-3.2-2.3-5-3c-1.4-0.5-2.8-0.8-4.2-0.7
			c-1.5,0.1-2.9,0.7-4,1.6c-1,0.7-1.9,1.5-2.8,2.3c-0.7,0.6-1.4,1.3-2.1,1.9c-0.6,0.6-1.2,1.2-1.8,1.8c-1.2,1-2.5,1.5-4.1,1.5
			c-1.7,0-3.2-0.6-4.7-1.4c-2.5-1.3-4.7-3.1-6.7-5.1c-2.3-2.2-4.5-4.6-6.6-7.1c-2-2.4-3.9-5-5.4-7.7c-1-1.8-1.9-3.7-2.2-5.7
			c-0.2-1.2-0.2-2.3,0.1-3.5c0.4-1.5,1.3-2.7,2.5-3.6c1.3-1,2.6-1.8,3.8-3c0.9-0.8,1.8-1.6,2.7-2.5c2-2.2,2.5-4.7,1.9-7.6
			c-0.4-2-1.3-3.9-2.3-5.7c-1.2-2-2.5-4-3.8-6c-1.1-1.8-2.4-3.4-4-4.8c-0.9-0.8-1.9-1.4-3.1-1.7c-0.4-0.1-0.7-0.1-1.1-0.2
			c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.8,0.1-1.2,0.2c-1.2,0.3-2.2,1-3.2,1.8c-1.3,1.1-2.6,2.2-3.9,3.3c-1.4,1.1-2.6,2.4-3.7,3.8
			c-1.3,1.7-2.5,3.5-3.4,5.5c-1,2.2-1.7,4.5-2.1,7c-0.3,1.8-0.5,3.7-0.4,5.5c0.1,3.3,0.6,6.5,1.5,9.7c1.3,4.6,3.3,8.9,5.7,12.9
			c2.4,4.2,5.2,8.1,8.3,11.8c1.9,2.3,3.9,4.6,5.9,6.8c1.9,2,3.8,3.9,5.8,5.7c3.5,3.2,7.2,6.1,11.3,8.5c3.8,2.3,7.9,4,12.2,5
			c1.5,0.3,3,0.6,4.5,0.7c0.4,0,0.9,0.1,1.3,0.1c0.6,0,1.2,0,1.8,0c0.5,0,0.9-0.1,1.4-0.1c2-0.1,3.9-0.5,5.8-1.1
			c3-0.9,5.8-2.3,8.4-4.2c1-0.8,2-1.7,2.9-2.5c1-0.9,2.1-1.8,3.1-2.8c1.1-1,1.9-2.2,2.2-3.6c0-0.1,0.1-0.3,0.1-0.4
			c0-0.4,0-0.7,0-1.1c0-0.2-0.1-0.4-0.1-0.7C90.6,76.4,89.7,75,88.6,73.8z"
      />
    </svg>
    <svg
      v-if="name==='tag'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 442.688 442.688"
    >
		<path class="fill" d="M442.666,178.822l-4.004-145.078c-0.447-16.222-13.504-29.279-29.727-29.728l-145.08-4.004
			c-8.475-0.237-16.493,2.97-22.468,8.945L8.954,241.391c-11.924,11.924-11.924,31.325,0,43.249l149.083,149.082
			c11.951,11.953,31.296,11.956,43.25,0.001L433.721,201.29C439.636,195.374,442.897,187.184,442.666,178.822z M376.238,139.979
			c-20.323,20.322-53.215,20.324-73.539,0c-20.275-20.275-20.275-53.265,0-73.539c20.323-20.323,53.215-20.324,73.539,0
			C396.512,86.714,396.512,119.704,376.238,139.979z" />	
    </svg>
    <svg
      v-if="name==='instagram'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <defs>
        <radialGradient
          id="instagram"
          r="150%"
          cx="30%"
          cy="107%"
        >
          <stop
            stop-color="#fdf497"
            offset="0"
          />
          <stop
            stop-color="#fdf497"
            offset="0.05"
          />
          <stop
            stop-color="#fd5949"
            offset="0.45"
          />
          <stop
            stop-color="#d6249f"
            offset="0.6"
          />
          <stop
            stop-color="#285AEB"
            offset="0.9"
          />
        </radialGradient>
      </defs>
      <path
        class="fill"
        d="M76.5,17.3c-3.3,0-5.9,2.6-5.9,5.9c0,3.4,2.6,6.1,6,6.1c3.3,0,6-2.7,6-6C82.6,19.9,79.9,17.2,76.5,17.3z"
      />
      <path
        class="fill"
        d="M50,24.3c-14.2,0-25.7,11.4-25.7,25.6c0,14.2,11.4,25.7,25.6,25.7C64,75.6,75.6,64.1,75.6,50C75.7,35.8,64.2,24.3,50,24.3z
        M49.9,66.6c-9.2-0.1-16.6-7.6-16.6-16.7c0-9.2,7.5-16.6,16.7-16.6c9.2,0,16.7,7.6,16.6,16.7C66.5,59.1,59,66.6,49.9,66.6z"
      />
      <path
        class="fill"
        d="M98.2,20.1C95.4,9.1,86.6,1.8,74.8,0.7C70,0.2,65.1,0,60.2,0C49.6,0,39.1,0,28.5,0.3C20.5,0.6,13.2,3,7.5,9.1
				c-4.9,5.3-7.1,11.7-7.2,18.7C0.1,42.6,0.1,57.3,0.4,72C0.6,81.1,4,88.9,11.7,94.4c5.4,3.8,11.6,5,18,5.3c4.4,0.2,8.9,0.2,13.3,0.2
				c0.6,0,5.7,0.1,6.6,0.2c3.6-0.1,12.2-0.1,15.6-0.3c4.5-0.3,9-0.3,13.4-1.3C89.8,96,97.5,87.7,99,76.2c0.7-5.4,0.6-11,0.9-16.4
				c0.1-1.6,0.1-8.2,0.2-10.7c-0.1-4.4-0.1-12.7-0.3-16.7C99.4,28.3,99.2,24.1,98.2,20.1z M90.4,72.8c-0.2,4.2-1.5,8.1-4.3,11.3
				c-3.5,4.1-8.1,6-13.2,6.2c-8.9,0.4-17.8,0.5-26.7,0.5c-6.4,0-12.8-0.2-19.2-0.5c-3.6-0.2-7-1.2-10-3.3c-4.6-3.2-7-7.8-7.4-13.2
				c-0.5-7.9-0.6-15.9-0.9-23C9,42.4,8.8,34.7,9.5,27.2c1.1-11.4,7.2-17.4,18.7-17.7c14.4-0.4,28.9-0.2,43.4,0c5,0.1,9.7,1.5,13.4,5.1
				c3.6,3.4,5.2,7.8,5.4,12.6c0.4,8.8,0.5,17.7,0.5,26.5C90.9,60,90.7,66.4,90.4,72.8z"
      />
    </svg>
    <svg
      v-if="name==='twitter'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M66.2,13.6c1.4,0,2.8,0,4.2,0c0,0,0.1,0.1,0.1,0.1c4.2,0.6,7.9,2.4,10.9,5.4c0.2,0.2,0.5,0.2,0.8,0.2c1.5-0.4,3-0.7,4.5-1.3
	c2.3-0.9,4.4-1.9,6.7-2.9c-1.4,4.1-4,7.3-7.6,9.8c3.5-0.3,6.7-1.3,10.1-2.7c-0.2,0.3-0.2,0.4-0.3,0.6c-2.3,3.3-5,6.1-8.2,8.5
	c-0.4,0.3-0.6,0.6-0.6,1.2c0.1,4-0.2,8-1,12C83,57.2,76.8,67.8,66.7,76c-8.5,6.9-18.5,10.3-29.3,10.9c-9.3,0.5-18.1-1.3-26.5-5.4
	c-1.6-0.8-3.2-1.7-4.7-2.6c0-0.1,0-0.2,0.1-0.2c4.8,0.5,9.5,0.1,14.2-1.2c4.6-1.3,8.8-3.4,12.8-6.5c-9.7-0.3-15.8-7.5-17-12.6
	c1.3,0,2.6,0.1,3.9,0c1.3-0.1,2.6-0.3,3.8-0.5c-4.2-1.2-7.7-3.4-10.4-6.8c-2.7-3.4-4-7.2-4-11.6c1.4,0.5,2.7,1.1,4,1.4
	c1.3,0.4,2.6,0.5,3.9,0.8c0-0.1,0-0.2,0-0.2c-9.1-6.7-9.4-18-5.3-24.4c10,11.7,22.6,18.2,38,19.3c-0.1-0.9-0.2-1.8-0.3-2.6
	C49,25.1,54.4,17,62.7,14.5C63.9,14.1,65,13.9,66.2,13.6z"
      />
    </svg>
    <svg
      v-if="name==='tumblr'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M41.2,5.9c4.4,0,8.7,0,13.1,0c0,7.5,0,15,0,22.5c5.9,0,11.8,0,17.7,0c0,4.5,0,8.9,0,13.4c-5.9,0-11.8,0-17.7,0
	c0,0.1,0,0.2,0,0.2c0,9.1,0,18.2,0,27.2c0,1.7,0.3,3.4,1.1,5c0.4,0.7,0.8,1.4,1.4,1.9c1.3,1.2,2.9,1.7,4.7,1.8c1,0.1,1.9,0,2.9,0
	c1.1-0.1,2.2-0.2,3.3-0.6c0.8-0.2,1.5-0.5,2.3-0.8c0,0.1,0.1,0.1,0.1,0.2c1.2,3.5,2.3,6.9,3.5,10.4c0.2,0.6,0.4,1.2,0.6,1.8
	c0,0.1,0,0.1,0,0.2c-0.6,0.8-1.4,1.4-2.2,1.8c-3.6,1.9-7.5,3-11.5,3.3c-2.5,0.2-5,0.1-7.4-0.3c-2.3-0.4-4.6-1-6.8-1.9
	c-1.8-0.8-3.6-1.8-5.1-3c-2.4-2-4.4-4.4-5.9-7.1c-1.9-3.4-2.9-7-2.9-10.8c0-9.6,0-19.2,0-28.9c0-0.1,0-0.2,0-0.4c-3,0-6,0-8.9,0
	c0-3.8,0-7.6,0-11.4c0.1,0,0.2-0.1,0.3-0.1c3-1.1,5.6-2.7,8-4.7c2.7-2.3,4.6-5,6.1-8.2c1.6-3.3,2.6-6.9,3-10.5
	C40.7,6.4,40.9,6.1,41.2,5.9z"
      />
    </svg>
    <svg
      v-if="name==='envelope'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M13.2,76.8c24.5,0,49.1,0,73.6,0c0.4,0,0.8-0.1,1.2-0.2c1.7-0.6,3.4-3.3,3.4-5.4c0-13.4,0-26.8,0-40.2c0-0.2,0-0.4,0-0.7
			C77.5,40.8,63.8,51.4,50,61.9c-0.2-0.2-0.4-0.3-0.6-0.4c-2.3-1.8-4.6-3.5-6.8-5.3c-3.3-2.5-6.7-5.1-10-7.6
			c-3.1-2.4-6.1-4.7-9.2-7.1c-3.4-2.6-6.8-5.2-10.2-7.8c-1.5-1.1-3-2.3-4.5-3.4c0,13.9,0,27.9,0,41.8C9,73,9.4,73.9,9.9,74.8
			C10.7,75.9,11.6,76.8,13.2,76.8z"
      />
      <path
        class="fill"
        d="M11.1,25c1.7,1.4,3.4,2.7,5.1,4.1c2.7,2.1,5.5,4.2,8.2,6.2c2.4,1.8,4.8,3.7,7.2,5.5c2.7,2.1,5.4,4.1,8.1,6.2
			c3.3,2.5,6.5,5,9.8,7.5c0.2,0.1,0.6,0.1,0.7,0c1.3-1,2.7-2,4-3c3-2.3,6.1-4.6,9.1-6.9c2.7-2.1,5.5-4.1,8.2-6.2
			c3.1-2.3,6.2-4.7,9.3-7c2.6-2,5.2-4.1,7.8-6.2c0.2-0.2,0.5-0.6,0.4-0.7c-0.1-0.2-0.5-0.5-0.7-0.5c-0.7-0.1-1.5-0.2-2.2-0.2
			c-12.1,0-24.1,0-36.2,0c-6.3,0-12.7,0-19,0c-5.8,0-11.6,0-17.3,0c-0.7,0-1.5,0-2.2,0.2C10.6,24.1,10.5,24.5,11.1,25z"
      />
    </svg>
    <svg
      v-if="name==='library'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M92.3,20.1l-10.2-3.5c-1.2-5-5.7-8.7-11.1-8.7H57L36,0.6c-6-2.1-12.5,1.1-14.6,7.1l-0.1,0.2h-9.9C5.1,7.9,0,13,0,19.3v59.6
      c0,6.3,5.1,11.5,11.5,11.5h31.7L65.3,98c6,2.1,12.5-1.1,14.6-7.1l19.5-56.3C101.4,28.7,98.3,22.2,92.3,20.1z M9.9,19.3
      c0-0.9,0.7-1.6,1.6-1.6h6.5H71c0.9,0,1.6,0.7,1.6,1.6v57.4c-2.2-2-5.8-3.2-8.7-4.4c-1.5-0.6-3-1.3-4.5-1.8c-2.2-0.8-4.4-1.6-6.6-2.4
      c0-1.7,0-3.4,0-5c0-0.6,0.2-0.8,0.7-1c1.6-0.6,3.2-1.2,4.6-2c1.9-1.1,3.1-2.8,3.3-4.9c0.2-1.2,0.2-2.5,0.1-3.7
      c-0.6-5-1.3-10-2.1-14.9c-0.5-3.1-1.4-6.2-3-9c-1.8-2.9-4.3-4.5-7.8-4.5c-0.3,0-0.7-0.2-1-0.4c-3.4-2.8-7.4-3.4-11.5-2.2
      c-7,2-11.7,6.8-14,13.7c-1.1,3.4-2,6.9-2.9,10.4c-1.1,4.1-1.9,8.3-1.5,12.7c0.4,4.7,3.1,7.6,7.8,8.2c1.1,0.1,2.2,0.1,3.3,0.2
      c0,0.9,0,1.7,0,2.6c-1.1,0.4-2.1,0.7-3.2,1.1c-2.8,1.1-5.7,2.1-8.5,3.4c-2.7,1.2-5.9,2.5-7.4,4.8V41.1V19.3z M50.9,43.7
      c0.8,0.9,1.7,1.6,3,1.9c0.7,0.1,1.1,0.8,1.2,1.6c0.2,2.1-1.4,4.4-3.5,5c-0.3,0.1-0.6,0.4-0.6,0.6c-0.9,2.6-2.2,5-4.1,7
      c-0.6,0.7-1.3,1.3-2,1.8c-2.7,1.9-5.8,1.9-8.4-0.1c-2.4-1.8-4-4.2-5.3-6.9c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-1.1-0.7-1.9-2.1-2.2
      c-1.6-0.4-2.9-3.3-2.5-5c0.2-0.7,0.6-1.2,1.4-1.3c1-0.2,2.1-0.5,3.1-0.7c3-0.8,10.7-3,12.2-4.2l3.8-3.7v0
      C47.6,39.4,49,41.7,50.9,43.7z M32.3,64.9c5.6,4.8,11.8,4.6,17.1-0.2l0.1,3.8c0.2,1.6-0.3,3.1-1.2,4.4c-1,1.3-1.9,2.7-3,4
      c-0.5,0.6-1.1,1.1-1.7,1.5c-2,1.5-3.7,1.5-5.7,0c-2.1-1.6-3.4-3.7-4.8-5.9c-0.6-1.1-1-2.1-0.9-3.4C32.4,67.8,32.3,66.4,32.3,64.9z"
      />
    </svg>
    <svg
      v-if="name==='library-inverted'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M90.5,15.8l-5.7-1c1,1.7,1.6,3.6,1.6,5.8v57c0,6.1-4.9,11-11,11h-50l49.9,9.2c6.3,1.2,12.3-3,13.5-9.3l10.9-59.2
        C101,23,96.8,17,90.5,15.8z"
      />
      <path
        class="fill"
        d="M27,58.5c-1.2-0.1-2.4-0.1-3.6-0.2c-5-0.7-7.8-3.7-8.3-8.8c-0.4-4.7,0.5-9.1,1.6-13.6c1-3.7,1.9-7.5,3.2-11.1
        c2.5-7.4,7.4-12.5,15-14.7C36,9.8,37,9.6,38.1,9.6c0.6,0,1.1,0,1.7,0c2.6,0.2,5.1,1.1,7.4,3c0.3,0.2,0.7,0.4,1,0.4
        c3.7,0,6.5,1.7,8.3,4.9c1.8,3,2.7,6.3,3.2,9.6c0.9,5.3,1.6,10.7,2.3,16c0.2,1.3,0.1,2.6-0.1,3.9c-0.3,2.3-1.5,4.2-3.6,5.3
        c-1.6,0.9-3.3,1.5-5,2.2c-0.5,0.2-0.8,0.4-0.8,1c0.1,1.8,0,3.6,0,5.4c2.4,0.9,4.7,1.7,7,2.6c1.6,0.6,3.2,1.3,4.8,2
        c3.8,1.7,11.2,4.7,13.7,8.5c0.5-1.3,0.8-2.7,0.8-4.2V13.7V13c0-1-0.2-2.1-0.4-3C77.3,5.4,73,2,68,2H11C4.9,2,0,6.9,0,13v57
        c0,1.9,0.5,3.7,1.3,5.3c0.1-0.4,0.1-0.8,0.2-1.2c0.4-2.1,3-3.7,6-5.1c0.1,0,0.1-0.1,0.2-0.1c2.5-1.1,5.2-2,6.8-2.8
        c3-1.4,6-2.5,9.1-3.7c1.1-0.4,2.3-0.8,3.4-1.2C27,60.3,27,59.4,27,58.5z"
      />
      <path
        class="fill"
        d="M32.8,67.2c1.2,2,2.5,4,4.4,5.4c1.8,1.4,3.4,1.4,5.2,0c0.6-0.4,1.1-0.9,1.5-1.4c1-1.2,1.8-2.4,2.7-3.7l1-7.5
        c-4.8,4.4-10.6,4.5-15.7,0.1c0,1.4,0.1,2.6,0,3.8C31.9,65.3,32.2,66.3,32.8,67.2z"
      />
      <path
        class="fill"
        d="M50.1,43.9c2-0.5,3.6-2.7,3.4-4.8c-0.1-0.8-0.5-1.4-1.2-1.6c-1.2-0.2-2.1-1-2.9-1.8c-1.8-1.9-3.2-4.2-4.1-6.7v0l-3.7,3.6
        c-1.4,1.2-8.9,3.3-11.8,4.1c-1,0.3-2,0.5-3,0.7c-0.7,0.2-1.1,0.6-1.3,1.3c-0.4,1.6,0.9,4.4,2.4,4.8c1.3,0.3,1.7,1.1,2,2.1
        c0.1,0.2,0.2,0.4,0.3,0.6c1.2,2.6,2.7,5,5.1,6.7c2.6,1.9,5.6,1.9,8.2,0.1c0.7-0.5,1.4-1.1,2-1.7c1.8-2,3.1-4.3,4-6.8
        C49.6,44.3,49.9,44,50.1,43.9z"
      />
    </svg>

    <svg
      v-if="['report','warn','warning','error','notice'].indexOf(name) !== -1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M98,38.5C95.3,26.6,88.8,17,80.1,10.3C71.5,3.7,60.9,0,49.9,0C40.8,0,31.5,2.6,23,8.1C8.7,17.4,0.8,33.2,0.8,49.3
        c0,7.9,1.9,15.8,5.9,23.2c1.8,3.2,3.9,6.2,6.3,9c-1.5,2.1-3,4.1-4.4,6.1c-1.2,1.6-1.8,3.5-1.8,5.5c0,0.1,0,0.3,0,0.4
        c0,1.1,0.4,2.1,0.9,2.9c0.5,0.8,1.1,1.3,1.6,1.9c0.4,0.4,0.8,0.7,1.4,1c0.5,0.3,1.1,0.4,1.7,0.5c1.7,0.2,3.5,0.3,5.2,0.3
        c2.5,0,5-0.2,7.5-0.6c3.7-0.6,7-1.9,10.2-3.1l0,0c0,0,0.1,0,0.2,0l0.2,0c4.7,1.4,9.5,2.2,14.3,2.2c2.8,0,5.6-0.2,8.4-0.7
        c11.9-2,22.1-8.3,29.3-17C95,72.1,99.2,61,99.2,49.3C99.2,45.7,98.8,42.1,98,38.5z M25.3,84.9c0.5-1.2,0.8-2.5,0.8-3.9
        c0-0.8-0.1-1.7-0.3-2.6c-0.2-0.9-0.6-1.7-1.2-2.5c-2.3-3-4.7-5.7-6.4-8.7c-3.4-6-4.9-12.3-4.9-18.4c0-8.1,2.7-15.9,7.6-22.2
        c4.9-6.3,11.8-11.1,20.4-13.2c3-0.7,5.9-1.1,8.9-1.1c8.8,0,17.1,3.2,23.6,8.6c6.5,5.5,11.2,13.2,12.7,22.3c0.3,2,0.5,4,0.5,5.9
        c0,8.7-3.1,17-8.5,23.4C73,79.1,65.4,83.8,56.6,85.3c-2.3,0.4-4.5,0.6-6.7,0.6c-3.8,0-7.6-0.6-11.4-1.9c-1.1-0.4-2.2-0.6-3.3-0.6
        c-1.6,0-3,0.4-4.5,1c-2.2,1-4.3,1.8-6.5,2.3C24.7,86.2,25.1,85.5,25.3,84.9z"
      />
      <path
        class="fill"
        d="M43.9,37c0.5,5.3,1.1,10.6,1.6,15.9c0.1,1.2,0.5,2.2,1.2,3s1.7,1.2,2.9,1.4c0.3,0.1,0.6,0.1,1,0.1c0.8,0,1.6-0.2,2.3-0.7
        c0.6-0.5,1.1-1.2,1.4-2c0.3-0.7,0.4-1.5,0.5-2.2c0.6-5.5,1.2-11,1.7-16.5c0.2-2.4,0.4-4.9,0.5-7.3v0l0-0.2c0-0.5,0-0.9-0.2-1.2
        c-0.2-0.6-0.5-1-1-1.3c-0.5-0.3-1.1-0.4-1.8-0.4c-0.5,0-0.9,0-1.4,0c-0.6,0-1.1,0-1.7,0v0h-0.5c-0.6,0-1.1,0-1.7,0
        c-0.4,0-0.8,0-1.2,0c-0.6,0-1.1,0.1-1.7,0.2c-0.8,0.1-1.5,0.4-1.9,1c-0.4,0.5-0.6,1.2-0.6,2h0l0,0.2c0,0.5,0,1,0.1,1.5
        C43.5,32.5,43.6,34.8,43.9,37z"
      />
      <path
        class="fill"
        d="M54.3,64.3c-1.1-1.2-2.6-1.9-4.3-1.9c-1.7,0-3.2,0.7-4.4,1.9c-1.2,1.1-1.9,2.6-1.9,4.3l0,0l0,0l0,0c0,1.7,0.7,3.2,1.9,4.3
        c1.1,1.1,2.6,1.9,4.3,1.9h0h0c1.7,0,3.2-0.7,4.4-1.8c1.1-1.1,1.9-2.6,1.9-4.3v0l0,0C56.1,67,55.4,65.5,54.3,64.3z"
      />
    </svg>

    <svg
      v-if="name==='ellipsis'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <circle
        class="fill"
        cx="50"
        cy="50"
        r="12.7"
      />
      <circle
        class="fill"
        cx="12.7"
        cy="50"
        r="12.7"
      />
      <circle
        class="fill"
        cx="87.3"
        cy="50"
        r="12.7"
      />
    </svg>

    <svg
      v-if="name==='checkbox'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <circle
        class="icon path circle"
        fill="none"
        stroke-width="6"
        stroke-miterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        class="icon path check"
        fill="none"
        stroke-width="6"
        stroke-linecap="round"
        stroke-miterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>

    <svg
      v-if="name==='clock'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <path
        class="fill"
        d="M77.3,9.8C69.5,4.6,60.1,1.5,50,1.5c-6.7,0-13.1,1.4-19,3.8C22.2,9,14.8,15.2,9.5,23c-5.3,7.8-8.3,17.2-8.3,27.3
	c0,6.7,1.4,13.2,3.8,19c3.7,8.8,9.9,16.2,17.7,21.5c7.8,5.3,17.2,8.3,27.3,8.3c6.7,0,13.2-1.4,19-3.8c8.8-3.7,16.2-9.9,21.5-17.7
	c5.3-7.8,8.3-17.2,8.3-27.3c0-6.7-1.4-13.1-3.8-19C91.2,22.5,85,15.1,77.3,9.8z M82.6,64c-2.7,6.3-7.2,11.7-12.8,15.6
	c-5.7,3.8-12.4,6-19.8,6c-4.9,0-9.5-1-13.8-2.8c-6.3-2.7-11.7-7.2-15.6-12.8c-3.8-5.6-6-12.4-6-19.8c0-4.9,1-9.5,2.8-13.8
	c2.7-6.3,7.2-11.7,12.8-15.6c5.6-3.8,12.4-6,19.8-6c4.9,0,9.5,1,13.8,2.8c6.3,2.7,11.7,7.2,15.6,12.8c3.8,5.7,6,12.4,6,19.8
	C85.3,55.2,84.4,59.8,82.6,64z"
      />
      <path
        class="fill"
        d="M67.3,57.4l-10.6-7.9V25.9c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7v27.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.4
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3
	c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.2
	c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2
	c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0.1,0.1,0.1l13.3,9.9c3,2.2,7.2,1.6,9.4-1.4C70.9,63.8,70.3,59.6,67.3,57.4z"
      />
    </svg>

    <svg
      v-if="name==='arrow'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M87.8,49.5c-7.4,0-14.7,0-22.1,0c-0.2,0-0.4,0-0.7,0c0-0.3,0-0.5,0-0.8c0-12,0-23.9,0-35.9c0-0.3,0-0.5,0-0.8
			c0-0.8-0.4-1.4-1-1.8c-0.5-0.3-1.1-0.4-1.7-0.4c-8,0-16.1,0-24.1,0c-0.3,0-0.7,0-1,0c-1,0-1.9,0.8-2.1,1.8c-0.1,0.3-0.1,0.7-0.1,1
			c0,12,0,24,0,36c0,0.2,0,0.5,0,0.8c-0.3,0-0.5,0-0.8,0c-7.4,0-14.8,0-22.2,0c-1.2,0-2.1,0.7-2.4,1.7c-0.2,1,0.1,1.8,0.8,2.5
			c8.3,7.9,16.5,15.8,24.8,23.8c4.3,4.1,8.6,8.3,12.9,12.4c0.6,0.6,1.4,1,2.2,0.7c0.6-0.2,1.2-0.5,1.6-1c4.3-4,8.5-8.1,12.7-12.2
			c8.3-7.9,16.6-15.9,24.8-23.8c0.9-0.8,1.1-1.8,0.7-2.8C89.8,50,89,49.5,87.8,49.5z"
      />
    </svg>

    <svg
      v-if="name==='bell'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M42.1,0.2c11.7-1.6,28,6,34.2,18.1c1.8,3.5,2.5,7.5,3.5,11.3c0.9,3.7,1.2,7.5,2.1,11.1
		c1.6,6.6,4.6,12.4,10.6,16.1c2.7,1.7,4.9,3.8,5.6,7.2c1.2,6.3-2.6,10.9-8.8,12.5c-13.6,3.3-23.2,5.4-37.7,7.2
		c-10.5,1.3-26.2,1.8-37.2,2.2c-5.1,0.6-9-1.1-11.1-5C1,76.6,1.3,72.3,4.7,68.8c7.6-7.7,7.9-17.2,6.7-27c-0.9-7.6-0.9-15,2.3-22.2
		C18.2,9.9,30.4,1.8,42.1,0.2z"
      />
      <path
        class="fill"
        d="M24.4,89.8c1.4,6.2,7.1,10.6,13.7,10.2c6.5-0.4,11.7-5.4,12.4-11.7L24.4,89.8z"
      />
    </svg>

    <svg
      v-if="name==='lock'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M76.1,44.8V27.2c0-7.2-2.9-13.7-7.6-18.4C63.7,4.1,57.2,1.2,50,1.2c-7.2,0-13.7,2.9-18.4,7.6c-4.7,4.7-7.6,11.3-7.6,18.4
			v17.6c-4.5,5.7-7.2,12.9-7.2,20.7c0,18.4,14.9,33.3,33.3,33.3c18.4,0,33.3-14.9,33.3-33.3C83.3,57.7,80.6,50.5,76.1,44.8z M33,27.2
			c0-4.7,1.9-8.9,5-12c3.1-3.1,7.3-5,12-5c4.7,0,8.9,1.9,12,5c3.1,3.1,5,7.3,5,12v9.7c-5-3-10.8-4.7-17-4.7c-6.2,0-12,1.7-17,4.7V27.2
			z"
      />
    </svg>

    <svg
      v-if="name==='check' || name==='checkmark'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M3,52.8l32.4,32.4l61-61c2.1-2.1,2.1-5.6,0-7.8c-2.1-2.1-5.6-2.1-7.8,0L35.5,69.7L10.8,45.1c-2.1-2.1-5.6-2.1-7.8,0
			S0.9,50.7,3,52.8z"
      />
    </svg>

    <svg
      v-if="name==='eye'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        style="fill:none;"
        d="M79.2,38.9c-2.8-2.5-5.9-4.6-9.5-6.4c6.1,11.4,2,22.8-5.4,28.9c-7.3,6.1-18.1,6.6-25.5,2.3
				C33.6,60.5,29.7,56,28,50c-1.6-6-0.9-11.9,2.2-17.5c-0.4,0.2-0.6,0.3-0.9,0.4c-7.5,4.1-13.4,9.9-18.1,16.9
				c-0.3,0.5-0.1,0.7,0.1,1.1c2,3,4.3,5.8,6.9,8.4c8.2,8.1,17.9,13.1,29.6,13.7c7.7,0.4,15-1.2,21.7-4.8c7.8-4.1,14.1-10.1,19-17.4
				c0.3-0.5,0.2-0.7-0.1-1.1C85.9,45.7,82.9,42,79.2,38.9z"
      />
      <path
        style="fill:none;"
        d="M36.8,46.2c1.4,0.1,2.4-0.8,2.6-2.4c0-0.4,0.1-0.7,0.1-1.1c0.4-4.1,3.3-7.8,7.2-9
				c1.1-0.3,2.2-0.4,3.3-0.5c1.4-0.2,2.4-1.2,2.4-2.6c-0.1-1.4-1.1-2.3-2.5-2.3c-1.1,0-2.2,0.1-3.3,0.3c-6.2,1.3-11.9,7.3-12,14.5
				c0,0.1,0,0.2,0,0.3C34.5,44.9,35.5,46.1,36.8,46.2z"
      />
      <path
        class="fill"
        d="M65,23.3c-6.4-2.1-13-2.8-19.7-2.1c-12.4,1.2-22.8,6.7-31.7,15.2c-3.1,3-5.7,6.3-8,10c-1.6,2.5-1.6,5.1,0,7.6
				c7,11.3,17,18.9,29.5,23.1c4,1.4,8.1,2.1,12.4,2.2c0.5,0,1.1,0.1,1.6,0.2c1,0,2,0,3,0c1.4-0.2,2.9-0.3,4.3-0.5
				c6.8-0.8,13.1-3.2,19-6.6c7.7-4.5,13.9-10.4,18.6-18c0.6-1,1-2.1,1.4-3.1c0-0.7,0-1.4,0-2.1c-1.9-4.9-5.3-8.8-8.9-12.4
				C80.5,30.5,73.2,26.1,65,23.3z M34.6,43.1c0.1-7.1,5.8-13.2,12-14.5c1.1-0.2,2.2-0.3,3.3-0.3c1.5-0.1,2.5,0.9,2.5,2.3
				c0.1,1.4-0.9,2.4-2.4,2.6c-1.1,0.1-2.2,0.2-3.3,0.5c-3.9,1.2-6.9,4.9-7.2,9c0,0.4-0.1,0.7-0.1,1.1c-0.1,1.6-1.1,2.5-2.6,2.4
				c-1.4-0.1-2.3-1.2-2.3-2.8C34.6,43.3,34.6,43.2,34.6,43.1z M88.7,50.7c-4.9,7.3-11.2,13.3-19,17.4c-6.8,3.6-14.1,5.1-21.7,4.8
				c-11.7-0.6-21.4-5.6-29.6-13.7c-2.6-2.5-4.8-5.4-6.9-8.4c-0.2-0.3-0.5-0.6-0.1-1.1c4.7-7.1,10.6-12.9,18.1-16.9
				c0.2-0.1,0.4-0.2,0.9-0.4C27.1,38.1,26.4,43.9,28,50c1.6,6.1,5.5,10.5,10.9,13.7c7.4,4.4,18.2,3.8,25.5-2.3
				c7.3-6.1,11.4-17.5,5.4-28.9c3.5,1.9,6.6,4,9.5,6.4c3.6,3.2,6.6,6.8,9.3,10.8C88.8,50,89,50.3,88.7,50.7z"
      />
    </svg>

    <svg
      v-if="name==='camera'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M86.5,19.4c0,0-7.1,0-12.2,0c-6.9,0-7.1-8.5-14.6-8.5c-5.4,0-14.5,0-19.5,0c-6.8,0-6.6,8.5-14.6,8.5c-6.2,0-12.2,0-12.2,0
	C6.1,19.4,0,25.4,0,32.9v38.7c0,7.5,6.1,13.5,13.5,13.5h73c7.5,0,13.5-6.1,13.5-13.5V32.9C100,25.4,94,19.4,86.5,19.4z M67.1,51
	c0,9.5-7.7,17.1-17.1,17.1c-9.5,0-17.1-7.7-17.1-17.1c0-9.4,7.7-17.1,17.1-17.1C59.5,33.9,67.1,41.6,67.1,51L67.1,51z"
      />
    </svg>

    <svg
      v-if="name==='compose'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M36.9,51.7c-3.8,3.8-6.9,9.3-9,14c-0.2,0.5-0.4,1-0.6,1.4c-0.8,1.8-1.4,3.5-1.8,4.8l0,0c-0.1,0.3-0.2,0.6-0.3,0.9l0,0
        c0,0.1,0,0.2-0.1,0.3c-0.2,0.9-0.3,1.4-0.2,1.5c0.1,0.1,0.6,0,1.5-0.2c0.1,0,0.2-0.1,0.4-0.1h0c0.6-0.2,1.2-0.4,2-0.6
        c1-0.4,2.3-0.8,3.6-1.4c0.5-0.2,1-0.4,1.4-0.6c4.5-2,9.8-5.1,13.9-9.3c6.3-6.3,24.1-23.8,33.3-32.8c2.8-2.8,4.8-4.8,5.4-5.3
        c0.1-0.1,0.2-0.2,0.2-0.2l-0.4-0.4l-5.1-5.2l0,0l-5.5-5.5c0,0-2.2,2.2-5.5,5.5C60.8,27.7,42.5,46,36.9,51.7z"
      />
      <path
        class="fill"
        d="M98.3,6.3c-1.6-1.5-3.1-3.1-4.6-4.6c-2.1-2.1-4.1-2.3-6.4-0.3c-1.7,1.5-3.3,3.2-4.8,4.8c-0.2,0.2-0.3,0.3-0.5,0.5
        c-0.3,0.3-0.6,0.6-0.9,0.9c3.3,3.3,6.6,6.6,9.9,10c0.3,0.3,0.5,0.5,0.8,0.8c0.5-0.5,0.9-0.9,1.4-1.4c1.2-1.2,2.4-2.4,3.6-3.6
        c0.4-0.4,0.7-0.7,1-1.1c0.5-0.8,1-1.6,1.3-2.4C99.7,8.5,99.4,7.3,98.3,6.3z"
      />
      <path
        class="fill"
        d="M79.4,46.9v25.4c0,2.3-0.5,4.4-1.3,6.4c-1.2,2.9-3.3,5.5-6,7.3c-1.3,0.9-2.8,1.6-4.3,2.1c-1.5,0.5-3.2,0.7-4.9,0.7H28.4
        c-2.3,0-4.4-0.5-6.4-1.3c-2.9-1.2-5.5-3.3-7.3-6c-0.9-1.3-1.6-2.8-2.1-4.3c-0.5-1.5-0.7-3.2-0.7-4.9V36.8c0-2.3,0.5-4.4,1.3-6.4
        c1.2-2.9,3.3-5.5,6-7.3c1.3-0.9,2.8-1.6,4.3-2.1c1.5-0.5,3.2-0.7,4.9-0.7h24.5c3.1,0,5.7-2.5,5.7-5.7S56,9,52.9,9H28.4
        c-3.8,0-7.5,0.8-10.8,2.2c-5,2.1-9.2,5.6-12.2,10.1c-1.5,2.2-2.7,4.7-3.5,7.3c-0.8,2.6-1.3,5.4-1.3,8.3v35.4
        c0,3.8,0.8,7.5,2.2,10.8c2.1,5,5.6,9.2,10.1,12.2c2.2,1.5,4.7,2.7,7.3,3.5s5.4,1.2,8.3,1.2h34.5c3.8,0,7.5-0.8,10.8-2.2
        c5-2.1,9.2-5.6,12.2-10.1c1.5-2.2,2.7-4.7,3.5-7.3c0.8-2.6,1.3-5.4,1.3-8.3V46.9c0-3.1-2.5-5.7-5.7-5.7S79.4,43.7,79.4,46.9z"
      />
    </svg>

    <svg
      v-if="name==='pencil'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M16.1,69.3C11,74.4,6.8,81.8,4,88.1c-0.3,0.6-0.6,1.3-0.8,1.9c-1,2.5-1.8,4.7-2.4,6.4l0,0
        c-0.1,0.4-0.3,0.8-0.4,1.2l0,0c0,0.1-0.1,0.2-0.1,0.3c-0.3,1.2-0.4,1.9-0.3,2c0.1,0.1,0.9,0,2.1-0.2c0.2,0,0.3-0.1,0.5-0.1h0
        c0.8-0.2,1.6-0.5,2.7-0.8c1.4-0.5,3-1.1,4.8-1.8c0.6-0.3,1.3-0.6,1.9-0.9c6-2.7,13.1-6.9,18.7-12.5c8.4-8.4,32.4-32,44.6-44.1
        c3.8-3.8,6.5-6.4,7.2-7.1c0.1-0.1,0.2-0.2,0.2-0.2l-0.5-0.5l-6.9-6.9l0,0l-7.4-7.4c0,0-2.9,2.9-7.4,7.4
        C48.2,37.2,23.6,61.8,16.1,69.3z"
      />
      <path
        class="fill"
        d="M98.5,8.5c-2.1-2-4.2-4.1-6.2-6.2c-2.8-2.9-5.5-3.1-8.5-0.4c-2.3,2.1-4.4,4.3-6.5,6.5C77,8.6,76.8,8.8,76.6,9
        c-0.4,0.4-0.8,0.8-1.2,1.3c4.4,4.4,8.9,8.9,13.3,13.4c0.3,0.3,0.7,0.7,1,1c0.6-0.6,1.2-1.2,1.9-1.9c1.6-1.6,3.2-3.2,4.8-4.9
        c0.5-0.5,1-0.9,1.4-1.5c0.7-1,1.3-2.1,1.8-3.2C100.3,11.4,99.9,9.9,98.5,8.5z"
      />
    </svg>

    <svg
      v-if="name==='megaphone'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M97.8,65.1c0,2.5-0.2,5-0.9,7.5c-0.1,0.4-0.3,0.8-0.4,1.2c-1.5,3.9-4.8,5.5-8.7,4.2c-1.9-0.6-3.6-1.6-5.3-2.7
	c-3.4-2-6.7-4.1-10.4-5.4c-6-2.2-12.2-2.8-18.5-2.3c-1.4,0.1-2.7,0.3-4,0.7c-0.6,0.1-1.2,0.5-1.8,0.9c-1.5,1.1-1.9,2.6-1.1,4.2
	c1.7,4,3.4,7.9,5.2,11.8c0.3,0.7,1.1,1.3,1.8,1.8c0.7,0.6,1.5,0.9,2.2,1.5c2.2,1.7,2.1,4.8-0.1,6.4c-0.4,0.3-0.9,0.5-1.4,0.7
	c-3.6,1.4-7.2,2.7-10.7,4.1c-1.2,0.5-2.2,0.3-3.3-0.3c-1.7-1-2.8-2.6-3.6-4.3c-2.1-4.7-4.1-9.4-6.1-14.1c-0.5-1.2-1.3-1.9-2.6-2
	c-0.4-0.1-0.9-0.2-1.3-0.2c-1.8-0.3-3.4-1.2-4.6-2.6c-0.3-0.4-0.5-0.5-1-0.3c-1,0.4-2,0.6-3,1c-1.9,0.8-3.8,0.9-5.7,0.2
	c-5.3-1.9-9.1-5.3-10.2-11.1c-0.6-3.2-0.1-6.3,1.3-9.2c1-1.9,2.4-3.2,4.4-4c8.8-3.6,17.3-7.7,25.2-13.1c4.2-2.9,8-6.1,11.4-9.9
	c5-5.6,8-12.1,9.4-19.4c0.3-1.7,0.8-3.4,1.3-5.1c0.2-0.7,0.5-1.3,0.9-2c1.7-2.8,4.7-3.9,7.8-3c2.6,0.8,4.7,2.4,6.7,4.1
	c4.3,3.8,7.9,8.3,11,13.2c7.4,11.4,12.5,23.7,15,37c0.6,3.4,0.9,6.9,1.3,10.4C97.9,65.1,97.8,65.1,97.8,65.1z M62.5,19.2
	c0.4,2.8,0.6,5.7,1.1,8.5c2,11.5,6.7,21.9,14.1,30.9c2.2,2.6,4.5,5.1,7.4,6.9c1.6,1,1.9,0.9,2.2-1c0.5-3.4,0.2-6.7-0.4-10
	C84.5,40.9,78.7,28.9,70,18.2c-1.6-2-3.3-3.8-5.5-5.3c-1-0.7-1.2-0.6-1.4,0.6C62.8,15.4,62.7,17.3,62.5,19.2z"
      />
    </svg>

    <svg
      v-if="name==='flash'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M56.9,37.2c2.8,1.6,5.6,3.3,8.4,4.9c4.7,2.7,9.3,5.4,14,8.1c3.4,2,3.8,6.1,0.9,8.8c-6.9,6.5-13.8,13-20.6,19.5
	c-6.2,5.8-12.3,11.7-18.5,17.5c-1.5,1.5-3.1,2.5-5.4,2c-3.2-0.7-5.2-3.8-4-6.9c1.4-3.6,2.9-7.1,4.3-10.6c2.4-5.8,4.8-11.5,7.2-17.2
	c0-0.1,0-0.1,0-0.3c-1.5-0.9-3-1.8-4.6-2.7c-5.9-3.4-11.9-6.8-17.8-10.3c-3.4-2-3.8-6.1-0.9-8.8c6.9-6.5,13.8-13,20.7-19.5
	c6.2-5.9,12.4-11.7,18.5-17.6c2.3-2.2,4.4-2.6,6.8-1.4c2.4,1.2,3.5,3.9,2.5,6.5c-1.4,3.5-2.8,7-4.3,10.5c-2.4,5.8-4.8,11.5-7.2,17.2
	C56.9,36.9,56.9,37,56.9,37.2z"
      />
    </svg>

    <svg
      v-if="name==='chainlink'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M50.6,32.8l17.5-17.5l0,0c1.1-1.1,2.4-1.9,3.8-2.5c1.4-0.6,2.8-0.8,4.3-0.8s2.9,0.3,4.3,0.8c1.4,0.6,2.6,1.4,3.8,2.5l0,0
				c1.1,1.1,1.9,2.4,2.5,3.8c0.6,1.4,0.8,2.8,0.8,4.3c0,1.5-0.3,2.9-0.8,4.3c-0.6,1.4-1.4,2.6-2.5,3.8L65.1,50.5v0
				c-1.1,1.1-2.4,1.9-3.8,2.5c-1.4,0.6-2.8,0.8-4.3,0.8c-1.5,0-2.9-0.3-4.3-0.8c-1.4-0.6-2.6-1.4-3.8-2.5c-2.1-2.1-5.6-2.1-7.8,0
				s-2.1,5.6,0,7.8c2.2,2.2,4.7,3.8,7.4,4.9c2.7,1.1,5.6,1.6,8.4,1.6c2.9,0,5.7-0.5,8.4-1.6c2.7-1.1,5.2-2.7,7.4-4.9l0,0L92,39.1l0,0
				c2.2-2.2,3.8-4.7,4.9-7.4c1.1-2.7,1.6-5.6,1.6-8.4c0-2.9-0.5-5.7-1.6-8.4c-1.1-2.7-2.7-5.2-4.9-7.4l0,0c-2.2-2.2-4.7-3.8-7.4-4.9
				c-2.7-1.1-5.6-1.6-8.4-1.6c-2.9,0-5.7,0.5-8.4,1.6c-2.7,1.1-5.2,2.7-7.4,4.9L42.8,25c-2.1,2.1-2.1,5.6,0,7.8S48.5,34.9,50.6,32.8z"
      />
      <path
        class="fill"
        d="M50.2,67.4L32.7,84.9l0,0c-1.1,1.1-2.4,1.9-3.8,2.5c-1.4,0.6-2.8,0.8-4.3,0.8c-1.5,0-2.9-0.3-4.3-0.8
				c-1.4-0.6-2.6-1.4-3.8-2.5l0,0c-1.1-1.1-1.9-2.4-2.5-3.8c-0.6-1.4-0.8-2.8-0.8-4.3c0-1.5,0.3-2.9,0.8-4.3c0.6-1.4,1.4-2.6,2.5-3.8
				l0,0l19.1-19.1c1.1-1.1,2.4-1.9,3.8-2.5c1.4-0.6,2.8-0.8,4.3-0.8c1.5,0,2.9,0.3,4.3,0.8c1.4,0.6,2.6,1.4,3.8,2.5
				c2.1,2.1,5.6,2.1,7.8,0c2.1-2.1,2.1-5.6,0-7.8c-2.2-2.2-4.7-3.8-7.4-4.9c-2.7-1.1-5.6-1.6-8.4-1.6c-2.9,0-5.7,0.5-8.4,1.6
				c-2.7,1.1-5.2,2.7-7.4,4.9L8.8,61l0,0c-2.2,2.2-3.8,4.7-4.9,7.4c-1.1,2.7-1.6,5.6-1.6,8.4c0,2.9,0.5,5.7,1.6,8.4
				C5,88,6.6,90.5,8.8,92.7l0,0c2.2,2.2,4.7,3.8,7.4,4.9c2.7,1.1,5.6,1.6,8.4,1.6s5.7-0.5,8.4-1.6c2.7-1.1,5.2-2.7,7.4-4.9L58,75.2
				c2.1-2.1,2.1-5.6,0-7.8S52.4,65.3,50.2,67.4z"
      />
    </svg>

    <svg
      v-if="name==='power'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M67.3,27c4.4,2.8,8,6.6,10.6,11.1c2.5,4.5,4,9.7,4,15.3c0,8.6-3.5,16.4-9.1,22c-5.7,5.7-13.4,9.1-22,9.1
		c-8.6,0-16.4-3.5-22-9.1c-5.7-5.7-9.1-13.4-9.1-22c0-5.6,1.4-10.8,4-15.3C26,33.6,29.7,29.8,34,27c3-1.9,3.9-5.9,2-9
		c-1.9-3-5.9-3.9-9-2c-6.2,3.9-11.3,9.3-14.9,15.7c-3.6,6.4-5.7,13.8-5.7,21.7c0,12.2,5,23.3,12.9,31.2c8,8,19.1,12.9,31.2,12.9
		c12.2,0,23.3-5,31.2-12.9c8-8,12.9-19.1,12.9-31.2c0-7.8-2.1-15.3-5.7-21.7c-3.6-6.4-8.7-11.8-14.9-15.7c-3-1.9-7-1-9,2
		C63.3,21.1,64.3,25.1,67.3,27z"
      />
      <path
        class="fill"
        d="M44.2,9.5v23.3c0,3.6,2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5V9.5C57.2,6,54.3,3,50.7,3S44.2,6,44.2,9.5"
      />
    </svg>

    <svg
      v-if="name==='moon'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M87.1,19.2C80.2,11,70.6,5,59.6,2.8c-2.1-0.4-4.3,0.5-5.4,2.3C53,7,53.1,9.3,54.3,11c3.7,5.1,5.8,11.2,5.8,18
	c0,8.5-3.4,16.1-9,21.7c-5.6,5.6-13.2,9-21.7,9c-6.7,0-12.9-2.2-18-5.8c-1.7-1.3-4.1-1.3-5.9-0.2c-1.8,1.2-2.7,3.3-2.3,5.4
	c2.2,11,8.1,20.7,16.4,27.6c8.3,6.9,19,11.1,30.6,11.1c13.2,0,25.2-5.4,33.9-14c8.7-8.7,14-20.7,14-33.9
	C98.2,38.2,94,27.5,87.1,19.2z"
      />
    </svg>

    <svg
      v-if="name==='play'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M82.1,44.5l-55.9-41c-2.2-1.6-5-1.8-7.4-0.6c-2.4,1.2-3.9,3.7-3.9,6.3v82.1c0,2.7,1.5,5.1,3.9,6.3c2.4,1.2,5.3,1,7.4-0.6
		l55.9-41c1.8-1.3,2.9-3.5,2.9-5.7C85,48,84,45.9,82.1,44.5z"
      />
    </svg>

    <svg
      v-if="name==='home'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M59.2,95.4c0-8.3,0-16.6,0-25c-6.1,0-12.2,0-18.3,0c0,8.4,0,16.7,0,25.1c-0.3,0-0.6,0.1-0.8,0.1
				c-5.1,0-10.1,0-15.2,0c-5.3,0-9.3-3.4-10.3-8.6c-0.1-0.7-0.1-1.3-0.1-2c0-7.6,0-15.3,0-22.9c0-1.2,0.4-2.1,1.2-2.9
				c10.7-10.7,21.4-21.3,32-32c1.6-1.6,3.1-1.6,4.7,0c10.7,10.7,21.3,21.3,32,32c0.9,0.9,1.3,1.8,1.3,3.1c0,7.6,0,15.2,0,22.8
				c0,3.5-1.2,6.4-4,8.6c-1.5,1.2-3.3,2-5.3,2c-5.6,0.1-11.2,0-16.8,0.1C59.5,95.5,59.4,95.5,59.2,95.4z"
      />
      <path
        class="fill"
        d="M71.4,18.8c0-1.8,0-3.5,0-5.3c0-1.9,0.6-3.5,2.1-4.8c3.4-2.7,8.4-0.8,9.1,3.5c0.1,0.5,0.1,1,0.1,1.5
				c0,5.3,0,10.6,0,15.8c0,0.4,0.2,1,0.5,1.3c4.6,4.6,9.2,9.2,13.7,13.8c0.9,0.9,1.7,1.8,2.1,3c0.7,2.4-0.2,4.9-2.3,6.3
				c-2,1.4-4.8,1.3-6.7-0.3c-2-1.8-3.8-3.7-5.6-5.5C73.9,37.5,63.4,27,52.8,16.4c-1.9-1.9-3.7-1.9-5.6,0.1C35,28.6,22.8,40.8,10.7,53
				C8,55.6,4.3,55.5,2,52.7c-1.7-2-1.7-5,0.1-7c1.8-2,3.7-3.9,5.6-5.8C18.3,29.4,28.9,18.7,39.6,8.1c5.8-5.8,15-5.8,20.9,0
				c3.3,3.2,6.5,6.5,9.8,9.8c0.3,0.3,0.6,0.7,0.9,1C71.2,18.8,71.3,18.8,71.4,18.8z"
      />
    </svg>

    <svg
      v-if="name==='billing'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M84.3,16H15.7C7.3,16,0.5,22.8,0.5,31.2v39.6C0.5,79.2,7.3,86,15.7,86h68.5c8.4,0,15.2-6.8,15.2-15.2V31.2
			C99.5,22.8,92.7,16,84.3,16z M74.3,26.6c4.2,0,7.6,3.4,7.6,7.6c0,4.2-3.4,7.6-7.6,7.6c-4.2,0-7.6-3.4-7.6-7.6
			C66.7,30,70.1,26.6,74.3,26.6z M84.5,69.7H15.5c-3.3,0-5.9-2.6-5.9-5.9c0-3.3,2.6-5.9,5.9-5.9h68.9c3.3,0,5.9,2.6,5.9,5.9
			C90.4,67,87.7,69.7,84.5,69.7z"
      />
    </svg>

    <svg
      v-if="name==='user'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M58.3,45.2c8.5-3.3,14.5-11.6,14.5-21.2c0-6.6-2.8-12.6-7.4-16.8c-4.1-3.7-9.5-6-15.4-6c-5.9,0-11.3,2.3-15.4,6
	c-4.5,4.2-7.4,10.1-7.4,16.8c0,9.7,6,17.9,14.5,21.2C20.6,49,2.9,63.3,2.9,83.7c0,8.4,7.8,14.7,16.3,14.7h61.5
	c8.9,0,16.4-5.6,16.4-16C97.1,62.5,79.1,48.9,58.3,45.2z"
      />
    </svg>

    <svg
      v-if="name==='camera-indicator'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="icon-accent"
        d="M50,13.8c-3.3,0-5.9,2.6-5.9,5.9s2.6,5.9,5.9,5.9c3.3,0,5.9-2.6,5.9-5.9S53.3,13.8,50,13.8z"
      />
      <path
        class="icon-accent"
        d="M50,75.7c10.6,0,19.2-8.6,19.2-19.2c0-10.6-8.6-19.2-19.2-19.2s-19.2,8.6-19.2,19.2C30.8,67.1,39.4,75.7,50,75.7z"
      />
      <path
        class="fill"
        d="M79.5,25.6L57.9,4c-4.4-4.4-11.4-4.4-15.8,0L20.2,25.9c-8,7.8-13,18.6-13,30.6c0,23.6,19.1,42.7,42.7,42.7
		c23.6,0,42.7-19.1,42.7-42.7C92.7,44.4,87.6,33.4,79.5,25.6z M50,75.7c-10.6,0-19.2-8.6-19.2-19.2c0-10.6,8.6-19.2,19.2-19.2
		s19.2,8.6,19.2,19.2C69.2,67.1,60.6,75.7,50,75.7z M50,13.8c3.3,0,5.9,2.6,5.9,5.9s-2.6,5.9-5.9,5.9c-3.3,0-5.9-2.6-5.9-5.9
		S46.7,13.8,50,13.8z"
      />
    </svg>


    <svg
      v-if="name==='wifi'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <g>
        <path
          class="stroke"
          d="M97.4,42.1C71.3,16,29.1,16,3,42.1"
        />
        <path
          class="stroke"
          d="M85.2,54.4c-19.3-19.3-50.6-19.3-69.9,0"
        />
        <path
          class="stroke"
          d="M72.6,66.9c-12.4-12.4-32.4-12.4-44.8,0"
        />
      </g>
      <circle
        class="fill"
        cx="50.2"
        cy="80.9"
        r="3.5"
      />
    </svg>

    <svg
      v-if="name==='graph'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M60.3,86.2c0,5.4-4.4,9.8-9.8,9.8l0,0c-5.4,0-9.8-4.4-9.8-9.8V13c0-5.4,4.4-9.8,9.8-9.8l0,0c5.4,0,9.8,4.4,9.8,9.8V86.2z"
      />
      <path
        class="fill"
        d="M27.3,86.2c0,5.4-4.4,9.8-9.8,9.8l0,0c-5.4,0-9.8-4.4-9.8-9.8V55.9c0-5.4,4.4-9.8,9.8-9.8l0,0c5.4,0,9.8,4.4,9.8,9.8V86.2z"
      />
      <path
        class="fill"
        d="M93.2,86.2c0,5.4-4.4,9.8-9.8,9.8l0,0c-5.4,0-9.8-4.4-9.8-9.8V34.6c0-5.4,4.4-9.8,9.8-9.8l0,0c5.4,0,9.8,4.4,9.8,9.8V86.2z"
      />
    </svg>

    <svg
      v-if="name==='cart'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M30.1,30.4c-0.5-1.7-0.9-3.3-1.2-4.4c-1.1-3.7-2.7-6.8-4.9-9.1c-1.1-1.1-2.4-2.1-3.9-2.7c-1.4-0.6-3-1-4.6-1
	c-2.1,0-4.2,0.6-6,1.6c-1.9,1-3.6,2.5-5.2,4.3c-1.5,1.7-1.3,4.2,0.4,5.7c1.7,1.5,4.2,1.3,5.7-0.4c1.1-1.2,2.1-2,3-2.5
	c0.9-0.5,1.6-0.6,2.2-0.6c0.5,0,0.9,0.1,1.3,0.3c0.7,0.3,1.4,0.9,2.2,2c0.8,1.1,1.6,2.6,2.1,4.7c1.5,5.3,4.8,17.2,7.7,27.6
	c1.4,5.2,2.8,10.1,3.8,13.7c0.1,0.2,0.1,0.4,0.2,0.6c-1.1-0.5-2.4-0.8-3.7-0.8c-5,0-9.1,4.1-9.1,9.1c0,5,4.1,9.1,9.1,9.1
	c5,0,9.1-4.1,9.1-9.1c0,0,0,0,0,0h34.9c0,0,0,0,0,0c0,5,4.1,9.1,9.1,9.1c5,0,9.1-4.1,9.1-9.1c0-5-4.1-9.1-9.1-9.1
	c-1.5,0-2.8,0.4-4,1H41.1c-0.3-1-0.6-2.2-1-3.6c10.2-2.1,40.3-8.3,45-9.3c5.7-1.2,10.9-8,11.5-13.9c0.6-5.9,1.4-13,1.4-13H30.1z"
      />
    </svg>

    <svg
      v-if="name==='favorites'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
    >
      <path
        class="fill"
        d="M1058.778,1115.098c0-342.871,0.002-685.742-0.004-1028.613c0-5.105,0.176-10.221-0.331-15.31
			c-1.777-17.835-10.261-27.85-27.199-32.207c-287.502,0-575.004,0-862.506,0c-13.909,2.101-21.472,11.195-25.863,23.651
			c-1.977,5.608-1.658,11.516-1.658,17.346c-0.017,346.518-0.018,693.037-0.005,1039.555c0,3.647-0.065,7.303,0.425,10.927
			c2.577,19.043,14.751,29.851,33.865,30.333c10.803,0.272,20.75-2.153,30.491-7.035
			c125.554-62.929,251.261-125.551,376.689-188.729c12.387-6.239,22.283-6.214,34.659,0.021
			c124.121,62.529,248.505,124.534,372.817,186.685c3.589,1.794,7.035,4.003,10.82,5.255c32.31,10.685,58.28,0.951,57.799-37.502
			C1058.758,1118.016,1058.778,1116.557,1058.778,1115.098z M832.468,460.712c-28.525,27.688-56.836,55.598-85.48,83.161
			c-4.615,4.441-6.343,8.149-5.072,14.972c6.986,37.514,13.158,75.179,19.629,112.789c2.538,14.752,1.758,28.633-11.59,38.41
			c-13.495,9.885-26.96,7.378-40.853-0.106c-33.561-18.077-67.492-35.467-101.056-53.537c-5.87-3.16-10.282-2.989-16.016,0.071
			c-34.924,18.64-69.945,37.104-105.122,55.261c-25.288,13.052-50.779-2.744-49.288-33.77c4.436-25.947,9.447-55.328,14.491-84.704
			c0.923-5.373,1.392-10.918,3.084-16.049c6.407-19.431,0.742-33.126-15.174-46.655c-24.951-21.208-47.187-45.586-70.936-68.242
			c-10.457-9.976-15.302-21.565-11.231-35.371c4.067-13.792,14.144-21.22,28.652-23.258c38.172-5.362,76.241-11.47,114.451-16.521
			c8.963-1.185,13.923-4.591,17.807-12.716c16.444-34.401,33.695-68.416,50.352-102.717c6.48-13.344,16.117-21.344,31.394-21.137
			c14.786,0.2,24.045,8.191,30.336,21.086c17.034,34.912,34.685,69.527,51.427,104.577c3.514,7.356,8.15,9.705,15.582,10.738
			c38.908,5.405,77.736,11.379,116.627,16.907c14.143,2.01,23.646,9.699,27.606,22.993
			C845.909,439.723,842.265,451.203,832.468,460.712z"
      />
    </svg>




    <svg
      v-if="name==='facebook'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M99.5,50C99.5,22.6,77.3,0.5,50,0.5S0.5,22.6,0.5,50c0,23.7,16.6,43.5,38.8,48.3c0-12.1,0-24.2,0-36.4c-0.5,0-0.9,0-1.3,0
	c-2.2,0-4.5-0.1-6.7-0.1c0-4.7,0-9.4,0-14.1c2.6,0,5.2,0,7.9,0c0-2.3-0.1-4.5,0-6.8c0.1-2.3,0.1-4.7,0.6-6.9
	c1.3-6.2,5.1-10.3,11.4-11.7c0.8-0.2,1.7-0.3,2.5-0.5c4.9,0,9.8,0,14.7,0c0,4.6,0,9.2,0,13.8c-0.5,0-0.8,0-1.1,0c-2.5,0-5,0-7.5,0
	c-2.4,0-3.7,1.3-3.8,3.7c0,1,0,2,0,2.9c0,1.8,0,3.6,0,5.5c4.3,0,8.4,0,12.6,0c0,0.4,0,0.7,0,0.9c-0.4,4.2-0.9,8.3-1.3,12.5
	c-0.1,0.7-0.4,0.8-0.9,0.8c-3.1,0-6.2,0-9.3,0c-0.4,0-0.7,0-1.2,0c0,12.5,0,24.8,0,37.2C80.5,96.2,99.5,75.3,99.5,50z"
      />
    </svg>

    <svg
      v-if="name==='facebook-solid'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M99.5,50C99.5,22.6,77.3,0.5,50,0.5S0.5,22.6,0.5,50c0,23.7,16.6,43.5,38.8,48.3c0-12.1,0-24.2,0-36.4c-0.5,0-0.9,0-1.3,0
	c-2.2,0-4.5-0.1-6.7-0.1c0-4.7,0-9.4,0-14.1c2.6,0,5.2,0,7.9,0c0-2.3-0.1-4.5,0-6.8c0.1-2.3,0.1-4.7,0.6-6.9
	c1.3-6.2,5.1-10.3,11.4-11.7c0.8-0.2,1.7-0.3,2.5-0.5c4.9,0,9.8,0,14.7,0c0,4.6,0,9.2,0,13.8c-0.5,0-0.8,0-1.1,0c-2.5,0-5,0-7.5,0
	c-2.4,0-3.7,1.3-3.8,3.7c0,1,0,2,0,2.9c0,1.8,0,3.6,0,5.5c4.3,0,8.4,0,12.6,0c0,0.4,0,0.7,0,0.9c-0.4,4.2-0.9,8.3-1.3,12.5
	c-0.1,0.7-0.4,0.8-0.9,0.8c-3.1,0-6.2,0-9.3,0c-0.4,0-0.7,0-1.2,0c0,12.5,0,24.8,0,37.2C80.5,96.2,99.5,75.3,99.5,50z"
      />
    </svg>

    <svg
      v-if="name==='hamburger'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <line
        class="stroke"
        x1="0"
        y1="50.2"
        x2="100"
        y2="50.2"
      />
      <line
        class="stroke"
        x1="0"
        y1="24.2"
        x2="100"
        y2="24.2"
      />
      <line
        class="stroke"
        x1="0"
        y1="76.2"
        x2="100"
        y2="76.2"
      />
    </svg>

    <svg
      v-if="name==='box'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <polygon
        class="stroke"
        points="50,12.9 11.8,29.5 50,46 88.2,29.5 "
      />
      <line
        class="stroke"
        x1="50"
        y1="46"
        x2="50"
        y2="87"
      />
      <polyline
        class="stroke"
        points="11.8,29.5 11.8,70.5 50,88 88.2,70.5 88.2,29.5 "
      />
      <line
        class="stroke"
        x1="30.9"
        y1="37.9"
        x2="69.1"
        y2="21.2"
      />
    </svg>

    <svg
      v-if="name==='box-return'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <polygon
        class="stroke"
        points="59,12.9 20.8,29.5 59,46 97.2,29.5 "
      />
      <polyline
        class="stroke"
        points="14.2,59.1 5.2,50.1 4.6,49.5 14.2,40.1 "
      />
      <polyline
        class="stroke"
        points="20.8,54.9 20.8,70.5 59,87 97.2,70.5 97.2,29.5   "
      />
      <path
        class="stroke"
        d="M20.8,29.5"
      />
      <line
        class="stroke"
        x1="59"
        y1="46"
        x2="59"
        y2="87"
      />
      <line
        class="stroke"
        x1="4.6"
        y1="49.5"
        x2="36.4"
        y2="49.5"
      />
      <path
        class="stroke"
        d="M20.8,70.5"
      />
      <line
        class="stroke"
        x1="20.8"
        y1="29.5"
        x2="20.8"
        y2="44.4"
      />
      <line
        class="stroke"
        x1="39.9"
        y1="36.9"
        x2="75.8"
        y2="20.2"
      />
    </svg>

    <svg
      v-if="name==='gear'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M45.9,2c3.5,0,7,0,10.5,0c0.2,0.1,0.4,0.3,0.6,0.3c2.6,1.1,4,3.2,4.1,6c0,1.2,0,2.3,0,3.5
				c0,2.6,1.2,4.3,3.5,5.4c2.3,1,4.6,0.5,6.4-1.3c0.9-0.8,1.7-1.7,2.6-2.6c2.4-2.3,6.2-2.3,8.6,0c1.9,1.8,3.8,3.7,5.7,5.6
				c2.4,2.5,2.4,6.1,0,8.6c-0.9,0.9-1.9,1.8-2.8,2.7c-1.7,1.7-2,3.8-1.1,6c0.9,2.2,2.6,3.5,5.1,3.6c1.3,0,2.6,0,3.9,0
				c3,0.1,5,1.7,5.7,4.6c0,0.2,0.2,0.4,0.2,0.6c0,3.2,0,6.3,0,9.5c-0.1,0.2-0.2,0.4-0.2,0.5c-0.7,2.9-2.8,4.6-5.8,4.6
				c-1.2,0-2.5,0-3.7,0c-2.5,0.1-4.2,1.3-5.1,3.6c-0.9,2.2-0.5,4.2,1.1,6c0.8,0.8,1.7,1.6,2.5,2.5c2.8,2.8,2.8,6.4,0.1,9.2
				c-1.7,1.7-3.4,3.4-5.1,5.1c-2.7,2.7-6.5,2.7-9.2,0c-0.7-0.7-1.5-1.5-2.2-2.2c-1.8-1.8-4-2.4-6.4-1.3c-2.4,1-3.6,2.9-3.6,5.5
				c0,1.5,0,3-0.1,4.5c-0.4,3-2.9,5.2-5.9,5.2c-2.6,0-5.2,0-7.7,0c-3.4,0-6-2.7-6.1-6.1c0-1.3,0-2.6,0-3.9c-0.1-2.5-1.3-4.3-3.6-5.2
				c-2.2-0.9-4.3-0.5-6.1,1.1c-0.9,0.8-1.7,1.7-2.6,2.5c-2.6,2.5-6.4,2.5-9,0c-1.8-1.7-3.6-3.5-5.3-5.3c-2.7-2.7-2.7-6.4,0.1-9.1
				c0.8-0.8,1.7-1.6,2.5-2.5c1.6-1.8,2.1-3.9,1.1-6.1c-1-2.2-2.7-3.5-5.1-3.5c-1.2,0-2.4,0-3.6,0c-4.3,0-6.8-3.4-6.5-6.6
				c0.2-2.3,0-4.7,0-7c0-3.1,2.1-5.6,5.1-6c1.6-0.2,3.2-0.2,4.8-0.2c2.6,0,4.4-1.3,5.3-3.6c1-2.4,0.5-4.5-1.3-6.3
				c-0.8-0.8-1.6-1.6-2.4-2.4c-2.6-2.7-2.6-6.3,0.1-9c1.8-1.8,3.6-3.6,5.5-5.3c2.5-2.4,6.3-2.3,8.8,0.1c0.9,0.9,1.7,1.8,2.7,2.6
				c1.8,1.6,3.9,2,6.1,1.1c2.3-1,3.5-2.7,3.5-5.3c0-1.2,0-2.5,0-3.7c0.1-2.1,0.9-3.8,2.6-5C44.5,2.7,45.2,2.4,45.9,2z M51.2,30
				c-10.9-0.1-20,8.8-20,19.6C31.1,60.8,39.9,69.9,51,69.9C62,70,71.1,61,71.1,50C71.1,39,62.2,30,51.2,30z"
      />
    </svg>

    <svg
      v-if="name==='plus'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <rect
        x="43.5"
        class="fill"
        width="13.1"
        height="100"
      />
      <rect
        x="0"
        y="43.5"
        class="fill"
        width="100"
        height="13.1"
      />
    </svg>

    <svg
      v-if="name==='logo-ring'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M84,16.4C75.3,7.7,63.2,2.2,49.9,2.2C36.7,2.2,24.6,7.7,16,16.4C7.3,25.1,1.8,37.2,1.8,50.3c0,13.3,5.4,25.3,14.1,33.9
	c8.7,8.7,20.8,14.1,33.9,14.1c13.3,0,25.3-5.4,33.9-14.1C92.6,75.5,98,63.5,98,50.3h0.1C98.1,37.1,92.7,25,84,16.4z M72.7,73.1
	c-5.9,5.8-13.9,9.5-22.8,9.5c-9,0-17-3.6-22.8-9.5c-5.8-5.9-9.5-13.9-9.5-22.8c0-9,3.6-17,9.5-22.8c5.9-5.8,13.9-9.5,22.8-9.5
	c9,0,17,3.6,22.8,9.5c5.8,5.9,9.5,13.9,9.5,22.8C82.2,59.3,78.6,67.3,72.7,73.1z"
      />
    </svg>

    <svg
      v-if="name==='calendar'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M93.9,12.4c-2.5-2.5-6.1-4.1-10-4.1H16c-3.9,0-7.4,1.6-10,4.1c-2.5,2.5-4.1,6.1-4.1,10l0,57c0,3.9,1.6,7.4,4.1,10
		c2.5,2.5,6.1,4.1,10,4.1h68c3.9,0,7.4-1.6,10-4.1c2.5-2.5,4.1-6.1,4.1-10h0v-46v-3v-8C98.1,18.5,96.5,14.9,93.9,12.4z M87.6,82.9
		c-0.9,0.9-2.2,1.5-3.6,1.5H16c-1.4,0-2.7-0.6-3.6-1.5c-0.9-0.9-1.5-2.2-1.5-3.6l0-46c0-1.4,0.6-2.7,1.5-3.6
		c0.9-0.9,2.2-1.5,3.6-1.5h68c1.4,0,2.7,0.6,3.6,1.5c0.9,0.9,1.5,2.2,1.5,3.6l0,46h0C89.1,80.7,88.5,82,87.6,82.9z"
      />
      <circle
        class="fill"
        cx="42.2"
        cy="41.3"
        r="4.5"
      />
      <circle
        class="fill"
        cx="57.6"
        cy="41.3"
        r="4.5"
      />
      <circle
        class="fill"
        cx="73"
        cy="41.3"
        r="4.5"
      />
      <circle
        class="fill"
        cx="26.8"
        cy="56.8"
        r="4.5"
      />
      <circle
        class="fill"
        cx="42.2"
        cy="56.8"
        r="4.5"
      />
      <circle
        class="fill"
        cx="57.6"
        cy="56.8"
        r="4.5"
      />
      <circle
        class="fill"
        cx="73"
        cy="56.8"
        r="4.5"
      />
      <circle
        class="fill"
        cx="26.8"
        cy="72.3"
        r="4.5"
      />
      <circle
        class="fill"
        cx="42.2"
        cy="72.3"
        r="4.5"
      />
    </svg>

    <svg
      v-if="name==='photostream'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M10.5,64.1c5.9,0.1,11.8,0.1,17.8,0.2c2.8,0,5.1-2.3,5.1-5.1c0-5.9,0-11.8,0-17.7c0-2.8-2.3-5.1-5.1-5.1
		c-5.9,0-11.8,0.1-17.8,0.2c-2.8,0-5.2,2.3-5.2,5.1c-0.1,5.8-0.1,11.6,0,17.4C5.3,61.8,7.6,64,10.5,64.1z"
      />
      <path
        class="fill"
        d="M59.1,36.3c-5.9,0-11.8,0-17.8,0c-2.8,0-5.1,2.3-5.1,5.1c0,5.9,0,11.8,0,17.8c0,2.8,2.3,5.1,5.1,5.1c5.9,0,11.8,0,17.8,0
		c2.8,0,5.1-2.3,5.1-5.1c0-5.9,0-11.8,0-17.8C64.3,38.6,62,36.3,59.1,36.3z"
      />
      <path
        class="fill"
        d="M95.4,41.6c0-2.8-2.4-5-5.2-5.1c-5.9-0.1-11.8-0.1-17.8-0.2c-2.8,0-5.1,2.3-5.1,5.1c0,5.9,0,11.8,0,17.7
		c0,2.8,2.3,5.1,5.1,5.1c5.9,0,11.8-0.1,17.8-0.2c2.8,0,5.2-2.3,5.2-5.1C95.4,53.2,95.4,47.4,95.4,41.6z"
      />
      <path
        class="fill"
        d="M28.2,67.5c-5.9-0.1-11.8-0.1-17.7-0.2c-2.8,0-5.1,2.2-5,4.9C5.6,78,5.8,83.8,6.1,89.6c0.1,2.8,2.5,5.1,5.2,5.2
		c5.8,0.3,11.6,0.5,17.4,0.6c2.8,0.1,5-2.2,4.9-5c-0.1-5.9-0.2-11.8-0.2-17.7C33.4,69.8,31.1,67.5,28.2,67.5z"
      />
      <path
        class="fill"
        d="M59.1,67.6c-5.9,0-11.8,0-17.7,0c-2.8,0-5.1,2.3-5.1,5.1c0,5.9,0.1,11.8,0.2,17.8c0,2.8,2.3,5.2,5.1,5.2
		c5.8,0.1,11.6,0.1,17.4,0c2.8,0,5-2.4,5.1-5.2c0.1-5.9,0.1-11.8,0.2-17.8C64.2,69.9,62,67.6,59.1,67.6z"
      />
      <path
        class="fill"
        d="M90.2,67.3c-5.9,0.1-11.8,0.2-17.7,0.2c-2.8,0-5.1,2.3-5.2,5.2c-0.1,5.9-0.1,11.8-0.2,17.7c0,2.8,2.2,5.1,4.9,5
		c5.8-0.1,11.6-0.3,17.4-0.6c2.8-0.1,5.1-2.5,5.2-5.2c0.3-5.8,0.5-11.6,0.6-17.4C95.2,69.4,93,67.2,90.2,67.3z"
      />
      <path
        class="fill"
        d="M28.7,5.2c-5.8,0.1-11.6,0.3-17.4,0.6C8.6,5.9,6.2,8.3,6.1,11c-0.3,5.8-0.5,11.6-0.6,17.4c-0.1,2.8,2.2,5,5,4.9
		c5.9-0.1,11.8-0.2,17.7-0.2c2.8,0,5.1-2.3,5.2-5.2c0.1-5.9,0.1-11.8,0.2-17.7C33.7,7.4,31.5,5.1,28.7,5.2z"
      />
      <path
        class="fill"
        d="M41.4,33c5.9,0,11.8,0,17.7,0c2.8,0,5.1-2.3,5.1-5.1c0-5.9-0.1-11.8-0.2-17.8C64,7.3,61.7,5,59,5c-5.8-0.1-11.6-0.1-17.4,0
		c-2.8,0-5,2.4-5.1,5.2c-0.1,5.9-0.1,11.8-0.2,17.8C36.3,30.8,38.6,33,41.4,33z"
      />
      <path
        class="fill"
        d="M72.4,33.1c5.9,0.1,11.8,0.1,17.7,0.2c2.8,0,5.1-2.2,5-4.9C95,22.6,94.8,16.8,94.6,11c-0.1-2.8-2.5-5.1-5.2-5.2
		c-5.8-0.3-11.6-0.5-17.4-0.6c-2.8-0.1-5,2.2-4.9,5c0.1,5.9,0.2,11.8,0.2,17.7C67.3,30.8,69.6,33.1,72.4,33.1z"
      />
    </svg>

    <svg
      v-if="name==='x'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M59.4,50.5l37.7-37.7c2.5-2.5,2.5-6.7,0-9.2c-2.5-2.5-6.7-2.5-9.2,0L50.2,41.3L12.6,3.7c-2.5-2.5-6.7-2.5-9.2,0
	s-2.5,6.7,0,9.2l37.7,37.7L3.4,88.2c-2.5,2.5-2.5,6.7,0,9.2c2.5,2.5,6.7,2.5,9.2,0l37.7-37.7l37.7,37.7c2.5,2.5,6.7,2.5,9.2,0
	c2.5-2.5,2.5-6.7,0-9.2L59.4,50.5z"
      />
    </svg>

    <svg
      v-if="name==='squiggle'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78.21 8"
    >
      <path
        class="fill"
        d="M74.79 3.46C73.79 1.92 72.55 0 70.06 0s-3.73 1.92-4.73 3.46S63.61 6 62.27 6s-2-1-3.05-2.54S57 0 54.49 0s-3.73 1.92-4.73 3.46S48 6 46.7 6s-2-1-3.05-2.54S41.41 0 38.92 0s-3.73 1.92-4.73 3.46S32.47 6 31.14 6s-2-1-3.05-2.54S25.84 0 23.35 0s-3.73 1.92-4.73 3.46S16.9 6 15.57 6s-2-1-3.05-2.54S10.27 0 7.78 0 4.05 1.92 3.05 3.46 1.33 6 0 6v2c2.49 0 3.73-1.92 4.73-3.46S6.45 2 7.78 2s2 1 3.05 2.54S13.08 8 15.57 8s3.73-1.92 4.73-3.46S22 2 23.35 2s2 1 3.05 2.54S28.65 8 31.14 8s3.73-1.92 4.73-3.46S37.58 2 38.92 2s2 1 3.05 2.54S44.21 8 46.7 8s3.73-1.92 4.73-3.46S53.15 2 54.49 2s2 1 3.05 2.54S59.78 8 62.27 8 66 6.08 67 4.54 68.72 2 70.06 2s2 1 3.05 2.54S75.35 8 77.84 8h.37V6a2.41 2.41 0 0 1-.37 0c-1.33 0-2.03-1-3.05-2.54z"
      />
    </svg>

    <svg
      v-if="name==='chevron-arrow'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M96.9,45.9L69.6,19.5c-1.9-1.9-5-1.8-6.9,0.1c-1.9,1.9-1.8,5,0.1,6.9l18.9,18.3H7.6c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7
        H82L62.7,74.2c-1.9,1.9-1.8,5,0.1,6.9c1.9,1.9,5,1.8,6.9-0.1L97,52.8c0.9-0.9,1.4-2.2,1.4-3.5C98.4,48,97.8,46.8,96.9,45.9z"
      />
    </svg>

    <svg
      v-if="name==='magnifying-glass'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M97.7,87.8L79.6,69.6c4.5-6.8,7.1-14.9,7.1-23.6c0-5.9-1.2-11.6-3.4-16.7c-3.3-7.7-8.7-14.3-15.6-18.9
	c-6.9-4.6-15.1-7.3-24-7.3c-5.9,0-11.6,1.2-16.7,3.4C19.2,9.7,12.7,15.2,8,22c-4.6,6.8-7.3,15.1-7.3,24c0,5.9,1.2,11.6,3.4,16.7
	c3.3,7.7,8.7,14.3,15.6,18.9c6.8,4.6,15.1,7.3,24,7.3c5.9,0,11.6-1.2,16.7-3.4c4.5-1.9,8.7-4.6,12.3-7.9l17.6,17.6
	c2.1,2.1,5.4,2.1,7.5,0C99.8,93.3,99.8,89.9,97.7,87.8z M61.7,72.9c-5.2,3.5-11.4,5.5-18.1,5.5c-4.5,0-8.7-0.9-12.6-2.5
	c-5.8-2.4-10.7-6.6-14.2-11.7C13.4,59,11.3,52.8,11.3,46c0-4.5,0.9-8.7,2.5-12.6c2.4-5.8,6.6-10.7,11.7-14.2
	c5.2-3.5,11.4-5.5,18.1-5.5c4.5,0,8.7,0.9,12.6,2.5C62,18.7,67,22.8,70.5,28C73.9,33.1,76,39.3,76,46c0,4.5-0.9,8.7-2.5,12.6
	C71,64.4,66.9,69.4,61.7,72.9z"
      />
    </svg>

    <svg
      v-if="name==='selfie'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <circle
        class="fill"
        cx="49.5"
        cy="37.7"
        r="20.3"
      />
      <path
        class="fill"
        d="M85,15C76.1,6,63.7,0.5,50,0.5C36.3,0.5,23.9,6,15,15C6,23.9,0.5,36.3,0.5,50C0.5,63.7,6,76.1,15,85
		c8.9,9,21.3,14.5,35,14.5c13.7,0,26.1-5.5,35-14.5c8.9-8.9,14.5-21.3,14.5-35C99.5,36.3,94,23.9,85,15z M49.5,63.8
		c-11.5,0-22.1,3.7-30.7,10c-5-6.6-8-14.8-8-23.8c0-10.8,4.4-20.6,11.5-27.7c7.1-7.1,16.9-11.5,27.7-11.5c10.8,0,20.6,4.4,27.7,11.5
		c7.1,7.1,11.5,16.9,11.5,27.7c0,9.2-3.1,17.5-8.4,24.2C72.1,67.7,61.3,63.8,49.5,63.8z"
      />
    </svg>

    <svg
      v-if="name==='emoji'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M85.4,14.6C76.3,5.6,63.8,0,50,0C36.2,0,23.7,5.6,14.6,14.6C5.6,23.7,0,36.2,0,50c0,13.8,5.6,26.3,14.6,35.4
        c9,9,21.6,14.6,35.4,14.6c13.8,0,26.3-5.6,35.4-14.6c9-9,14.6-21.6,14.6-35.4C100,36.2,94.4,23.7,85.4,14.6z M80.6,80.6
        C72.8,88.5,62,93.3,50,93.3c-12,0-22.8-4.8-30.6-12.7C11.5,72.8,6.7,62,6.7,50c0-12,4.8-22.8,12.7-30.6C27.2,11.5,38,6.7,50,6.7
        c12,0,22.8,4.8,30.6,12.7C88.5,27.2,93.3,38,93.3,50C93.3,62,88.5,72.8,80.6,80.6z"
      />
      <circle
        class="fill"
        cx="32.4"
        cy="37.9"
        r="5.9"
      />
      <circle
        class="fill"
        cx="67.6"
        cy="37.9"
        r="5.9"
      />
      <path
        class="fill"
        d="M68.2,61.3H31.8c-3.5,0-5.1,2.6-3.5,5.7c0,0,0.3,0.7,3,3.3c10.3,10.4,27.1,10.4,37.5,0c2.7-2.7,3-3.3,3-3.3
        C73.4,63.9,71.8,61.3,68.2,61.3z"
      />
    </svg>

    <svg
      v-if="name==='funnel'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M94.5,16.8c0,3.7,0,7.3,0,11c-0.3,1.1-0.9,2.1-1.6,3c-2.4,3.2-5.2,6-8,8.7c-7.5,7.4-15.4,14.3-23.3,21.1
	c-0.4,0.3-0.5,0.6-0.5,1.1c0,10.7,0,21.5,0,32.2c0,1.7-0.7,2.9-2,3.8c-1.4,0.9-3,1.4-4.6,1.7c-1,0.2-2,0.3-3,0.4c-1,0-2,0-3,0
	c-0.2,0-0.4-0.1-0.6-0.1c-2.3-0.2-4.5-0.6-6.5-1.8c-1.7-1-2.5-2.3-2.5-4.3c0.1-10.7,0-21.3,0-32c0-0.5-0.2-0.8-0.6-1.2
	C32.5,55.5,26.8,50.4,21,45.3c-3.9-3.5-7.6-7.1-11.1-11c-1.3-1.5-2.5-3.1-3.7-4.7c-0.4-0.6-0.7-1.3-0.7-2c0-3.5,0-7.1,0-10.6
	c0-1.3,0.3-2.5,1-3.6c0.8-1.4,1.8-2.5,3-3.4c2.4-2,5.2-3.4,8.1-4.6c4-1.6,8.2-2.7,12.4-3.5c4.4-0.8,8.9-1.3,13.4-1.5
	c1-0.1,2.1-0.1,3.1-0.2c2.3,0,4.6,0,6.9,0c0.2,0,0.3,0.1,0.5,0.1c2.5,0.2,4.9,0.2,7.4,0.5c5.6,0.5,11,1.4,16.4,3
	c4,1.2,7.8,2.7,11.3,5.1C91.8,10.7,94.3,13,94.5,16.8z M50,28.4c1.1,0,2.2,0,3.3,0c7.3-0.3,14.5-1.5,21.4-4c2.7-1,5.2-2.1,7.6-3.8
	c0.6-0.4,1.1-0.9,1.6-1.5c0.5-0.6,0.5-1.1,0-1.7c-0.3-0.4-0.7-0.8-1.1-1.1C80.9,14.9,79,14,77,13.2c-7.1-3-14.5-4.4-22.2-4.9
	C50.5,8,46.1,8.1,41.7,8.6c-5.5,0.6-10.8,1.7-16,3.5c-2.7,1-5.3,2.1-7.7,3.7c-0.6,0.5-1.2,1-1.8,1.6c-0.5,0.6-0.5,1.1,0,1.8
	c0.3,0.4,0.7,0.8,1.2,1.2c2.5,1.9,5.3,3.1,8.2,4.1C33.5,27.3,41.6,28.4,50,28.4z"
      />
    </svg>

    <svg
      v-if="name==='link'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M97.5,6.4c-0.1-1-0.5-2-1.3-2.7c-0.7-0.6-1.4-0.9-2.3-1c-1-0.1-2-0.2-3-0.3c-0.2,0-0.4,0-0.6-0.1c-14.8,0-29.6,0-44.4,0
				c-0.1,0-0.3,0.1-0.4,0.1c-0.9,0.1-1.7,0.2-2.5,0.5c-0.9,0.4-1.1,1-0.8,1.8c0.1,0.2,0.1,0.3,0.2,0.5c0.6,0.8,1.2,1.6,1.8,2.2
				c4,4,8,8,12,12c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-6.7,6.7-13.3,13.3-20,20c-0.3,0.3-0.6,0.6-0.9,0.9
				c-2,2.5-2.8,5.4-2.3,8.5c0.3,2.2,1.3,4.1,2.9,5.7c3.1,3.1,6.2,6.2,9.4,9.2c1.9,1.8,4.2,2.8,6.8,2.9c3.1,0.2,5.9-0.9,8.1-3.1
				c1-1,2-2,3-3c5-5,10-9.9,15-14.9c0.8-0.8,1.6-1.6,2.4-2.5c0.1,0.1,0.2,0.2,0.3,0.3c3.7,3.7,7.5,7.5,11.2,11.2c0.5,0.5,1,1,1.5,1.4
				c0.6,0.5,1.3,1,1.9,1.4c0.8,0.4,1.4,0.1,1.7-0.7c0.2-0.6,0.3-1.3,0.4-2c0.1-0.5,0.1-0.9,0.1-1.4c0-14.5,0-29.1,0-43.6
				c0-0.2,0-0.5-0.1-0.7C97.8,8.7,97.7,7.5,97.5,6.4z"
      />
      <path
        class="fill"
        d="M79.4,56.7l-0.2,0.2c-3.6,3.6-7.1,7.1-10.7,10.7c-0.1,0.1-0.2,0.3-0.2,0.5c0,4,0,7.9,0,11.9c0,0.5,0,0.9-0.1,1.4
				c-0.5,3.3-3.4,5.8-6.8,5.8c-13.8,0-27.6,0-41.5,0c-0.5,0-0.9,0-1.4-0.1c-3.3-0.7-5.6-3.5-5.6-6.8c0-13.8,0-27.6,0-41.4
				c0-0.6,0.1-1.1,0.2-1.7c0.7-3,3.5-5.3,6.6-5.3c4.2,0,8.3,0,12.5,0c0.2,0,0.4-0.1,0.5-0.2c3.5-3.5,7.1-7.1,10.6-10.6
				c0.1-0.1,0.1-0.1,0.2-0.3c-0.2,0-0.3,0-0.4,0c-7.6,0-15.2,0-22.8,0c-1.2,0-2.3,0.1-3.5,0.2c-2.2,0.3-4.2,1.1-6.1,2.2
				c-2.9,1.7-5.1,4-6.7,6.9c-1,1.9-1.7,3.9-2,6c-0.1,0.5-0.1,0.9-0.2,1.4c0,14.6,0,29.2,0,43.9c0.1,0.5,0.1,0.9,0.2,1.4
				c0.3,2,0.9,3.9,1.9,5.8c0.9,1.8,2.1,3.3,3.6,4.7c2.3,2.2,5.1,3.7,8.2,4.4c1,0.2,2,0.3,3,0.4l0.2,0.1c14.5,0,28.9,0,43.4,0l0.3-0.1
				c0.9-0.1,1.9-0.2,2.8-0.4c4-0.9,7.2-2.9,9.8-6.1c2.7-3.2,4.1-7,4.1-11.2c0.1-7.8,0-15.7,0-23.5C79.5,56.9,79.5,56.9,79.4,56.7z"
      />

    </svg>

    <svg
      v-if="name==='google-solid'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M95.2,42.1H51.9v17.8h24.8c-1.1,5.7-4.3,10.6-9.2,13.9c-4.1,2.8-9.4,4.4-15.6,4.4c-12,0-22.1-8.1-25.7-19
	c-0.9-2.8-1.4-5.7-1.4-8.7c0-3,0.5-6,1.4-8.7c3.6-10.9,13.8-19,25.7-19c6.8,0,12.8,2.3,17.6,6.9l13.2-13.2c-8-7.4-18.4-12-30.8-12
	c-18,0-33.5,10.3-41.1,25.3C7.7,36,5.9,43.1,5.9,50.5c0,7.4,1.8,14.4,4.9,20.7c7.6,15,23.1,25.3,41.1,25.3
	c12.4,0,22.8-4.1,30.4-11.1c8.7-8,13.7-19.8,13.7-33.8C96.1,48.3,95.8,45.1,95.2,42.1z"
      />
    </svg>

    <svg
      v-if="name==='google'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <g>
        <path
          class="st1"
          d="M99,51.1c0-4.1-0.3-7.1-1.1-10.2H51v18.6h27.6C78,64.1,75,71,68.3,75.7l-0.1,0.6l14.8,11.5l1,0.1
          C93.6,79.2,99,66.3,99,51.1"
        />
        <path
          class="st3"
          d="M51,100c13.5,0,24.8-4.4,33.1-12.1L68.3,75.7c-4.2,2.9-9.9,5-17.3,5c-13.2,0-24.4-8.7-28.4-20.8l-0.6,0
          L6.5,71.9l-0.2,0.6C14.6,88.8,31.4,100,51,100"
        />
        <path
          class="st2"
          d="M22.6,59.9c-1.1-3.1-1.7-6.4-1.7-9.9c0-3.4,0.6-6.8,1.6-9.9l0-0.7L6.8,27.3l-0.5,0.2C2.9,34.3,1,41.9,1,50
          c0,8.1,1.9,15.7,5.3,22.4L22.6,59.9"
        />
        <path
          class="st0"
          d="M51,19.3c9.4,0,15.7,4.1,19.3,7.4L84.4,13C75.8,4.9,64.5,0,51,0C31.4,0,14.6,11.2,6.3,27.6l16.2,12.6
          C26.6,28.1,37.8,19.3,51,19.3"
        />
      </g>
    </svg>

    <svg
      v-if="name==='circled-checkmark'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M84.3,15.7C75.5,6.9,63.4,1.5,50,1.5c-13.4,0-25.5,5.4-34.3,14.2C6.9,24.5,1.5,36.6,1.5,50c0,13.4,5.4,25.5,14.2,34.3
			c8.8,8.8,20.9,14.2,34.3,14.2c13.4,0,25.5-5.4,34.3-14.2c8.8-8.8,14.2-20.9,14.2-34.3C98.5,36.6,93.1,24.5,84.3,15.7z M80,80
			c-7.7,7.7-18.3,12.4-30,12.4c-11.7,0-22.3-4.8-30-12.4C12.3,72.3,7.5,61.7,7.5,50c0-11.7,4.8-22.3,12.4-30
			c7.7-7.7,18.3-12.4,30-12.4c11.7,0,22.3,4.8,30,12.4c7.7,7.7,12.4,18.3,12.4,30C92.5,61.7,87.7,72.3,80,80z"
      />
      <path
        class="fill"
        d="M68.8,35L44.2,60.2L31.1,47.7c-1.5-1.4-3.8-1.4-5.3,0.1c-1.4,1.5-1.4,3.8,0.1,5.3l15.8,15.1c0.7,0.7,1.7,1.1,2.7,1
			c1,0,1.9-0.4,2.6-1.1l27.2-27.9c1.4-1.5,1.4-3.8-0.1-5.3C72.6,33.5,70.3,33.5,68.8,35z"
      />
    </svg>

    <svg
      v-if="name==='paintbrush'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M90.7,2.9c-1.5,0.2-2.9,0.7-4.3,1.3c-4.1,1.7-7.6,4.3-11.1,7c-10.1,7.9-18.9,17.1-27.4,26.6
				c-0.8,0.9-0.9,1.6-0.5,2.7c3.4,7.8,9.4,12.7,18,15.6c0.8,0.5,1.7,0,2.6-1.1c2.8-3.4,5.6-6.7,8.4-10.1c6.2-7.7,12.1-15.7,16.6-24.5
				c1.9-3.6,3.6-7.3,3.9-11.5C97.2,4.8,94.7,2.3,90.7,2.9z"
      />
      <path
        class="fill"
        d="M42.5,44.8c-3.3,5.1-5.6,10.4-6.1,16.5c-1.4,0-2.6-0.1-3.8-0.1c-7.7,0.1-13.3,3.4-16.5,10.5
				c-1.3,3-1.5,6.3-1.5,9.5c-0.1,4.6-2.1,8.1-6.1,10.3C8.3,91.6,8,91.8,7.7,92c-1.4,0.9-1.5,2.4,0,3.4c3.3,2.2,7,3.4,11,3.5
				c9.7,0.1,17.3-4.1,23.4-11.4c2.8-3.4,4.5-7.2,4.5-11.7c0-2-0.6-4.1-1-6.2c5.9-1.2,11.1-4,16-8.1C53.1,58.1,46.7,52.8,42.5,44.8z"
      />
    </svg>

    <svg
      v-if="name==='location'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M50.1,100C32.9,81.5,18.6,62.6,15.7,37.7c-1.9-16.4,8.8-33,26.8-36.8c24.1-5.2,44.9,14.5,41.7,38.9
        c-2.4,18-11.4,32.8-22.2,46.7C58.5,91,54.4,95.1,50.1,100z M50.2,16.6c-10-0.1-18,7.7-18,17.5c-0.1,10,8,18.2,17.9,18.2
        c9.7,0,17.8-8.1,17.9-17.9C67.9,24.6,60.2,16.7,50.2,16.6z"
      />
    </svg>
    <svg
      v-if="name==='pin'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <path
        class="fill"
        d="M78.7,990.3l240.4-240.6l237,237.1c6.9-4.5,14.6-7.7,20.6-13.7c47.4-47.4,47.4-124.4,0-171.8l-51.4-51.6l343.4-343.6l65.2,65.3c7-4.5,14.6-7.7,20.6-13.7c47.4-47.4,47.4-124.4,0-171.7L714.1,45.3c-47.4-47.4-124.4-47.4-171.7,0c-6.1,6.1-9.2,13.7-13.7,20.6l65.2,65.3L250.4,474.8l-51.5-51.5c-47.4-47.4-124.4-47.4-171.7,0c-6.1,6.1-9.2,13.7-13.7,20.6l237,237.1L10,921.5v68.8H78.7L78.7,990.3z"
      />
    </svg>
    <svg
      v-if="name==='storefront'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M98.4,32l-4.2-10.9l-1.7-4.3c-0.2-0.6-0.6-1.2-1.1-1.8c-0.5-0.5-1.1-1-1.7-1.4c-0.6-0.4-1.3-0.7-2.1-0.9
				c-0.7-0.2-1.5-0.3-2.2-0.3h-71c-0.7,0-1.5,0.1-2.2,0.3c-0.7,0.2-1.4,0.5-2.1,0.9C9.6,14,9,14.5,8.5,15c-0.5,0.5-0.9,1.1-1.1,1.8
				l-1.7,4.3L1.6,32c-0.6,1.6-0.9,3.1-0.8,4.6c0.1,1.5,0.5,2.8,1.3,4c0.8,1.2,1.8,2.2,3.2,2.8c0.6,0.3,1.3,0.5,2,0.7v33.4
				c0,1.4,0.3,2.7,0.8,3.9c0.8,1.8,2,3.3,3.6,4.4c0.8,0.5,1.7,1,2.6,1.3c0.9,0.3,1.9,0.4,3,0.4h65.7c1.4,0,2.7-0.3,3.9-0.8
				c1.8-0.8,3.3-2,4.4-3.6c0.5-0.8,1-1.7,1.3-2.6c0.3-0.9,0.4-1.9,0.4-3V44.2c0.7-0.2,1.4-0.4,2-0.7c1.3-0.7,2.4-1.7,3.2-2.8
				c0.7-1.2,1.2-2.5,1.3-4C99.3,35.2,99,33.6,98.4,32z M84.9,78.4c-0.2,0.4-0.5,0.7-0.8,1c-0.2,0.1-0.4,0.2-0.6,0.3
				c-0.2,0.1-0.4,0.1-0.7,0.1H43.6V60.2c0-5.6-4.6-10.2-10.2-10.2c-5.6,0-10.2,4.6-10.2,10.2v19.6h-6.1c-0.3,0-0.6-0.1-0.9-0.2
				c-0.4-0.2-0.7-0.5-1-0.8c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.4-0.1-0.7V43.7c0.2-0.1,0.4-0.1,0.6-0.2c1.7-0.7,3.4-1.7,4.8-2.8
				c1.4-1.2,2.6-2.5,3.6-4c0.9-1.4,1.6-3,1.9-4.6c-0.3,1.6-0.3,3.1,0.1,4.6c0.4,1.5,1.1,2.8,2,4c1,1.2,2.3,2.2,3.7,2.8
				c1.5,0.7,3.2,1.1,5,1.1c1.8,0,3.6-0.4,5.2-1.1c1.6-0.7,3.1-1.7,4.3-2.8c1.2-1.2,2.1-2.5,2.8-4c0.6-1.4,1-3,1-4.6
				c0,1.6,0.3,3.1,1,4.6c0.7,1.5,1.6,2.8,2.8,4c1.2,1.2,2.7,2.2,4.3,2.8c1.6,0.7,3.4,1.1,5.2,1.1c1.8,0,3.5-0.4,5-1.1
				c1.5-0.7,2.7-1.7,3.7-2.8c1-1.2,1.7-2.5,2-4c0.4-1.4,0.4-3,0.1-4.6c0.3,1.6,1,3.1,1.9,4.6c0.9,1.5,2.2,2.8,3.6,4
				c1.4,1.2,3.1,2.2,4.8,2.8c0.2,0.1,0.4,0.2,0.6,0.2v33.9C85.1,77.9,85,78.2,84.9,78.4z"
      />
      <path
        class="fill"
        d="M71.3,50H57.9c-3.4,0-6.1,2.7-6.1,6.1v3.4c0,3.4,2.7,6.1,6.1,6.1h13.4c3.4,0,6.1-2.7,6.1-6.1v-3.4
				C77.4,52.7,74.6,50,71.3,50z"
      />
    </svg>


    <svg
      v-if="name==='storefront-inverted'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        class="fill"
        d="M83.8,47.3c-2.6-0.9-5-2.3-7.1-4.1l-0.2-0.2l-0.2-0.1l-0.1-0.1c-1.5,1.6-3.2,2.9-5.3,3.8h0l0,0l0,0
      c-2.3,1-4.8,1.6-7.5,1.6c-2.7,0-5.2-0.6-7.5-1.5l0,0l0,0l0,0c-2.3-0.9-4.3-2.3-6-3.9c-1.7,1.6-3.7,3-6,3.9l0,0
      c-2.3,1-4.9,1.5-7.5,1.5c-2.7,0-5.2-0.6-7.5-1.6l0,0l0,0h0c-1.9-0.9-3.5-2.1-5-3.5c-2.3,2-5,3.6-8,4.6c-1.8,0.6-3.7,0.9-5.7,0.9
      v29.9c0,6.4,5.2,11.5,11.5,11.5h7.6V60.4h17.1V90h32.2c6.4,0,11.5-5.2,11.5-11.5V48.4c0,0,0,0,0,0C88,48.4,85.8,48,83.8,47.3z
       M76.2,72.7H56.8V60.4h19.4V72.7z"
      />
      <path
        class="fill"
        d="M99.2,27.9L90.7,10H10.8l-10,17.9C0.2,29.5-0.1,31,0,32.5c0.1,1.5,0.5,2.9,1.3,4.1c0.8,1.2,1.9,2.2,3.2,2.9
      c0.5,0.3,1.1,0.5,1.7,0.6c0.1,0,0.2,0.1,0.3,0.1c1.2,0.4,2.4,0.7,3.8,0.7c0,0,0.1,0,0.1,0c1.3,0,2.6-0.2,3.9-0.6
      c4.5-1.4,8.4-5.1,10.2-9.5c0.1-0.4,0.3-0.7,0.4-1.1c0.2-0.6,0.4-1.1,0.5-1.7c-0.2,1-0.2,1.9-0.2,2.9c0,0.6,0.1,1.2,0.3,1.8
      c0.4,1.5,1.1,2.9,2.1,4.1c1,1.2,2.3,2.2,3.8,2.9c1.5,0.7,3.2,1.1,5.1,1.1c1.9,0,3.7-0.4,5.3-1.1c1.6-0.7,3.1-1.7,4.3-2.9
      c1.2-1.2,2.2-2.6,2.8-4.1c0.3-0.6,0.5-1.2,0.6-1.8c0.2-0.9,0.4-1.9,0.4-2.9c0,1,0.1,1.9,0.4,2.9c0.2,0.6,0.4,1.2,0.6,1.8
      c0.7,1.5,1.6,2.9,2.8,4.1c1.2,1.2,2.7,2.2,4.3,2.9c1.7,0.7,3.5,1.1,5.3,1.1c1.9,0,3.6-0.4,5.1-1.1c1.5-0.7,2.8-1.7,3.8-2.9
      c1-1.2,1.7-2.6,2.1-4.1c0.1-0.6,0.2-1.2,0.3-1.8c0.1-0.9,0-1.9-0.2-2.9c0.2,1,0.5,1.9,0.9,2.9c0.3,0.6,0.6,1.2,1,1.8
      c0.9,1.5,2.1,2.8,3.5,4l0.1,0.1l0,0c1.6,1.4,3.4,2.6,5.5,3.3c1.4,0.5,3,0.8,4.5,0.8c0.3,0,0.6,0,0.9,0c0.9-0.1,1.7-0.2,2.5-0.4
      c0.3-0.1,0.6-0.2,0.9-0.3c0.4-0.1,0.8-0.3,1.1-0.5c1.4-0.7,2.5-1.7,3.2-2.9c0.8-1.2,1.2-2.6,1.3-4.1C100.1,31,99.8,29.5,99.2,27.9z
      "
      />
    </svg>
    <svg
      v-if="name==='dollar-light'"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 47 47"
    >
      <g>
        <path
          class="fill"
          d="M23.501,7.705c0.55,0,1-0.45,1-1V1c0-0.55-0.45-1-1-1s-1,0.45-1,1v5.704C22.501,7.254,22.951,7.705,23.501,7.705z"
        />
        <path
          class="fill"
          d="M23.501,39.295c-0.55,0-1,0.45-1,1V46c0,0.55,0.45,1,1,1s1-0.45,1-1v-5.704C24.501,39.746,24.051,39.295,23.501,39.295z"
        />
        <path
          class="fill"
          d="M8.299,6.884c-0.389-0.389-1.025-0.389-1.414,0c-0.389,0.389-0.389,1.025,0,1.414l4.033,4.032
          c0.389,0.389,1.025,0.389,1.414,0.001c0.388-0.389,0.388-1.025-0.001-1.414L8.299,6.884z"
        />
        <path
          class="fill"
          d="M36.084,34.67c-0.389-0.389-1.025-0.389-1.414-0.001c-0.388,0.39-0.388,1.024,0.001,1.414l4.032,4.033
          c0.389,0.389,1.025,0.389,1.414,0c0.389-0.39,0.389-1.025,0-1.414L36.084,34.67z"
        />
        <path
          class="fill"
          d="M7.705,23.5c0-0.55-0.45-1-1-1H1c-0.55,0-1,0.45-1,1s0.45,1,1,1h5.705C7.255,24.5,7.705,24.05,7.705,23.5z"
        />
        <path
          class="fill"
          d="M46,22.5h-5.705c-0.55,0-1,0.45-1,1s0.45,1,1,1H46c0.55,0,1-0.45,1-1S46.55,22.5,46,22.5z"
        />
        <path
          class="fill"
          d="M10.918,34.67l-4.033,4.032c-0.389,0.389-0.389,1.024,0,1.414c0.389,0.389,1.025,0.389,1.414,0l4.032-4.033
          c0.389-0.39,0.389-1.024,0.001-1.414C11.943,34.282,11.307,34.282,10.918,34.67z"
        />
        <path
          class="fill"
          d="M36.082,12.331l4.035-4.032c0.389-0.389,0.389-1.025,0-1.414c-0.389-0.389-1.025-0.389-1.414,0l-4.034,4.033
          c-0.39,0.389-0.39,1.025-0.001,1.414C35.057,12.719,35.693,12.719,36.082,12.331z"
        />
        <path
          class="fill"
          d="M30.586,23.163c-0.578-0.414-1.234-0.748-1.975-0.998c-0.737-0.247-1.495-0.461-2.272-0.638
          c-0.623-0.145-1.231-0.276-1.838-0.404v-4.171c0.447,0.091,0.86,0.229,1.233,0.425c0.703,0.36,1.093,1.059,1.166,2.085h5.072
          c-0.1-1.204-0.406-2.199-0.918-2.988c-0.516-0.788-1.16-1.421-1.938-1.899c-0.777-0.474-1.658-0.813-2.648-1.014
          c-0.643-0.131-1.302-0.203-1.969-0.248V11h-2V13.3c-0.736,0.039-1.464,0.108-2.176,0.24c-1.002,0.189-1.904,0.516-2.705,0.979
          c-0.801,0.463-1.447,1.103-1.936,1.915c-0.486,0.817-0.73,1.838-0.73,3.064c0,0.851,0.174,1.572,0.525,2.158
          c0.35,0.592,0.813,1.08,1.389,1.47c0.578,0.388,1.234,0.699,1.975,0.938c0.738,0.242,1.496,0.441,2.273,0.621
          c0.487,0.103,0.948,0.203,1.385,0.304v5.061c-0.117-0.022-0.236-0.036-0.352-0.065c-0.49-0.127-0.92-0.319-1.297-0.582
          c-0.377-0.262-0.682-0.604-0.92-1.016c-0.24-0.414-0.359-0.91-0.359-1.482H14.5c0.051,1.273,0.346,2.354,0.884,3.229
          c0.538,0.877,1.226,1.588,2.067,2.125c0.838,0.539,1.797,0.922,2.873,1.145c0.713,0.149,1.441,0.231,2.176,0.283V36h2v-2.304
          c0.805-0.045,1.6-0.129,2.381-0.297c1.064-0.222,2.014-0.599,2.84-1.125c0.824-0.525,1.494-1.223,2.008-2.084
          c0.515-0.867,0.771-1.938,0.771-3.215c0-0.902-0.176-1.658-0.527-2.272C31.622,24.089,31.161,23.574,30.586,23.163z
           M22.185,20.532c-0.563-0.189-1.021-0.435-1.369-0.733c-0.353-0.3-0.525-0.689-0.525-1.166c0-0.401,0.098-0.719,0.3-0.955
          c0.198-0.24,0.442-0.42,0.733-0.545c0.287-0.126,0.605-0.208,0.956-0.245c0.08-0.008,0.145-0.007,0.222-0.013v3.751
          C22.397,20.593,22.283,20.563,22.185,20.532z M26.827,28.875c-0.228,0.312-0.509,0.562-0.849,0.748
          c-0.336,0.189-0.711,0.327-1.125,0.416c-0.119,0.025-0.235,0.039-0.354,0.059v-4.595c0.395,0.121,0.758,0.248,1.066,0.384
          c0.513,0.227,0.908,0.482,1.184,0.771c0.275,0.287,0.412,0.633,0.412,1.033C27.163,28.165,27.055,28.561,26.827,28.875z"
        />
      </g>
    </svg>
    <svg
      v-if="name==='find-creator'"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1200 1200"
      xml:space="preserve"
    >
      <g>
        <g>
          <g>
            <path
              class="fill"
              d="M436.717,1028.082c-30.541,0-55.387-24.846-55.387-55.387,0-19.523,10.477-37.82,27.343-47.751,8.482-4.995,18.18-7.636,28.044-7.636s19.564,2.641,28.045,7.638c6.339,3.731,11.809,8.621,16.249,14.53,7.258,9.66,11.093,21.146,11.093,33.219,0,.555-.011,1.109-.027,1.662-.385,12.867-5.293,25.274-13.82,34.942-.431,.488-.861,.954-1.296,1.414-10.428,11.037-25.098,17.368-40.245,17.368Z"
            />
            <path
              class="fill"
              d="M95.955,1196c-52.909,0-95.955-39.096-95.955-87.151v-287.993c0-43.289,34.932-79.308,80.537-86.027,8.534-12.774,23.753-21.298,41.068-21.298h71.182c16.258,0,30.668,7.514,39.43,19.006l43.825-72.932c15.426-25.679,45.013-41.633,77.212-41.633h166.554c32.185,0,61.769,15.945,77.206,41.614l44.554,74.119h135.91c52.909,0,95.955,39.096,95.955,87.151v287.993c0,48.056-43.045,87.151-95.955,87.151H95.955Zm340.762-367.293c-25.46,0-50.469,6.603-72.324,19.096-43.648,24.945-70.759,70.839-70.759,119.776,0,76.576,64.187,138.874,143.084,138.874,39.663,0,76.581-15.485,103.954-43.602,1.339-1.377,2.408-2.507,3.36-3.554,22.026-24.241,34.704-55.317,35.696-87.502,.05-1.642,.074-2.979,.074-4.216,0-30.227-9.899-58.99-28.626-83.182-11.529-14.892-25.705-27.201-42.14-36.589-21.857-12.499-46.86-19.101-72.319-19.101Z"
            />
          </g>
          <path
            class="fill"
            d="M862.671,400.971h-102.903c-100.497,0-190.72,40.965-252.515,105.951h18.336c51.356,0,98.734,24.709,123.651,64.497l45.99,73.47h150.235c39.31,0,76.548,13.252,104.854,37.314,14.334,12.185,25.655,26.51,33.649,42.577,8.497,17.077,12.805,35.247,12.805,54.004v343.322c0,18.757-4.308,36.927-12.805,54.004-3.468,6.969-7.563,13.609-12.257,19.891h228.289v-480.63c0-173.638-151.028-314.399-337.329-314.399Z"
          />
          <circle
            class="fill"
            cx="837.647"
            cy="202.486"
            r="202.486"
          />
        </g>
      </g>
    </svg>

    <svg
      v-if="name==='info'"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <path
        class="fill"
        d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M12,17L12,17c-0.552,0-1-0.448-1-1v-4 c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v4C13,16.552,12.552,17,12,17z M12.5,9h-1C11.224,9,11,8.776,11,8.5v-1 C11,7.224,11.224,7,11.5,7h1C12.776,7,13,7.224,13,7.5v1C13,8.776,12.776,9,12.5,9z"
      />
    </svg>
	<svg
	class="material"
	v-if="name==='cash'" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" ><path class="fill" d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Zm-1 280q14 0 24.5-10.5T514-235v-15q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q20 0 35 7t25 19q10 12 22.5 16.5t23.5-.5q15-6 20.5-20.5T606-653q-16-23-39.5-39T516-710v-15q0-14-10.5-24.5T481-760q-14 0-24.5 10.5T446-725v15q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-26 0-47-12.5T404-364q-8-14-21-19t-26 0q-14 5-20.5 19t-.5 27q16 34 43 55.5t65 29.5v17q0 14 10.5 24.5T479-200Z"/></svg>
	<svg
		v-else-if="String(name).includes('feather')"
		fill="none"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
      	viewBox="0 0 24 24"
	>
		<use :href="`/icons/feather-sprite.svg#${name.replace('feather-', '')}`" />
	</svg>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
/* eslint max-len: "off" */
export default Vue.extend({
  props: {
    name: {
      type: String,
      default: null
    }
  },
  computed: {
    classes () {
      const classes = {
        'icon-svg': true,
        icon: true
      }
      classes[this.name] = true
      return classes
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

	.icon-svg{
		display: inline-block;
		vertical-align: middle;
    color: inherit;
    height: 1em;
    width: 1em;
		&.rotate, &.rotate-180{
			transform: rotate(180deg);
		}
		&.rotate-45{
			transform: rotate(45deg);
		}
		&.rotate-90{
			transform: rotate(90deg);
		}
		svg{
			vertical-align: super;
		}
    &.inline {
      height: 1em;
      width: 1em;
    }
    &.x2{
      height: 2em;
      width: 2em;
    }
    &.x3{
      height: 3em;
      width: 3em;
    }
    &.x4{
      height: 4em;
      width: 4em;
    }
    &.x5{
      height: 5em;
      width: 5em;
    }
    &.x6{
      height: 6em;
      width: 6em;
    }
	}

	.fill, .st0 {
		fill: currentcolor;
	}

	.stroke {
		fill: transparent;
		stroke: currentcolor;
		stroke-linecap:round;
		stroke-linejoin:round;
		stroke-miterlimit:10;
    stroke-width: 4px;
	}

	.icon-accent {
		fill: transparent;
	}

	.path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 0;
		&.circle {
			fill: transparent;
			stroke: green;
			animation: dash .9s ease-in-out;
		}
		&.line {
			fill: transparent;
			stroke: green;
			stroke-dashoffset: 1000;
			animation: dash .9s .35s ease-in-out forwards;
		}
		&.check {
			fill: transparent;
			stroke: green;
			stroke-dashoffset: -100;
			animation: dash-check .9s .35s ease-in-out forwards;
		}
	}

  .google.colored{
    .st0{fill:#EB4535;}
    .st1{fill:#557EBF;}
    .st2{fill:#F9BC15;}
    .st3{fill:#36A852;}
  }

  .tiktok{
    transform: scale(0.75) translateY(-0.1em);
	color: black;
  }

  .facebook.colored {
    color: @facebook !important;
  }

  .youtube.colored{
    color: red;
  }

  .tiktok.colored{
      filter: drop-shadow(-0.05em -0.05em 0 #35ffff) drop-shadow(0.05em 0.05em 0 #fe2d52);
  }

  .instagram{
    transform: scale(0.9);
  }
  .material{
	transform: scale(1.2);
  }

</style>
