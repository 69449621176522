<template>
  <component
	:is="to ? 'router-link' : 'div'"
    :class="{'entity-profile-name': true, 'has-image': hasImage}"
	:to="to"
  >
    <div
      :class="{'entity-image': true, hasBoth: (imageUrl && icon)}"
    >
      <img
        v-if="imageUrl"
        :src="imageUrl"
      >
      <IconSvg
        v-if="icon"
        :name="icon"
      />
      <slot name="image" />
    </div>
    <div
      v-if="title || meta || $slots.title || $slots.meta"
      class="entity-details"
    >
      <span
        v-if="title || $slots.title"
        class="entity-title"
      >
		<IconSvg
			v-if="titleIcon"
			:class="titleIconClass"
			:name="titleIcon"
		/>

        {{title}}
		<slot name="title" />
      </span>
      <span
        v-if="meta || $slots.meta"
        v-tooltip="metaTooltip"
        class="entity-meta"
      >
        {{ meta }}
		<slot name="meta" />
      </span>
    </div>
  </component>
</template>
<script lang="ts">
import Vue from 'vue'

import IconSvg from '@/components/atoms/IconSvg.vue'

export default Vue.extend({
  components: {
    IconSvg
  },
  props: {
    imageUrl: {
      type: String,
      default: null,
      required: false
    },
    icon: {
      type: String,
      default: null,
      required: false
    },
    titleIcon: {
      type: String,
      default: null,
      required: false
    },
	titleIconClass: {
		type: String,
		default: null,
		required: false
	},
    title: {
      type: String,
      default: null,
      required: false
    },
    meta: {
      type: String,
      default: null,
      required: false
    },
    metaTooltip: {
      type: String,
      default: null,
      required: false
    },
	to: {
		type: Object,
		default: null,
		required: false
	}
  },
  computed: {
    hasImage () {
      return this.imageUrl || this.icon
    }
  }
})
</script>

<style lang="less" scoped>
  @import "../../less/variables";

  * {
    line-height: 1.25em !important;
  }
  .entity-profile-name{
    font-size: 1em;
  }

  .entity-image{
    width: 50px;
    height: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    background: @grayLightHover;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      float: left;
      box-shadow: 1px 1px 4px fade(@black, 10%);
    }
    .icon{
      vertical-align: top;
      height: 70%;
      width: 70%;
      color: @grayMedium;
    }
  }

  .size-xs .entity-image{
    height: 25px;
    width: 25px;
  }

  .size-s .entity-image{
    height: 38px;
    width: 38px;
  }

  .size-m .entity-image{
    height: 50px;
    width: 50px;
  }

  .size-l .entity-image{
    height: 80px;
    width: 80px;
  }

  .size-xl .entity-image{
    height: 120px;
    width: 120px;
  }

  .size-xxl .entity-image{
    height: 150px;
    width: 150px;
  }

  .entity-profile-name{
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    gap: 0.25em;
    &.bordered{
      .entity-image{
        border:3px solid @grayLight;
      }
    }
  }

  .hasBoth .icon{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;
    height: 45%;
    background: @white;
    padding: 8%;
    border-radius: 50%;
    box-shadow: 1px 1px 3px fade(@black, 10%);
    color: @grayMedium;
  }

  .entity-details{
	display: flex;
	flex-direction: column;
    gap: 0.5em;
    flex-direction: column;
    max-width: calc(100% - 2em);
	row-gap: 0.25em;
  }

  .entity-title {
    flex-grow: 1;
    font-weight: 500;
    text-overflow: ellipsis;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

	.icon-svg{
		margin-right: 0.25em;
	}
  }

  .entity-meta{
    color: @grayLightMedium;
    font-size: 90%;
    font-weight: 500;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .has-image .entity-details{
    margin-left: 0.5em;
  }


  .reverse{
    flex-direction: row-reverse;
    .entity-title{
      text-align: right;
    }
  }
</style>
