<template>
  <BaseTable>
    <tr>
      <th>Profile Key</th>
      <th>Times called</th>
      <th>Total Time (ms)</th>
    </tr>
    <tr
      v-for="group in groupedProfiles"
      :key="group.key"
    >
      <td>{{ group.key }}</td>
      <td>{{ group.count }}</td>
      <td>{{ group.ms }}</td>
    </tr>
  </BaseTable>
</template>

<script>
import BaseTable from "@/components/molecules/BaseTable.vue";
import { profiles } from "@/plugins/profile";

export default {
  components: {
    BaseTable,
  },
  computed: {
    groupedProfiles() {
      const aggregation = {};

      profiles.forEach(profile => {
        if (!aggregation[profile.key]) {
          aggregation[profile.key] = {
            key: profile.key,
            ms: 0,
            count: 0
          };
        }
        aggregation[profile.key].ms += profile.ms;
        aggregation[profile.key].count += 1;
      });

      // Convert object to an array and sort by `ms` descending
      return Object.values(aggregation).sort((a, b) => b.ms - a.ms);
    }

  },
};
</script>

<style scoped>
/* Your styles here */
</style>
