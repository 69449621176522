<template>
  <table>
    <slot />
  </table>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({})
</script>
<style lang="less" scoped>
  @import '../../less/mixins';
  @import '../../less/variables';

  table {
    width: 100%;
    text-align: left;
    margin-bottom: 1.5em;
    .shadowbox();
    th{
      font-weight: 500;
      color: @grayMedium;
      &.sortable{
        cursor: pointer;
        &:hover, &:active{
          color: @black;
          &::after{
            content: '▲'
          }
        }
      }
      &.sorted{
        color: @black;
        &.asc::after{
          content: '▲'
        }
        &.desc::after{
          content: '▼'
        }
      }
      &::after{
        position: absolute;
        margin-left: 0.25em;
      }
    }
    td{
      &.cell-primary {
        font-weight: 500;
      }
    }
    /deep/ th, /deep/ td{
      padding: 1em 0.5em;
      border-bottom:1px solid @grayLight;
      vertical-align: middle;
      .avatar{
        margin-top: -0.5em;
        margin-bottom: -0.5em;
      }
      &:first-child{
        padding-left: 1.5em;
      }
      &:last-child{
        padding-left: 1.5em;
      }
    }
    tr.summary{
      background: @grayLight;
      th{
        color: @black;
        font-weight: 500;
      }
    }
    tr.actionable{
      &:hover, &:active{
        background: @grayLightHover;
        cursor: pointer;
      }
      &:last-child{
        td, th{
          border-bottom: 0;
        }
      }
    }
  }
</style>
