<template>
  <div>
    <TheLogo />
    <h2>Sign In</h2>
    <p>
      Need an account?
      <router-link :to="{name: 'register'}">
        Create an Account
      </router-link>
    </p>
    <div v-show="!showLoginForm">
      <ButtonSocialLogin
        platform="facebook"
        label="Continue with Facebook"
      />
      <ButtonSocialLogin
        platform="tiktok"
        label="Continue with TikTok"
      />
      <ButtonSocialLogin
        platform="google"
        label="Sign in with Google"
      />
    </div>

    <p v-show="!showLoginForm">
      <HorizontalRule />
      <a @click="showLoginForm=true">Sign in with email and password</a>
    </p>

    <div v-show="showLoginForm">
      <InputEmail
        :value="email"
        name="email"
        autocomplete="on"
        :required="true"
        placeholder="Email"
        @change="value => email=value"
      />
      <InputPassword
        :value="password"
        name="password"
        :required="true"
        placeholder="Password"
        autocomplete="current-password"
        @change="value => password=value"
        @enter="login"
      />
      <FormErrors :errors="[errorMessage]" />
      <Button
        ref="submitButton"
        icon="lock"
        label="Sign In"
        @click.native="login"
      />
    </div>
    <p v-show="showLoginForm">
      <HorizontalRule />
      <router-link :to="{name: 'recover-password', query: {email}}">
        Forgot password?
      </router-link>
    </p>
    <p
      v-show="showLoginForm"
      v-if="false"
    >
      <a @click="showLoginForm=false">Sign in with social media account</a>
    </p>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import HorizontalRule from '@/components/atoms/HorizontalRule.vue'
import FormMixin from '@/components/MixinForm.vue'
import ButtonSocialLogin from '@/components/molecules/ButtonSocialLogin.vue'
import FormErrors from "@/components/molecules/FormErrors.vue";
import TheLogo from "@/components/atoms/TheLogo.vue";


export default Vue.extend({
  components: {
    TheLogo,
    ButtonSocialLogin,
    HorizontalRule,
    FormErrors
  },
  mixins: [FormMixin],
  props: {
    errorMessage: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      showLoginForm: true,
      email: null,
      password: null
    }
  },
  methods: {
    async login () {
      if (this.allInputsAreValid()) {
        await this.$store.dispatch('user/login', {
          email: this.email,
          password: this.password
        }).catch(error => {
          this.errorMessage = error
        })
      }
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

</style>
