<template>
  <div class="checkbox-container">
    <input
      :name="name"
      type="hidden"
      value="false"
    >
    <input
      :id="name"
      ref="checkbox"
      :placeholder="placeholder"
      :checked="value"
      :name="name"
      value="true"
      type="checkbox"
      @change="onChange"
    >
    <label :for="name">
      <span :class="{checkbox: true, checked: !!value, invalid, valid}">
        <IconSvg
          v-if="value"
          name="check"
        />
      </span>
    </label>
    <div>
      <label
        v-if="label"
        :for="name"
        style="display: inline;"
      >
        {{ label }}
      </label>
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'
import IconSvg from "@/components/atoms/IconSvg.vue";
export default Vue.extend({
  components: {
    IconSvg
  },
  extends: BaseInput,
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    }
  }
})
</script>

<style lang="less" scoped>
@import '../../less/variables';

input {
  display: none;
}

.checkbox-container{
  display: flex;
}

.checkbox{
  float: left;
  height: 1.75em;
  width: 1.75em;
  border-radius: 0.4em;
  border: 2px solid @grayDark;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  margin-left: 1em;
  box-sizing: border-box;
  &:hover{
    background: @grayExtraLight;
  }
  &.checked{
    background: black;
    border-color: black;
    color: white;
  }
  &.invalid{
    border-color: red;
  }
}
</style>
