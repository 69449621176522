import axios from 'axios';
import moment from 'moment';

// NOTE:
// There's watcher for changes subscription features
// in the store/index.ts file.

export default {
	name: 'subscription',
	namespaced: true,
	actions: {
		async getStripePortalLink(context) {
			try {
				const account = context.rootGetters['account/selected']
				const response = await axios.get(`/api/accounts/${account._id}/stripe-portal-session`)
				return response.data.url;
			} catch (error) {
				console.error('Error getting Stripe portal link', error)
			}
		},
		authorizeFeature({ rootGetters, getters, commit }, feature: string): boolean {
			if (rootGetters['account/selected'].isFreeAccount) {
				return true
			}

			if (rootGetters['user/isAdmin']) {
				return true;
			}

			let approved = true;

			const featureValue = getters.featureValue(feature);

			if (featureValue !== 'unlimited') {
				if (feature === 'users') {
					const userCount = rootGetters['collaboration/forSelectedAccount'].length;
					approved = userCount < featureValue;
					console.log(`Feature ${feature} ${approved ? 'authorized' : 'not authorized'}: Account supports ${featureValue} collaborations and has ${userCount}.`);
				} else if (feature === 'projects') {
					const projectCount = rootGetters['project/forSelectedAccount'].length;
					approved = projectCount < featureValue;
					console.log(`Feature ${feature} ${approved ? 'authorized' : 'not authorized'}: Account supports ${featureValue} projects and has ${projectCount}.`);
				}
			}
			if (approved) {
				return true;
			} else {
				commit('app/SET_UPGRADE_MODAL_REASON', feature, { root: true })
				throw new Error(`Feature ${feature} not authorized`);
			}
		}
	},
	getters: {
		stripe: (state, getters, rootState, rootGetters) => {
			return rootGetters['account/selected'].stripe
		},
		customer: (state, getters) => {
			return getters.stripe?.customer
		},
		isSubscribed: (state, getters) => {
			return !!getters.stripe?.subscription
		},
		isTrial: (state, getters) => {
			return getters.isSubscribed && getters.stripe.subscription.status === 'trialing'
		},
		trialExpirationDate: (state, getters) => {
			return moment(getters.stripe.subscription.trial_end * 1000).format('MMMM Do, YYYY')
		},
		isTrialActive: (state, getters) => {
			return getters.isSubscribed && getters.stripe.subscription.trial_end > Date.now()
		},
		trialDuration: (state, getters) => {
			if (getters.isTrial) {
				return Math.ceil((getters.stripe.subscription.trial_end - getters.stripe.subscription.trial_start) / (60 * 60 * 24))
			} else {
				return 0
			}
		},
		trialDurationRemaining: (state, getters) => {
			if (getters.isTrial) {
				return Math.max(
					0,
					Math.ceil((getters.stripe.subscription.trial_end - (Date.now() / 1000)) / (60 * 60 * 24))
				);
			} else {
				return 0
			}
		},
		isPastDue: (state, getters) => {
			return getters.isSubscribed && getters.stripe.subscription.status === 'past_due'
		},
		isCanceled: (state, getters) => {
			return getters.isSubscribed && getters.stripe.subscription.cancel_at !== null
		},
		isUnpaid: (state, getters) => {
			return getters.isSubscribed && getters.stripe.subscription.status === 'unpaid'
		},
		isEnded: (state, getters) => {
			return getters.isSubscribed && getters.stripe.subscription.status === 'ended'
		},
		isActive: (state, getters) => {
			return getters.isSubscribed && getters.stripe.subscription.status === 'active'
		},
		isCanceledOrEnded: (state, getters) => {
			return getters.isCanceled || getters.isEnded
		},
		isPastDueOrUnpaid: (state, getters) => {
			return getters.isPastDue || getters.isUnpaid
		},
		isPendingOrActive: (state, getters) => {
			return getters.isPending || getters.isActive
		},
		features: (state, getters) => {
			return getters.stripe?.product?.metadata
		},
		featureValue: (state, getters) => (feature) => {
			return getters.features?.[feature]
		},
		product: (state, getters) => {
			return getters.stripe?.product
		},
		plan: (state, getters) => {
			return getters.stripe?.subscription.plan
		}
	}
}