import axios from 'axios';
import Vue from 'vue';
import moment from 'moment';

import { db } from '@/plugins/vuex-sync-documents';

export default {
  namespaced: true,
  state: {
    statusMessage: null,
	actionError: null,
    fromRoute: null,
	version: 'unset',
	build: 'unset',
    showProfiler: false,
    upgradeModalReason: '',
    // stateRestored: false,
	creatorVisionServer: '',
    gitHash: null,
    serverStartTime: 0,
    documentCacheTTL: 1000 * 60 * 60, // 1 hour,
    cancelledServerRedirect: false,
    appOutOfSyncWithServer: false,
    hideAppOutOfSyncError: false,
    tours: {

    },
  },
  mutations: {
	SET_ACTION_ERROR (state, actionError) {
		state.actionError = actionError
	},
    SET_STATUS_MESSAGE (state, message) {
      state.statusMessage = message;
    },
    SET_UPGRADE_MODAL_REASON (state, reason) {
      state.upgradeModalReason = reason;
    },
    SET_FROM_ROUTE (state, fromRoute) {
      state.fromRoute = fromRoute;
    },
    SET_SHOW_PROFILER(state, showProfiler) {
      state.showProfiler = showProfiler;
    },
    SET_STATE_RESTORED(state, stateRestored) {
      state.stateRestored = stateRestored;
    },
    SET_CANCELLED_SERVER_REDIRECT(state, value) {
      state.cancelledServerRedirect = value;
    },
    SET_SERVER_STATUS(state, status) {
      if(status){
        if(!state.appOutOfSyncWithServer) {
          // Check of the git hash or server start time changed
          const gitHashChanged = state.gitHash && state.gitHash !== status.gitHash;
          const serverStartTimeChanged = state.serverStartTime && state.serverStartTime !== status.startTime;
          // If either changed, then the app is not synced with the server
          if(gitHashChanged || serverStartTimeChanged){
            state.appOutOfSyncWithServer = true;
            console.log('The app is no longer in sync with the server');
          }
        }
        if(state.appOutOfSyncWithServer){
          // make sure the user stays aware that they need to refresh the page.
          state.hideAppOutOfSyncError = false;
        }
        // Set the git hash and server start time
        state.gitHash = status.gitHash;
		state.serverStartTime = status.startTime;
		state.version = status.version;
		state.build = status.build;
		state.env = status.env;
		state.creatorVisionServer = status.creatorVisionServer;
      }else{
        state.serverStartTime = 0;
      }
    },
    SET_HIDE_APP_OUT_OF_SYNC_ERROR(state, value) {
      state.hideAppOutOfSyncError = value;
    },
    SET_TOUR_STATUS(state, data: {tour: string; status: boolean}) {
      console.log(data);
      Vue.set(state.tours, data.tour, data.status);
    },
  },
  actions: {
    async getServerStatus(context) {
      try{
        const status = await axios.get('/api/status');
        if(status && status.data && status.data.startTime){
          console.debug('Got server status', status.data);
          context.commit('SET_SERVER_STATUS', status.data);
          return status.data;
        }else{
          throw new Error('Failed to get server status');
        }
      } catch(e){
        console.error(e);
        context.commit('SET_SERVER_STATUS', null);
      }
    },
    setStatusMessage (context, message) {
      context.commit('SET_STATUS_MESSAGE', message)
      setTimeout(() => {
        context.commit('SET_STATUS_MESSAGE', null)
      }, 2000);
    },
    async periodicalReload(context, options = {forceReload: false}) {
      await context.dispatch('getServerStatus');
      const modules = Object.keys(context.rootState);
      modules.forEach(moduleName => {
        if (Object.prototype.hasOwnProperty.call(context.rootState[moduleName], 'lastLoadDate')) {
          if(options.forceReload){
            // Reset the modules last load date
            // that way it loads all data from the beginning of time
            context.commit(`${moduleName}/RESET_LAST_LOAD_DATE`, null, {root: true});
          }
          context.dispatch(`${moduleName}/periodicalReload`, options, { root: true });
        }
      });
    },
    async cleanupFromTests(context) {
      await axios.get('/api/utilities/cleanup-from-tests')
      await context.dispatch('clearLocalCache', {refresh: true});
    },
    async clearLocalCache(context, options){
      console.log('Clearing local cache');
      await db.delete();
      if(options.refresh){
        console.log('Refreshing');
        window.location.reload();
      }
    }
  },
  getters: {
    getTourStatus: (state) => {
      const tourStatuses = {};
      Object.keys(state.tours).forEach(tour => {
          tourStatuses[tour] = state.tours[tour] || 'incomplete';
      });
      return tourStatuses;
	},
	getServerStartTime: (state) => {
		return moment(state.serverStartTime).format('M/D, h:mm a');
	},
	actionErrorMessage: (state) => {
		let message = "An error occurred";
		
		// Helper function to safely access nested properties
		const safeAccess = (path) => {
			const obj = 'state.app.actionError'
			return path.split('.').reduce((acc, part) => acc && acc[part], obj);
		};
		
		// Check for action
		const action = safeAccess('action');
		if (action) {
			message += ` while attempting to ${action}`;
		}
		
		// Check for module
		const module = safeAccess('module');
		if (module) {
			const singularize = (word) => {
			// This is a very basic singularization. You might want to use a more robust solution.
			return word.endsWith('s') ? word.slice(0, -1) : word;
			};
			message += ` the ${singularize(module)}`;
		}
		
		// Check for error message
		const errorMessage = safeAccess('error.response.data.message');
		if (errorMessage) {
			message += `: ${errorMessage}`;
		}
		
		return message;
	}
  }
}
