import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'agreement-shipments',
  getters: {
    forAgreement: (state, getters) => agreementId => {
      return getters.all.filter(object => object.agreement === agreementId || false)
    }
  },
  async onChange(context, object) {
    // re-load the agreement
    console.log('re-loading agreement');
    await context.dispatch('agreement/read', object.agreement, { root: true })
  },
  async onDelete(context, object) {
    // re-load the agreement
    console.log('re-loading agreement');
    await context.dispatch('agreement/read', object.agreement, { root: true })
  }
})
