import Vue from 'vue';
function vuexCreatorVision(store) {
    
    async function initialize() {
		store.subscribe(async (mutation) => {
			try{
				// Get the module name and type of mutation
				const [moduleName, mutationType] = mutation.type.split('/');
				if(moduleName === 'media') {
					if(mutationType === 'SET'){
						console.log('Media was set', mutation)
						const existingMedia = store.state.creatorVision.mediaOutliers[mutation.payload.visionId]
						if(existingMedia) {
							Object.keys(mutation.payload).forEach(key => {
								if(key in mutation.payload && key in existingMedia && existingMedia[key] !== mutation.payload[key]) {
									console.log('Updating outlier media', key, ' to ', mutation.payload[key])
									Vue.set(existingMedia, key, mutation.payload[key])
								}
							})
						}
					}
				}
			} catch(e){
				console.error(e);
			}
		});
    }
    initialize();
}
export default vuexCreatorVision;