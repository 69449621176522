<template>
  <div class="horizontal-rule">
    <span
      v-if="label"
      class="label"
    >
      {{ label }}<slot />
    </span>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false,
      default: null
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  .horizontal-rule{
    background: @grayExtraLight;
    height:3px;
    border-radius:1.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &.dark{
      background: @grayLight;
    }
    .label{
      background: white;
      padding: 0 10px;
      color: @grayMedium;
      font-size: 0.9em;
    }
    &.mini{
      width:50px;
    }
  }

</style>
