<script lang="ts">

/**
 * @module FormMixin
 *
 * [FormMixin] serves two primary purposes.
 * 1. Define base components for [Forms]
 * 2. Maintain key [Input] validation pass/failure information
 *
 * ABOUT VALIDATION
 * FormMixin listens to 'change' and 'error' events from [Inputs]
 * to maintain key validation pass/failure information to the [Form].
 * This is primarily used to hide/show [Errors], or other components
 * based on which [Input] have failed validation, if any.
 *
 * @inputErrors {object} contains which [Input] have failed validation
 * and what error they returned.
 *
 * @formHasError {boolean} contains whether or not ANY [Input]
 * is currently failing validation
 *
 * ==========================================
 */

import deepmerge from 'deepmerge';
import Vue from 'vue'

import Button from '@/components/atoms/Button.vue'
import Error from '@/components/atoms/Error.vue'
import InputCheckbox from '@/components/atoms/InputCheckbox.vue'
import InputEmail from '@/components/atoms/InputEmail.vue'
import InputNumber from '@/components/atoms/InputNumber.vue'
import InputPassword from '@/components/atoms/InputPassword.vue'
import InputPhone from '@/components/atoms/InputPhone.vue'
import InputSelect from '@/components/atoms/InputSelect.vue'
import InputSelectCheckbox from '@/components/atoms/InputSelectCheckbox.vue'
import InputSlider from '@/components/atoms/InputSlider.vue'
import InputText from '@/components/atoms/InputText.vue'
import InputTextArea from '@/components/atoms/InputTextArea.vue'
import InputTextAreaHighlightable from '@/components/atoms/InputTextAreaHighlightable.vue'
import FormErrors from "@/components/molecules/FormErrors.vue";
import InputToggle from '@/components/atoms/InputToggle.vue'
import InputSelectList from '@/components/atoms/InputSelectList.vue'

export interface FormComponent extends Vue{
  formRules: object;
}

export default Vue.extend({
  components: {
    Error,
    Button,
    InputEmail,
    InputPassword,
    InputText,
    InputCheckbox,
    InputNumber,
    InputTextArea,
    InputSelect,
    InputSelectCheckbox,
    InputSlider,
    InputPhone,
    InputToggle,
    InputTextAreaHighlightable,
    FormErrors,
		InputSelectList
  },
  directives: {
    formRules: function (input, binding, vnode) {
      if (typeof binding.value === 'function') {
        (vnode.context as FormComponent).formRules[binding.expression] = true
      } else if (typeof binding.value === 'object') {
        (vnode.context as FormComponent).formRules = binding.value
      }
    }
  },
  props: {
    initialFormData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    submitButtonLabel: {
      type: String,
      default: null
    },
    submitOnChange: {
      type: Boolean,
      default: false
    },
    validate: {
      type: String,
      default: 'all',
      validator: (value: string) => ['all', 'changes'].includes(value)
    },
    create: {
      type: [Function, null],
      required: false,
      default: null
    },
    update: {
      type: [Function, null],
      required: false,
      default: null
    },
    delete: {
      type: [Function, null],
      required: false,
      default: null
    },
    reviewBeforeSubmit: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      /*
            * @inputErrors {object} contains which [Input] have failed validation
            * and what error they returned.
            * */
      inputErrors: {},
      /*
            * @formErrors {object} contains form formRules have failed validation
            * and what error they returned.
            * */
      formErrors: {},
      /*
             * @formHasError {boolean} contains whether or not ANY [Input]
             * is currently failing validation
             * */
      formHasError: false,
      /*
             * @inputs {array} contains a list of all descendent [Input]s
             * */
      inputs: [],
      inputObserver: null, // this observes changes in the inputs array
      /*
             * @changedFormData {object} contains all changed form values
             * */
      changedFormData: {

      },
      overwrittenFormData: {

      },
      // deletedPaths: [],
      loading: false,
      lastSaved: null,
      reviewing: false
    }
  },
  computed: {
    error () {
      return this.inputErrors
    },
    formData () {
      // basic form data
      // console.log({
      //   initialFormData: this.initialFormData,
      //   changedFormData: this.changedFormData,
      //   overwrittenFormData: this.overwrittenFormData
      // });
      let data = deepmerge(this.initialFormData, this.changedFormData)
      // overwritten form data
      data = deepmerge(data, this.overwrittenFormData, { arrayMerge: (destinationArray, sourceArray) => sourceArray });
      this.cleanObject(data)
      return data;
    },
    formDataHasChanged () {
      return Object.keys(this.changedFormData).length > 0
    },
    isNew () {
      return !this.formData._id
    },
    inputErrorMessages(){
      return [...new Set(Object.values(this.inputErrors)
          .map(error => error === 'notEmpty' ? 'Please fill out all required fields.': error))]
    }
  },
  watch: {
    lastSaved(){
      this.$store.dispatch('app/setStatusMessage', 'Saved just now');
    },
    initialFormData:{
      handler(){
        // Delete redundant changedFormData keys
        // when they are found to match in initialFormData
        this.deleteRedundantChangedFormData(this.changedFormData, this.initialFormData);
        this.deleteRedundantChangedFormData(this.overwrittenFormData, this.initialFormData);
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    if (this.inputs.length === 0) {
      console.warn('MixinForm: Zero inputs found. If you have conditionally-shown inputs use v-show instead of v-if.')
    }
    // check to see if the form has instance of FormErrors in children
    const hasFormErrors = this.$children.find(child => child.$options.name === 'FormErrors')
    if(this.showErrors && !hasFormErrors){
      console.warn('MixinForm: Add <FormErrors /> to your template or set :show-errors="false".', this.$el)
    }
  },
  methods: {
    registerInput(input){
      if (this.inputs.includes(input)) {
        console.warn('Input is already registered:', input);
        return;
      }
      this.inputs.push(input);
      input.$on('change', (value, options) => {
        console.log('onchange', value, options);
        this.onChange(input.name, value, options)
      }).$on('error', value => {
        this.onError(input.name, value)
      }).$on('enter', () => {
        this.validateAndSubmit()
      })
    },
    unregisterInput(input){
      const index = this.inputs.indexOf(input);
      if(index > -1){
        this.inputs.splice(index, 1);
      }
    },
    validateAndSubmit () {
      if (this.allInputsAreValid() && this.formRulesAreValid()) {

        const data = deepmerge({}, this.formData);
        const changedData = deepmerge({}, this.changedFormData);

        if(this.reviewBeforeSubmit && !this.reviewing){
          this.reviewing = true;
          this.$emit('review', { data, changedData })
          return;
        }
        this.$emit('submit', { data, changedData })

        // console.log({
        //   isnew: this.isNew,
        //   update: this.update,
        //   loading: this.loading,
        //   lastSaved: this.lastSaved
        // })

        if (!this.isNew && this.update && typeof this.update === 'function') {
          // Call update function
          this.loading = true
          this.update(Object.assign(
              changedData,
            { _id: this.formData._id }
          )).then(() => {
            this.loading = false
            this.lastSaved = new Date();
            this.$emit('update', { data, changedData })
          }).catch(error => {
            console.log(error);
            this.loading = false
          })
        }

        if (this.isNew && this.create && typeof this.create === 'function') {
          // Call create function
          this.loading = true
          Vue.delete(this.formErrors, 'create')
          this
            .create(this.formData)
            .then(resultingObject => {
              // console.log({resultingObject})
              this.loading = false
              this.lastSaved = new Date();
              this.$emit('create', resultingObject)
            })
            .catch(error => {
              console.log(error)
              if(error.message){
                this.formErrors = { create: error.message };
                this.$emit('error', {
                  rule: 'create',
                  message: error.message
                })
              }
              this.loading = false
            })
        }
      } else {
        this.$emit('error', this.inputErrors)
      }
    },
    /**
         * Returns whether or not all child [Inputs] have passed validation
         * @return {boolean}
         */
    allInputsAreValid () {
      this.validateAllInputs()
      let allInputsAreValid = true
      Object.values(this.inputErrors).forEach(val => {
        if (val) {
          allInputsAreValid = false
        }
      })
      return allInputsAreValid
    },
    /**
         * Returns whether form formRules from v-formRules directive are valid
         * @return {boolean}
         */
    formRulesAreValid () {
      if (this.formRules) {
        this.formErrors = {}
        // console.log('Looping over form formRules', this.formRules.length)
        this.formRules.forEach(ruleName => {
          if (Object.prototype.hasOwnProperty.call(this, ruleName) && typeof this[ruleName] === 'function') {
            // console.log('found rule function', ruleName)
            const results = this[ruleName]()
            // console.log('rule results are', results)
            if (results !== true) {
              this.formErrors[ruleName] = results
              // console.log('emitting form error')
              this.$emit('error', {
                rule: ruleName,
                message: results
              })
            }
          } else {
            console.warn(`ReferenceError: Form validation rule ${ruleName} is referenced but not defined`)
          }
        })
        if (Object.keys(this.formErrors).length > 0) {
          // console.log('form has errors', this.formErrors)
          this.formHasError = true
          return false
        } else {
          // console.log('form has no errors', this.formErrors)
          return true
        }
      } else {
        return true
      }
    },
    /**
         * Executes validation checks on ALL child [Inputs]
         */
    validateAllInputs () {
      if (this.validate === 'all') {
        // Validate all inputs
        this.inputs.forEach(input => input.checkValidity())
      } else if (this.validate === 'changes') {
        // Only run validation checks on changed values
        const changedInputs = this.inputs.filter(input => {
          return Object.prototype.hasOwnProperty.call(this.changedFormData, input.name)
        })
        changedInputs.forEach(input => input.checkValidity())
      }
    },
    /**
         * Event handler for 'change' events from all inputs
         * @param {string} field - The name of the input that's changed
         * @param {string} value - The new value of the input
         * @param {object} options - option includes overwrite
         */
    onChange (field, value, options) {
      this.setFieldValue(field, value, options)
    },
    /**
         * Event handler for 'error' events from all inputs
         * @param {string} inputName - The name of the input that's changed
         * @param {object} event - details of the error event
         */
    onError (inputName, event) {
      // Set this.inputErrors[inputName] = errorName (rule broken, I.E. minLength)
      this.$set(this.inputErrors, inputName, event.error)
      this.formHasError = true
    },
    /**
     * Event handler for 'error' events from all inputs
     * @param {object} data - The name of the input that's changed
     * @param {object} options - include the option to overwrite or merge
     */
    setFormData (data, options = { overwrite: true }) {
      console.log('setFormData', data);
      Object.keys(data).forEach(field => {
        this.setFieldValue(field, data[field], options)
      })
	  if(options.submit){
        this.$nextTick(() => {
          this.validateAndSubmit();
        });
	  }
    },
    resetForm () {
      this.changedFormData = {};
    },
    // Helper function to set a nested value within this.changedFormData.
    setNestedValue(path, value, options) {
      // Split the provided path string into an array of keys.
      const keys = path.split('.');

      // Start with this.changedFormData as the initial object.
      let obj = this.changedFormData;
      // Loop through all keys except for the last one.
      for (let i = 0; i < keys.length - 1; ++i) {
        // Get the current key from the keys array.
        const key = keys[i];
        // If the current level of the object doesn't contain the key, create an empty object at that key.
        if (!obj[key]) {
          Vue.set(obj, key, {});
        }
        // Move down one level in the object for the next iteration.
        obj = obj[key];
      }
      // At this point, obj is the parent object of the property we want to set.
      // The last key in the keys array is the property we want to set on obj.
      // Set the value of the property on obj using Vue.set to ensure reactivity.
      Vue.set(obj, keys[keys.length - 1], value);

      if(options.overwrite){
        // Start with this.changedFormData as the initial object.
        let obj = this.overwrittenFormData;
        // Loop through all keys except for the last one.
        for (let i = 0; i < keys.length - 1; ++i) {
          // Get the current key from the keys array.
          const key = keys[i];
          // If the current level of the object doesn't contain the key, create an empty object at that key.
          if (!obj[key]) {
            Vue.set(obj, key, {});
          }
          // Move down one level in the object for the next iteration.
          obj = obj[key];
        }
        // At this point, obj is the parent object of the property we want to set.
        // The last key in the keys array is the property we want to set on obj.
        // Set the value of the property on obj using Vue.set to ensure reactivity.
        Vue.set(obj, keys[keys.length - 1], value);
      }
    },
    getNestedValue(path){
      const keys = path.split('.');
      let obj = this.formData;
      for (let i = 0; i < keys.length; ++i) {
        obj = obj[keys[i]];
        if (obj === undefined) break;
      }
      return obj;
    },


    setFieldValue(field, value, options = {overwrite: false}) {
		if(!field){
			console.debug('Form: Not setting value. No name provided.', this.$el);
			return;
		}
      // Split the field into its constituent parts
      const keys = field.split('.');

      // Get the current value of the nested field
      let current = this.formData;
      for (let i = 0; i < keys.length; ++i) {
        current = current[keys[i]];
        if (current === undefined) break;
      }

      // If the value hasn't changed, do nothing
      if (current === value) return;

      // Set the value of the nested field
      this.setNestedValue(field, value, options);

      Vue.delete(this.inputErrors, field);
      this.formHasError = false;

      this.$emit('change', { field, value });

      if (this.submitOnChange && !options.disableSubmitOnChange) {
        this.$nextTick(() => {
          this.validateAndSubmit();
        });
      }
    },
    deleteRedundantChangedFormData(changed, initial) {
      if (changed && initial) {
        Object.keys(changed).forEach(key => {
          // If both the changed and initial value are objects (including arrays), recurse into them
          if (
              typeof changed[key] === "object" &&
              changed[key] !== null &&
              initial[key]
          ) {
            this.deleteRedundantChangedFormData(changed[key], initial[key]);

            // If they are arrays, remove identical or redundant members
            if (Array.isArray(changed[key]) && Array.isArray(initial[key])) {
              for (let i = changed[key].length - 1; i >= 0; i--) {
                if (initial[key].includes(changed[key][i]) || (typeof changed[key][i] === 'object' && Object.keys(changed[key][i]).length === 0)) {
                  changed[key].splice(i, 1);
                }
              }
            }

            // After recursion (and potential array handling), check if the object or array has become empty and delete it if so
            if (Object.keys(changed[key]).length === 0) {
              this.$delete(changed, key);
            }
          }
          // If the changed value and initial value are the same, delete the key from changed
          else if (JSON.stringify(changed[key]) === JSON.stringify(initial[key])) {
            this.$delete(changed, key);
          }
        });
      }
    },
    cleanObject(obj) {
      if(obj && typeof obj === 'object'){
        Object.keys(obj).forEach((key) => {
          if (/^(-?\d+)$/.test(key) && obj[key] === null) {
            delete obj[key];
          } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            this.cleanObject(obj[key]);
          }
        });
      }
    },
    formDataItems(items){
      const filteredItems = {};
      Object.keys(items || {}).forEach(key => {
        if(items[key]){
          filteredItems[key] = items[key];
        }
      })
      return filteredItems;
    },
    addItem(key: string, data: unknown) {
      const formData = deepmerge(this.initialFormData, this.changedFormData);
      let newKey;
      if(formData[key]) {
        // Convert string keys to numbers, find the max, and increment it by 1 for the newKey
        newKey = Object.keys(formData[key]).reduce((a, b) => Math.max(Number(a), Number(b)), 0) + 1;
      }else{
        newKey = 0;
      }

      // Since newKey is a number, if setFieldValue expects a string, convert it back.
      this.setFieldValue(`${key}.${newKey.toString()}`, data);
    }

  }
})
</script>
