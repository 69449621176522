<template>
  <transition name="fade">
    <div
      v-show="show"
      class="socket-connection-error-container"
    >
      <transition name="slide">
        <div
          v-show="show"
          class="socket-connection-error-message"
        >
          <span v-if="reconnectFailed">
            Server not available.

            <Button
              v-if="false"
              label="Refresh page"
              icon="refresh"
              class="small rounded"
              @click="$store.dispatch('app/clearLocalCache', {refresh: true})"
            />
          </span>
          <span v-else-if="reconnectSucceeded">
            Reconnected <IconSvg name="check" />
          </span>
          <span v-else>
            Reconnecting...
            <ProgressSpinner />
          </span>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';
import Vuex from 'vuex';

import Button from "@/components/atoms/Button";
import IconSvg from "@/components/atoms/IconSvg";
import ProgressSpinner from "@/components/atoms/ProgressSpinner";

export default Vue.extend({
  components: {
    Button,
    ProgressSpinner,
    IconSvg
  },

  computed: {
    ...Vuex.mapState('socket', ['show', 'reconnectFailed', 'reconnectSucceeded']),
    ...Vuex.mapGetters('socket', ['socketProblemDetected']),
  },

  watch: {
    socketProblemDetected: {
      immediate: true,
      handler(problemDetected) {
        problemDetected ? this.onProblemDetected() : this.onProblemCleared();
      }
    }
  },

  methods: {
    ...Vuex.mapActions('socket', ['onProblemDetected', 'onProblemCleared']),
  },
});
</script>

<style lang="less" scoped>
@import '../../less/variables.less';
@import '../../less/mixins.less';

.socket-connection-error-container{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: fade(@grayMedium, 70%);
  z-index: 21;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  pointer-events: none;
}

.socket-connection-error-message{
  display: flex;
  align-items: center;
  height: 3rem;
  background: @grayLight;
  font-size: 0.8rem;
  padding: 0 1rem;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  .shadow();
}

// Fade effect for the container
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

// Slide effect for the message
.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter, .slide-leave-to {
  transform: translateY(-100%);
}

.slide-enter-to, .slide-leave {
  transform: translateY(0);
}
</style>