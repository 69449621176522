import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'services',
  getters: {
    forSelectedAccount: (state, getters, rootState, rootGetters) => {
      let services = []
      rootGetters['serviceCategory/forSelectedAccount'].forEach(category => {
        services = services.concat(
          getters.all.filter(service => service.serviceCategory === category._id)
        )
      })
      return services
    },
    forServiceCategory: (state, getters) => serviceCategory => {
      return getters.all.filter(service => service.serviceCategory == serviceCategory)
    },
    forAccountId: (state, getters) => accountId => {
      return getters.all.filter(object => object.account === accountId || false)
    }
  },
  async beforeSave (context, doc) {
    if(doc.price){
      doc.price = String(doc.price).replace(/\D/g,'')
    }
  },
})
