<template>
  <span class="the-logo">
    <img :src="`/img/creator-velocity-logo${color ? `-${color}`:''}.png`">
  </span>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: ''
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';
  .the-logo {
    display: inline-block;

    img {
      width: 100%;
    }
  }
</style>
