import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'messages',
  actions: {
    markRead(context, messageId){
      const message = context.state.documents[messageId];
      if(message){
        return context.dispatch('update', {
          _id: messageId,
          readBy: [...(message.readBy || []), { user:context.rootGetters['user/me']._id} ].filter(Boolean)
        });
      }
    }
  },
  getters: {
    forAccountAndCreator: (state, getters, rootstate, rootGetters) => creatorId => {
      const account = rootGetters['account/selected']
      return getters.all.filter(message => {
        return message.toAccount === account._id && message.creator === creatorId
      }).sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    },
    forCollab: (state, getters) => collabId => {
      return getters.all.filter(message => {
        return message.collab === collabId
      }).sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    },
    unreadCountForCollab: (state, getters, rootState, rootGetters) => collabId => {
      const myUserId = rootGetters['user/me']._id;
      return getters.forCollab(collabId).filter(message => {
        return !((message.readBy || []).find(reader => reader.user === myUserId))
      }).length;
    },
    wasReadByMe: (state, getters, rootState, rootGetters) => messageId => {
      const message = state.documents[messageId];
      if(message){
        return (message.readBy || []).some(reader => reader.user === rootGetters['user/me']._id)
      }
    }
  },
  onCreate(context){
    // Re-load users conversation subscription settings
    return context.dispatch('objectEventSubscription/load', null, {root: true});
  },
  async beforeSave (context, doc) {
    if(!doc._id){
      if (!doc.fromAccount) {
        doc.fromAccount = context.rootGetters['account/selected']._id
        if (!doc.fromAccount) {
          throw new Error('A message must have a fromAccount')
        }
      }
      if (!doc.user) {
        doc.user = context.rootGetters['user/me']._id
        if (!doc.user) {
          throw new Error('A message must have a user')
        }
      }
    }
  }
})
