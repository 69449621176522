<template>
  <input
    v-rules="validEmail"
    :name="name"
    :value="value"
    :placeholder="placeholder"
    :class="{ invalid: invalid, valid: valid }"
    type="text"
    @change="onChange"
    @keypress.enter="onEnter"
  >
</template>
<script lang="ts">
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'

export default Vue.extend({
  extends: BaseInput,
  methods: {
    validEmail (value) {
      if (value) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return this.notEmpty(value) && re.test(String(value).toLowerCase())
      } else {
        return true
      }
    }
  }
})

</script>
