<template>
  <div class="form-media-drag-drop">
    <div :class="{'upload-status': true, minimized}">
      <header v-if="files.length">
        <span>
          <ProgressSpinner v-if="activeFiles.length > 0" />
          <IconSvg
            v-if="activeFiles.length == 0 && completeFiles.length > 0"
            name="circled-checkmark"
          />
          {{ title }}
        </span>
        <IconSvg
          name="chevron"
          @click.native="minimized=!minimized"
        />
        <IconSvg
          name="x"
          @click.native="files=[]"
        />
      </header>
      <SmoothReflow class="file-list-container">
        <ul v-if="files.length && !minimized">
          <li
            v-for="file in files"
            :key="file.id"
            :class="{file: true, success: file.success}"
            @click="file.success ? goToMedia(file) : null"
          >
            <span class="thumbnail">
              <img
                v-if="file.thumb"
                :src="file.thumb"
              >
              <span v-else />
            </span>
            <span class="filename">
              {{ file.name }}
              <span class="error">
                {{ file.active ? file.progress ? 'Processing' : 'Uploading' : file.success ? 'Uploaded' : file.error ? errorMessage(file.error) : 'Waiting' }}
              </span>
            </span>
            <span class="status">
              <div
                v-if="file.active"
                class="progress"
              >
                <div
                  :class="{
                    'progress-bar': true,
                    'bg-danger': file.error,
                    'progress-bar-animated': file.active
                  }"
                  :style="{width: file.progress + '%'}"
                />
                <label>{{ file.progress }}%</label>
              </div>
              <div v-if="file.success">
                <IconSvg name="circled-checkmark" />
              </div>
              <div v-if="file.error">
                <IconSvg name="x" />
              </div>
            </span>
          </li>
        </ul>
      </SmoothReflow>

      <div
        v-show="$refs.upload && $refs.upload.dropActive"
        class="drop-active"
      >
        <h3>
          <IconSvg name="library-inverted" /><br>
          Drop files to add to Library
        </h3>
      </div>
      <file-upload
        ref="upload"
        v-model="files"
        extensions="jpg,jpeg,mp4,png,mov"
        accept="image/png,image/jpeg,video/mpeg,video/h264"
        name="media"
        :drop="true"
        :drop-directory="true"
        :thread="1"
        :multiple="true"
        :data="formData"
        class="btn btn-primary"
        post-action="/api/media"
        @input-filter="inputFilter"
        @input-file="inputFile"
      />
      <SmoothReflow tag="footer">
        <Button
          v-if="$refs.upload && $refs.upload.active"
          class="small secondary"
          label="Cancel Upload"
          @click.prevent="$refs.upload.active = false"
        />
      </SmoothReflow>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import FileUpload from 'vue-upload-component'

import Button from '@/components/atoms/Button.vue'
import IconSvg from '@/components/atoms/IconSvg.vue'
import ProgressSpinner from '@/components/atoms/ProgressSpinner.vue'
import SmoothReflow from '@/components/atoms/SmoothReflow.vue'


export default Vue.extend({
  components: {
    IconSvg,
    Button,
    FileUpload,
    SmoothReflow,
    ProgressSpinner
  },
  data () {
    return {
      files: [],
      minimized: false
    }
  },
  computed: {
    formData () {
      return {
        account: this.$store.getters['account/selected']._id
      }
    },
    activeFiles () {
      return this.files.filter(file => file.active).length
    },
    completeFiles () {
      return this.files.filter(file => file.success).length
    },
    title () {
      const numberOfFiles = this.files.length
      const itemName = numberOfFiles === 1 ? 'item' : 'items'
      if (this.activeFiles) {
        return `Uploading ${this.completeFiles + this.activeFiles} of ${numberOfFiles} ${itemName}`
      } else {
        if (this.completeFiles) {
          return `Uploaded ${this.completeFiles + this.activeFiles} ${itemName}`
        } else {
          return 'Upload Failed'
        }
      }
    }
  },
  methods: {
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        console.log('blobbig')
        // Create a blob field
        // 创建 blob 字段
        newFile.blob = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
    },
    // add, update, remove File Event
    inputFile (newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, { error: 'size' })
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
          console.log(newFile)
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
          this.$emit('success', JSON.parse(newFile.xhr.response))
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    errorMessage (error) {
      if (error === 'denied') {
        return 'Network error'
      } else if (error === 'extension') {
        return 'File type not supported'
      } else {
        return 'Unknown error'
      }
    },
    goToMedia (file) {
      const media = JSON.parse(file.xhr.response)
      if (media && media._id) {
        this.$router.push({
          name: 'media-details',
          params: {
            mediaId: media._id
          }
        })
      }
    }
  }
})
</script>

<style lang="less" scoped>
  @import '../../less/variables';
  @import '../../less/mixins';



  header{
    background: @purpleVibrant;
    color: @white;
    padding: 1em 1.5em;
    display: grid;
    grid-template-columns: auto 1em 1em;
    column-gap: 1em;
    align-items: center;
    .icon{
      justify-self: flex-end;
      width: 1em;
      opacity: 0.8;
      cursor: pointer;
      &:hover, &:active{
        opacity: 1;
      }
      &.chevron{
        transform: rotate(90deg);
      }
    }
  }

  .file {
    padding: 10px;
    &.success{
      cursor: pointer;
      &:hover, &:active{
        border-radius: 4px;
        background: @grayLight;
      }
    }
  }

  .form-media-drag-drop label.btn {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .form-media-drag-drop .drop-active {
    inset: 0;
    position: fixed;
    z-index: 50;
    opacity: .8;
    text-align: center;
    background: @grayDark;
  }

  .upload-status{
    background: #fff;
    position: absolute;
    right: 2em;
    bottom: 0;
    width: 400px;
    z-index: 40;
    overflow: hidden;
    transition: width 250ms;
    .shadowbox();
  }

  .minimized.upload-status{
    width: 250px !important;
  }

  .file-list-container{
    max-height: 50vh;
    overflow: scroll;
  }

  .file-uploads{
    position: absolute;
  }

  .minimized header .icon.chevron{
    transform: rotate(-90deg);
  }

  footer {
    text-align: center;
  }

  ul{
    padding: 0.5em;
    padding-top: 1em;
  }

  li{
    display: grid;
    grid-template-columns: 50px auto 100px;
    column-gap: 1em;
    row-gap: 1em;
    padding: 0.5em 0;
    border-bottom: 1px solid @grayLight;
    border-top: 1px solid @grayLight;
    margin-bottom: -1px;
    &:first-child{
      border-top: none;
    }
    &:last-child{
      border-bottom: none;
    }
    span {
      display: flex;
      align-items: center;
    }
    .thumbnail{
      overflow: hidden;
      img{
        width: 100%;
        border-radius: 0.5em;
      }
      span{
        width: 100%;
        padding-top: 100%;
        background: @grayLight;
        border-radius: 0.5em;
      }
    }
    .filename {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline;
      position: relative;
      top: 50%;
      margin-top: -1.25em;
    }
    .status {
      justify-content: flex-end;
      .icon {
        margin-right: 1em;
        font-size: 1.5em;
        &.x{
          color: @red;
        }
        &.circled-checkmark{
          color: @green;
        }
      }
    }
    .progress{
      width: 100%;
      border-radius: 4px;
      overflow: hidden;
    }
    .progress-bar{
      background: @black;
      color: #fff;
    }
    .error{
      color: @grayLightMedium;
      font-size: 80%;
    }
  }

  .form-media-drag-drop .drop-active h3 {
    margin: -.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
    .icon {
      font-size: 100px;
    }
  }
</style>
