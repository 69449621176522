<template>
  <div v-rules="rules">
    <vue-select
      ref="select"
      :name="name"
      :value="value"
      :multiple="multiple"
      :clearable="clearable"
      :taggable="taggable"
      :searchable="searchable"
      :selectable="selectable"
      :no-drop="noDrop"
      :label="label"
      :options="keyedOptions"
      :reduce="reduce"
      :class="{ invalid, valid, taggable, ...classes }"
      :placeholder="placeholder"
      :required="required"
      @input="setKeyedValue($event)"
    />
  </div>
</template>
<script lang="ts">
import '@/less/vue-select.less'
import Vue from 'vue'
import VueSelect from 'vue-select'

import BaseInput from '@/components/atoms/BaseInput.vue'

export default Vue.extend({
  components: {
    VueSelect
  },
  extends: BaseInput,
  props: {
    value: {
      type: [String, Array, Number, Object, null],
      required: false,
      default: null
    },
    classes: {
      type: Object,
      required: false,
      default: () => ({})
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    selectable: {
      type: Function,
      default: () => true
    },
    label: {
      type: String,
      default: 'name'
    },
    uniqueKey: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    noDrop: {
      type: Boolean,
      default: false
    },
    pushTags: {
      type: Boolean,
      default: false
    },
    reduce: {
      type: Function,
      default: value => value
    }
  },
  computed: {
    keyedOptions(){
      if(this.uniqueKey){
        return this.options.map(value => ({
          name: value,
          id: `${this.uniqueKey}-${value}`,
          value
        }))
      }
      return this.options;
    }
  },
  methods: {
    setKeyedValue(value){
      if(this.uniqueKey){
        this.setValue(value.map(value => value.value))
      }else{
        this.setValue(value);
      }
    }
  }
})

</script>
<style lang="less" scoped>
  /deep/ ul {
    padding-left: 0;
  }
</style>
