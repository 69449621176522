<template>
  <BasePage class="full-screen">
    <main class="left">
      <div class="left-content">
        <slot />
      </div>
    </main>
    <main class="right" />
  </BasePage>
</template>
<script lang="ts">
import Vue from 'vue'

import HorizontalRule from '@/components/atoms/HorizontalRule.vue'
import TheLogo from '@/components/atoms/TheLogo.vue'
import FormMixin from '@/components/MixinForm.vue'
import BasePage from '@/components/molecules/BasePage.vue'
import ButtonSocialLogin from '@/components/molecules/ButtonSocialLogin.vue'
import FormErrors from "@/components/molecules/FormErrors.vue";


export default Vue.extend({
  components: {
    BasePage
  },
  mixins: [FormMixin],
})
</script>
<style lang="less" scoped>
  @import '../less/variables';

  main {
    user-select: none;
    -webkit-app-region: drag;
    position: relative;
    &.left{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &.right{
      display: none;
      flex-grow: 1;
      flex-shrink: 1;
      background-image: url("../assets/portrait-1.jpg");
      background-size: cover;
      background-position: center;
    }
  }

  .left-content{
    padding: 3em;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
  }

  /deep/ p{
    color: @grayMedium;
    font-size: 90%;
  }

  .page.full-screen{
    display: flex;
    align-items: stretch;
    padding: 0;
    user-select: none;
    -webkit-app-region: drag;
  }

  /deep/ .the-logo{
    max-width: 150px;
    margin-bottom: 1em;
  }

  /deep/ input{
    margin-top: 0.5em;
  }

  /deep/ .horizontal-rule{
    margin: 1em 0;
    background: @grayLight;
  }

  /deep/ button {
    width: 100%;
  }

  /deep/ .button.facebook, .button.google{
    margin-left: 0;
  }

  @media screen and (min-width: @secondbreakpoint){
    main.left{
      width: 500px;
    }
    main.right{
      display: flex;
    }
  }

  @media screen and (min-width: @thirdbreakpoint){

  }
</style>
