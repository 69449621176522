import Dexie from 'dexie';

const db = new Dexie('documents'); // Initialize Dexie instance with a database name

function syncDocumentsPlugin(store) {

    async function clearModule(moduleName){
        console.debug('Wiping module', moduleName);
        await db[moduleName].clear();
        // wipe the module state
        await db['moduleMetadata'].delete(moduleName);
    }

    async function putModuleMetadata(newMetadata) {
        const currentMetadata = await db['moduleMetadata'].get(newMetadata.moduleName);
        db['moduleMetadata'].put({
            ...currentMetadata,
            ...newMetadata
        });
    }


	async function documentIsOutdated(moduleName, document) {
		if (!document || !document._id || !document.updatedAt) {
			console.warn(`Invalid document for ${moduleName}:`, document);
			return false; // We can't determine if it's outdated, so we'll assume it's not
		}
	
		try {
			const existingDoc = await db[moduleName].get(document._id);
	
			if (existingDoc && existingDoc.updatedAt) {
				const incomingUpdateTime = new Date(document.updatedAt).getTime();
				const existingUpdateTime = new Date(existingDoc.updatedAt).getTime();
	
				if (incomingUpdateTime < existingUpdateTime) {
					console.warn(`Doc ${moduleName} ${document._id} not saving to Dexie. It's older than current version`, 
						{incoming: document, existing: existingDoc});
					return true;
				}
			}
	
			return false; // document is not outdated compared to existing record
		} catch (error) {
			console.error(`Error checking if document is outdated for ${moduleName}:`, error);
			return false; // In case of error, we'll assume it's not outdated to be safe
		}
	}
    
    async function initialize() {
        try{

            // Get the server startTime and git commit hash
            const serverStatus = await store.dispatch('app/getServerStatus');

            // Calculate the database version by finding the number of minutes since Jan 1, 2023, using the server start time.
            // each server restart will generate a new database version
            // as long as they are 1min apart at least
            const dbVersion = Math.floor((serverStatus.startTime - new Date('2023-09-01T00:00:00Z').getTime()) / (1000 * 60));

            // Create the schemas for the stores
            const moduleNames = [];
            const stores = {
                'moduleMetadata': 'moduleName' // stores module metadata
            };
            for (const moduleName in store.state) {
                const hasDocuments = Object.prototype.hasOwnProperty.call(store.state[moduleName], 'documents');
                const hasLastLoadDate = Object.prototype.hasOwnProperty.call(store.state[moduleName], 'lastLoadDate');
                if (hasDocuments && hasLastLoadDate) {
                    stores[moduleName] = '_id';
                    moduleNames.push(moduleName);
                }
            }
            
            // Initialize Dexie
            db.version(dbVersion).stores(stores);
            await db.open();
            
            // Populate Vuex store with documents
            const populateResults = {};
            const expiredTime = Date.now() - store.state.app.documentCacheTTL;
            for (const moduleName of moduleNames) {
                const moduleMetadata = await db['moduleMetadata'].get(moduleName);
                if(moduleMetadata && moduleMetadata.lastLoadDateLocal && moduleMetadata.lastLoadDateLocal < expiredTime){
                    // wipe the table
                    await clearModule(moduleName);
                    populateResults[moduleName] = 'Expired. Wiped.'
                }else{
                    const documents = (await db[moduleName].toArray()).reduce((acc, document) => {
                        if(!document.deleted) {
                            acc[document._id] = document;
                        }
                        return acc;
                    }, {});
                    populateResults[moduleName] = Object.keys(documents).length;
                    store.commit(`${moduleName}/RESTORE`, {
                        documents,
                        ...moduleMetadata
                    });
                }
            }
            store.commit('app/SET_STATE_RESTORED', true);
            console.log(`Dexie: v${dbVersion} w/ modules`, moduleNames);
            console.log(`Dexie: populated with`, populateResults);

            
            // Subscribe to vuex mutations
            // to keep the database in sync with the store
            store.subscribe(async (mutation) => {
                try{
                    // Get the module name and type of mutation
                    const [moduleName, mutationType] = mutation.type.split('/');
                    
                    // ensure this module is a document store
                    if (Object.prototype.hasOwnProperty.call(store.state[moduleName], 'documents')) {

                        // Get the payload
                        const { payload } = mutation; 
                        
                        switch (mutationType) {
                            case 'SET':
                            case 'UPDATE': {
								if(await documentIsOutdated(moduleName, payload)){
									console.log('Sync: Aborting. Payload out of date.', moduleName, mutationType, payload);
									return // abort if the document is outdated
								}
                                db[moduleName].put(payload);
                                break;
                            }
                            case 'DELETE': {
								if(await documentIsOutdated(moduleName, payload)){
									return // abort if the document is outdated
								}
                                db[moduleName].delete(payload);
                                break;
                            }
                            case 'CREATE_BULK': {
                                db[moduleName].bulkPut(payload);
                                break;
                            }
                            case 'SET_LAST_LOAD_DATE': {
                                putModuleMetadata({
                                    moduleName,
                                    lastLoadDate: payload,
                                    lastLoadDateLocal: Date.now()
                                });
                                break;
                            }
                            case 'ON_DEAUTHENTICATE': {
                                // wipe the table
                                clearModule(moduleName);
                                break;
                            }
                        }
                    }
                } catch(e){
                    console.error(e);
                }
            });
        } catch(e){
            console.log(e);
        }
    }


    // If the socket goes offline and re-connects
    // 1. delete and reinitialize the database
    // 2. Reload all documents from scratch
    store.watch(state => state.socket.reconnectSucceeded, async reconnectSucceeded => {
        if(reconnectSucceeded){
            console.log('Refreshing page as a result of socket reconnection');
            await store.dispatch('app/clearLocalCache', {refresh: false});
        }
    });

    // Initialize the plugin
    initialize();
}

export default syncDocumentsPlugin;
export {
    db
};
