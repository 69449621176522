/* tslint:enable:no-implicit-any */
<template>
  <component
    :is="to ? isAbsoluteUrl ? 'a' : 'router-link' : 'button'"
    :class="classes"
    :style="style"
    type="button"
    :to="to"
    :target="target"
    @click="onClick"
  >
    <IconSvg
      v-if="icon && iconPosition === 'left'"
      :name="icon"
    />
    {{ label }}
    <slot />
    <IconSvg
      v-if="icon && iconPosition === 'right'"
      :name="icon"
    />
    <IconSvg
      v-if="hasChevron"
      name="chevron"
    />
  </component>
</template>
<script lang="ts">
import Vue from 'vue'

import IconSvg from '@/components/atoms/IconSvg.vue'

export default Vue.extend({
  components: {
    IconSvg
  },
  props: {
    label: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value: string) => ['tiny', 'small', 'medium', 'large'].includes(value)
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    hasChevron: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object, null],
      default: null
    },
    target: {
      type: String,
      default: null
    }
  },
  computed: {
    classes (): object {
      return {
        button: true,
        'has-chevron': this.hasChevron,
        disabled: this.disabled === true,
        tiny: this.size === 'tiny',
        small: this.size === 'small',
        medium: this.size === 'medium',
        large: this.size === 'large',
        warning: this.warning
      }
    },
    style (): string {
      if (this.backgroundColor) {
        return `background:${this.backgroundColor}`
      }
      return ''
    },
    isAbsoluteUrl(){
      return this.to && typeof this.to === 'string' && String(this.to).indexOf('http') === 0;
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
      if (this.to) {
        // check if url is relative or absolute
        if(this.isAbsoluteUrl){
          if(this.target) {
            window.open(this.to, this.target)
          }else{
            window.location.href = this.to;
          }
        }else{
          this.$router.push(this.to)
        }
      }
    }
  }
})

</script>
<style lang="less" scoped>

  @import '../../less/variables';


  .icon {
    margin-right: 0.25em;
  }

  .button {
    background: @grayDark;
    font-size: inherit;
    padding: .4em 1.25em;
    border-radius: 0.25em;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    margin-top: 1em;
    margin-bottom: 1em;
    position: relative;
    border: 0;
    box-shadow: 0 3px 5px fade(@black, 10%);
    color: #fff;
    white-space: nowrap;
    display: inline-block;
    box-sizing: border-box;
		font-family: inherit;
    &.link{
      color: @grayDark;
      background: transparent;
      box-shadow: none;
      padding: 0;
	  margin: 0;
      &:hover, &:active {
        color: @purpleVibrant;
        background: transparent;
      }
      &.warning {
        color: @red;
        background: transparent;
        &:hover, &:active{
          color: @white;
          background: @red;
        }
      }
    }
    &.primary {
      background: @purpleVibrant;
      &:hover, &:active {
        background: darken( @black, 10%);
      }
      .icon {
        height: 1.5em;
        width: 1.5em;
      }
    }
    &.secondary {
      background: @grayLight;
      color: @grayDark;
      box-shadow: 0 3px 5px fade(@black, 5%);
      &:hover, &:active {
        background: @grayLightHover;
      }
      .icon {
        height: 1.5em;
        width: 1.5em;
      }
    }
    &.outline {
      background: @grayLight;
      color: @grayDark;
      box-shadow: 0 3px 5px fade(@black, 5%);
      &:hover, &:active {
        background: @white;
      }
      .icon {
        height: 1.5em;
        width: 1.5em;
      }
    }
    &.rounded{
      border-radius: 2em;
    }
    &.black {
      background: @black;
    }
    &.green{
      background: @green;
      color: @black;
      &:hover{
        background: darken(@green, 5%);
      }
    }
    &.white {
      background: @white;
      color: @grayDark;
      box-shadow: 0 2px 6px fade(@black, 10%);
      transition: box-shadow 100ms;
      &:hover{
        box-shadow: 0 2px 6px fade(@black, 10%);
        background: @white;
        color: @black;
      }
      &:active {
        background: @grayLight;
      }
      .icon {
        height: 1.5em;
        width: 1.5em;
      }
      &.outline{
        box-shadow: none;
        border: 1px solid fade(@black, 8%);
        height: auto;
        &:hover{
          background: @grayExtraLight;
        }
      }
    }
    &.disabled {
      background: @grayLight;
      color: @grayLightMedium;
      box-shadow: none;
      cursor: not-allowed;
      &:hover, &:active{
        background: fade(@grayMedium, 50%);
        color: fade(@black, 50%);
      }
    }
    &.block{
      width: 100%;
    }
    &.warning {
      color: @red;
    }
    &.has-chevron .chevron {
      margin-right: -0.25em;
    }
    &.tiny {
      font-size: 70%;
    }
    &.small {
      font-size: 80%;
    }
    &.large {
      font-size: 120%;
    }
    &.full-width {
      width: 100%;
    }
    &.circle{
      height: 2.5em;
      width: 2.5em;
      padding: 0;
      border-radius: 50%;
	  aspect-ratio: 1 / 1;
      margin: 0;
      .icon {
        margin-right: revert;
      }
    }
    &.transparent {
      background: transparent;
      box-shadow: none;
      color: @grayMedium;
      &:hover, &:active{
        background: @grayLight;
        color: @grayDark;
      }
    }
    &.rotate-icon-90 .icon{
      transform: rotate(90deg);
    }
    &.rotate-icon-180 .icon{
      transform: rotate(180deg);
    }
    &.rotate-icon-270 .icon{
      transform: rotate(270deg);
    }
    &:hover, &:active{
      background-color: @black;
    }
  }

</style>
