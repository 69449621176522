<template>
  <div
    :class="{notification: true, unread: !read}"
    @click="handleClick"
  >
    <PopoverMenu placement="bottom">
      <PopoverMenuItem
        icon="x"
        label="Delete this notification"
        @click="$store.dispatch('notification/delete', notificationId)"
      />
      <PopoverMenuItem
        v-if="!read"
        icon="eye"
        label="Mark as read"
        @click="$store.dispatch('notification/markRead', notificationId)"
      />
      <PopoverMenuItem
        v-if="read"
        icon="eye"
        label="Mark as Unread"
        @click="$store.dispatch('notification/markUnread', notificationId)"
      />
      <slot name="popover" />
    </PopoverMenu>
    <div
      v-if="!image"
      class="icon-container"
    >
      <IconSvg :name="icon" />
    </div>
    <div
      v-if="image"
      class="image-container"
    >
      <img :src="image">
      <IconSvg :name="icon" />
    </div>
    <div class="text">
      <div
        class="message"
        v-html="message"
      />
      <div
        v-if="timeLabel"
        class="time"
      >
        {{ timeLabel }}
      </div>
    </div>
    <span class="unread-indicator" />
  </div>
</template>
<script lang="ts">
import moment from 'moment'
import Vue from 'vue'

import IconSvg from '@/components/atoms/IconSvg.vue'
import PopoverMenu from '@/components/molecules/PopoverMenu.vue'
import PopoverMenuItem from '@/components/molecules/PopoverMenuItem.vue'


export default Vue.extend({
  components: {
    IconSvg,
    PopoverMenu,
    PopoverMenuItem
  },
  props: {
    notificationId: {
      type: String,
      required: true
    },
    read: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    message: {
      type: String,
      required: true,
      default: ''
    },
    icon: {
      type: String,
      required: true,
      default: ''
    },
    time: {
      type: String,
      required: true,
      default: ''
    },
    link: {
      type: String,
      required: true,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      timeLabel: null,
      intervalTimer: null
    }
  },
  mounted () {
    Vue.nextTick(() => {
      this.updateTimeLabel()
    })
    // Update the time once a minute.
    this.intervalTimer = setInterval(this.updateTimeLabel, 60000)
  },
  methods: {
    updateTimeLabel () {
      const time = moment(this.time)
      let timeString = ''
      timeString = time.fromNow()

      if (time.isAfter(moment().add(-1, 'minute'))) {
        timeString = 'Just now'
      } else if (time.isAfter(moment().add(-24, 'hour'))) {
        timeString = time.fromNow(true)
          .replace(' ', '')
          .replace(/minutes/g, 'm') // 2minutes to 2m
          .replace(/minute/g, 'm') // 1minute to 1m
          .replace(/hours/g, 'h') // 2hours to 2h
          .replace(/hour/g, 'h') // 1hour to 1h
          .replace(/an/g, '1')
          .replace(/a/g, '1')
      } else {
        timeString = time.fromNow()
      }
      this.timeLabel = timeString
    },
    handleClick () {
      if (!this.read) {
        this.$store.dispatch('notification/markRead', this.notificationId)
      }
      if (this.link) {
        this.$router.push(this.link).catch(() => {
          console.log('This page does not exist.')
        })
      }
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/mixins';
  @import '../../less/variables';

  .icon-container{
    grid-row: 1 / 2;
    .icon{
      height: 50px;
      width: 50px;
      color: @grayMedium;
    }
  }

  .image-container{
    height: 50px;
    width: 50px;
    position: relative;
    img{
      height:100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .icon{
      height: 1.5em;
      width: 1.5em;
      padding: 0.15em;
      box-sizing: border-box;
      border-radius: 50%;
      color: @grayDark;
      background: @white;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .time{
    color: @grayLightMedium;
    font-size: 0.9em;
  }

  .unread-indicator{
    background: @purpleVibrant;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    display: none;
  }

  .v-popover{
    position: absolute;
    top: 50%;
    right: 2em;
    margin-top: -1.3em;
    display: none;
    /deep/ .button{
      .shadowbox();

      border-radius: 50%;
      color: @grayLightMedium;
      &:hover, &:active{
        color: @grayDark;
        background: @grayLight;
      }
    }
  }

  .notification{
    padding: 0.5em 1em;
    margin:0 -0.5em;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 50px 1fr 0.5em;
    align-items: center;
    gap: 0.5em;
    position: relative;
    font-size: 0.8em;
    &:hover, &:active{
      background: @grayLight;
      .v-popover{
        display: block;
      }
      .image-container .icon{
        background: @grayLight;
      }
    }
    &.unread{
      .unread-indicator {
        display: inline-block;
      }
      .time{
        color: @purpleVibrant;
        font-weight: 500;
      }
    }
  }

  /deep/ strong {
    font-weight: 600;
  }
</style>
