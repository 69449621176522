function vuexSocketPlugin(store) {
    function openWebsocket(){
        if(!store.getters['user/isAuthenticated']){
            return;
        }
        console.log('WebSocket Opening...')
        const wsScheme = window.location.protocol === "https:" ? "wss:" : "ws:";
        const socketUrl = `${wsScheme}//${window.location.host}/ws`;
        const socket = new WebSocket(socketUrl);

        socket.onopen = () => {
            console.log('WebSocket opened')
            socket.send('Test message from client')
            store.commit('socket/SET_SOCKET_CONNECTED', true)
        }
        socket.onerror = error => {
            store.commit('socket/SET_SOCKET_CONNECTED', false)
            console.log('WebSocket Error', error)
        }
        socket.onclose = error => {
            store.commit('socket/SET_SOCKET_CONNECTED', false)
            console.log('WebSocket Closed. Attempting reconnect in 5 seconds.', error.reason)
            setTimeout(() => {
                openWebsocket()
            }, 5000)
        }
        socket.onmessage = event => {
            const message = JSON.parse(event.data)
            if (message.type === 'notification' && message.notification) {
                console.log('Websocket Received Notification', message.notification)
                store.dispatch('notification/display', message.notification)
                // AudioController.play('Notification')
            } else if (message.type === 'systemEvent') {
                const { objectType, eventType, target, data } = message.data
                console.log('WebSocket Received', objectType, eventType, target, data)
                const moduleName = objectType.charAt(0).toLowerCase() + objectType.slice(1)
                if (['Create'].includes(eventType)) {
                    console.log(`${moduleName}/SET`, target)
                    store.commit(`${moduleName}/SET`, target)
                } else if (['Modify'].includes(eventType)) {
                    console.log(`${moduleName}/UPDATE`, { target, changes: data.changes })
                    store.commit(`${moduleName}/UPDATE`, target)
                } else if (eventType === 'Delete') {
                    console.log(`${moduleName}/DELETE`, target._id)
                    store.commit(`${moduleName}/DELETE`, target._id)
                }
            }
        }
    }
    openWebsocket();

    store.watch((state, getters) => getters['user/isAuthenticated'], isAuthenticated => {
        if(isAuthenticated){
            openWebsocket();
        }
    })
    store.watch((state) => state.socket.timesConnected, async timesConnected => {
        // if we've connected more than once,
        // then we've recovered from a socket problem
        if(timesConnected > 1){
            console.log('Recovered from socket problem');
            store.dispatch('app/getServerStatus');
        }
    });
}
export default vuexSocketPlugin;
