import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'agreements',
  getters: {
    forBrand: (state, getters) => brandId => {
      return getters.all.filter(object => object.brand === brandId || false)
    },
    forCollab: (state, getters) => collabId => {
      return getters.all.filter(object => object.collab === collabId || false)
    }
  },
  async beforeSave (context, doc) {

    // Automatically update related objects
    if(doc.brandLegalName && !context.rootGetters['account/selected'].legalName){
      // set the account legal name to the agreements legal name
      await context.dispatch('account/update', {
        _id: context.rootGetters['account/selected']._id,
        legalName: doc.brandLegalName
      }, { root: true });
    }

    // Automatically update related objects
    const creator = context.rootGetters['account/byId'](doc.creator);
    if(doc.creatorLegalName && creator && !creator.legalName){
      // set the account legal name to the agreements legal name
      await context.dispatch('account/update', {
        _id: creator._id,
        legalName: doc.creatorLegalName
      }, { root: true });
    }


    if (!doc._id){

      // Automatically fill fields
      if (!doc.account) {
        doc.account = context.rootGetters['account/selected']._id
      }
      if (!doc.brandLegalName) {
        doc.brandLegalName = context.rootGetters['account/selected'].legalName
      }
      if (!doc.creatorLegalName) {
        doc.creatorLegalName = creator.legalName
      }
      // Validate
      if(!doc.account) {
        console.error('An agreement must have an account', doc);
        throw new Error('An agreement must have an account')
      }
    }
  }
})
