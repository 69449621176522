<template>
  <div :class="classes">
	<ProgressSpinner v-if="icon === 'progress-spinner'" />
    <IconSvg
      v-else-if="icon"
      :name="icon"
      :class="iconClasses"
    />
    <h2
      v-if="title"
      v-html="title"
    />
    <p
      v-if="message"
      v-html="message"
    />
    <p><slot name="message" /></p>
    <slot />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import ProgressSpinner from '@/components/atoms/ProgressSpinner.vue'
import IconSvg from '@/components/atoms/IconSvg.vue'

export default Vue.extend({
  components: {
    IconSvg,
	ProgressSpinner
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    iconSize: {
      type: String,
      required: false,
      default: 'x4',
      validator: (value: string) => ['x1', 'x2', 'x3', 'x4', 'x5'].includes(value)
    },
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    actionable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      return {
        'no-results': true,
        actionable: this.actionable
      }
    },
    iconClasses () {
      return this.iconClass + ' ' + this.iconSize
    }
  }
})
</script>
<style lang="less" scoped>
  @import "../../less/variables";

  .no-results {
    text-align: center;
    padding: 3em @mobile-horizontal-padding;
    color: @grayMedium;
    .icon, .progress-spinner {
      margin-bottom: 1em;
    }
    p, h2 {
      color: inherit;
    }
    &.actionable {
      color: @grayDark;
      p{
        color: @grayMedium;
      }
    }
  }

  @media (min-width: @firstbreakpoint){
    button {
      width: revert;
    }
  }
</style>
